import { ItemResponse } from "../../../service/order/types";
import { copyClipboard } from "../../../shared/lib/copyClipboard";
import { customToast } from "../../../shared/lib/customToast";
import { OrderItemType } from "../ui/IndividualOrderTable";

export const OrderCopyToClipboard = (orderItems: OrderItemType[]) => {
  let text =
    "연번\t주문번호\t상품번호\t상품바코드\t상품명\t사이즈\t컬러\t발주상태\t자동상품메모\t발주담당자메모\t청도주문메모\t기타메모\t재고현황\t입고현황\t발주수량\t확정수량\t박스번호\t준비수량\t플완\t오류수량\tIBC 하차번\n";

  orderItems.forEach((item: ItemResponse, index: number) => {
    const row = [
      index,
      item.IndividualOrderNumber || " ",
      item.SKU_ID || " ",
      item.BarCode || " ",
      item.ProductName || " ",
      item.Size ? item.Size.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
      item.Color ? item.Color.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
      item.Status === "R" ? "준비" : "처리완료",
      item.Memo0 ? item.Memo0.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
      item.Memo1 ? item.Memo1.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
      item.Memo2 ? item.Memo2.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
      item.Memo3 ? item.Memo3.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
      item.TotalCount || 0,
      item.IncomingCount || 0,
      item.Quantity || 0,
      item.Quantity_OK || 0,
      item.BoxNumberList ? item.BoxNumberList.map((v) => `${v.ToObjectCode}(${v.Quantity})`).join(", ") : " ",
      item.Quantity_Ready || 0,
      item.Quantity_coupang || 0,
      item.Quantity_Error || 0,
      item.IBCNumber ? item.IBCNumber.replace(/[\r\n\t\/\\\"]/g, " ") : " ",
    ];

    text += row.join("\t") + "\n";
  });

  if (text.trim()) {
    copyClipboard(text);
    customToast("success", "클립보드에 복사되었습니다.");
  } else {
    customToast("warn", "복사할 데이터가 없습니다.");
  }
};
