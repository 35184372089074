import { Typography, Button, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ERROR_DESCRIPTION } from "../const/descriptions";

const ErrorBoundary: React.FC = () => {
  const navigate = useNavigate();
  const moveToLink = () => {
    navigate(`/users`);
  };

  return (
    <Stack gap={3} sx={{ maxWidth: "1000px", margin: "0 auto", marginTop: "20%" }} alignItems={"center"}>
      <Typography variant="h3" fontWeight={900}>
        404
      </Typography>
      <Typography variant="h5" fontWeight={900}>
        {ERROR_DESCRIPTION.title}
      </Typography>
      <Box>
        {ERROR_DESCRIPTION.body.map((item, i) => {
          return (
            <Typography variant="body1" textAlign={"center"} key={i}>
              {item}
            </Typography>
          );
        })}
      </Box>
      <Button onClick={moveToLink} variant="outlined" sx={{ border: "1px solid black", color: "black", width: "200px" }}>
        메인페이지로 이동
      </Button>
    </Stack>
  );
};

export default ErrorBoundary;
