import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { customToast } from "../../shared/lib/customToast";
import { QUERY_KEY } from "../../config/querykey";
import { targetFindApi } from "./targetFindAxios";
import { createTargetFindItemDto, deleteTargetFindDto, getTargetFindItemDto, updateTargetFindDto, updateTargetFindMemodto } from "./types";

export const useCreateTargetFind = () => {
  const queryClient = useQueryClient();
  return useMutation<createTargetFindItemDto[], Error, createTargetFindItemDto[]>({
    mutationFn: (data: createTargetFindItemDto[]) => targetFindApi.createTargetFind(data),
    onSuccess: (data) => {
      // console.log("데이터 전송 성공");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.targetFind] });
      customToast("success", "데이터 전송 성공");
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: " 데이터 전송 실패", error: err });
    },
  });
};

export const useGetTargetFindList = () => {
  return useQuery<getTargetFindItemDto[], Error>({
    queryKey: [QUERY_KEY.targetFind],
    queryFn: () => targetFindApi.getTargetFind(),
  });
};

export const useUpdateTargetFind = () => {
  return useMutation<updateTargetFindDto, Error, updateTargetFindDto>({
    mutationFn: (data: updateTargetFindDto) => targetFindApi.updateTargetFind(data),
    onSuccess: (data) => {
      customToast("success", "수량 변경 성공");
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "수량 변경 실패", error: err });
    },
  });
};

export const useUpdateTargetFindMemo = () => {
  return useMutation<updateTargetFindMemodto, Error, updateTargetFindMemodto>({
    mutationFn: (data: updateTargetFindMemodto) => targetFindApi.updateTargetFindMemo(data),
    onSuccess: (data) => {
      // customToast("success", "메모 변경 성공");
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "메모 변경 실패", error: err });
    },
  });
};

export const useDeleteTargetFind = () => {
  const queryClinet = useQueryClient();
  return useMutation<deleteTargetFindDto, Error, deleteTargetFindDto>({
    mutationFn: (data: deleteTargetFindDto) => targetFindApi.deleteTargetFind(data),
    onSuccess: (data) => {
      queryClinet.invalidateQueries({ queryKey: [QUERY_KEY.targetFind] });
      customToast("success", "삭제 성공");
    },
  });
};
