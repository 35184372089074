import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type UserInfoType = {
  UserName: string;
  IsStaff: boolean;
  RoleID:number;
};

type UserInfoActions = {
  setUserInfo: (data: UserInfoType) => void;
  clearUserInfo: () => void;
  getUserInfo: () => UserInfoType;
};

export const useUserInfoState = create(
  persist<UserInfoType & UserInfoActions>(
    (set, get) => ({
      UserName: "",
      IsStaff: false,
      RoleID:0,
      setUserInfo: (data: UserInfoType) => {
        set({
          ...data,
        });
      },
      clearUserInfo: () => {
        set({
          UserName: "",
          IsStaff: false,
          RoleID:0,
        });
      },
      getUserInfo: () => {

        const {UserName, IsStaff, RoleID} = get()
        return {
          UserName,
          IsStaff,
          RoleID,
        };
      },
    }),
    {
      name: "ATTERLIC_USER_INFO",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const { setUserInfo, getUserInfo, clearUserInfo } = useUserInfoState.getState();
