import { useModals } from "../../../../service/modal/useModals";
import AddBasketModal from "./CreateBasketModal";
import { Button } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useCreateBasket } from "../../../../service/basket/BasketHooks";
import { createBasketDto } from "../../../../service/basket/types";

export const CreateBasketButton: React.FC = () => {
  const { openModal, closeModal } = useModals();
  const { mutate: createBasket } = useCreateBasket();
  const handleClose = () => {
    closeModal(AddBasketModal);
  };

  const handleSubmit = (data: createBasketDto) => {
    createBasket(data);
    handleClose();
  };

  const handleClickOpen = () => {
    openModal(AddBasketModal, { handleClose, handleSubmit });
  };

  return (
    <React.Fragment>
      <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={handleClickOpen}>
        바구니 추가
      </Button>
    </React.Fragment>
  );
};
