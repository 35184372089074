import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Stack } from "@mui/material";
import { useState } from "react";
import { BarcodeAlertDto } from "../../../../service/barcode-alert/types";
import { useUserInfoState } from "../../../../store/UserInfoStore";
import { useUpdateBarcodeAlert } from "../../../../service/barcode-alert/barcodeAlertHooks";
import { customToast } from "../../../../shared/lib/customToast";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../../config/querykey";
import RowStack from "../../../../shared/ui/RowStack";
import { ALERT_ICON_LIST } from "../../const/const";

interface Props {
  data: BarcodeAlertDto;
  handleClose: () => void;
}

const UpdateBarcodeAlertModal: React.FC<Props> = ({ data, handleClose }) => {
  const UserName = useUserInfoState((v) => v.UserName);
  const queryClient = useQueryClient();
  const [iconIndex, setIconIndex] = useState<number>(ALERT_ICON_LIST.indexOf(data?.image_url || ""));
  const { mutate: updateBarcodeAlert } = useUpdateBarcodeAlert(() => {
    customToast("success", "수정이 완료되었습니다.");
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.barcodeAlert] });
  });
  const [params, setParams] = useState<BarcodeAlertDto>({
    ...data,
    update_user: UserName,
  });

  const handleSelectIcon = (index: number) => {
    setIconIndex(index);
    setParams({ ...params, image_url: ALERT_ICON_LIST[index] });
  };

  const handleChangeParams = (e: any) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const handleSubmit = () => {
    const { barcode, image_url, message, desc_text, id } = params;
    updateBarcodeAlert([{ id, barcode, image_url, message, desc_text }]);
    handleClose();
  };

  return (
    <>
      <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "16px", color: "#333" }}>
          바코드 알림 수정
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ width: "500px", mb: 4 }}>
            {/* <Typography variant="subtitle2">
              정보를 입력해주세요.
              <br />
            </Typography> */}
          </DialogContentText>
          <Stack gap={2}>
            <Stack direction="row" sx={{ flexWrap: "wrap" }} gap={2}>
              {ALERT_ICON_LIST.map((icon, index) => (
                <img
                  onClick={() => handleSelectIcon(index)}
                  src={icon}
                  alt="barcode"
                  key={index}
                  onDragStart={(e) => e.preventDefault()}
                  style={{
                    userSelect: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    width: "120px",
                    height: "120px",
                    border: iconIndex === index ? "5px solid #6799ff" : "1px solid #dfdfdf",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                />
              ))}
            </Stack>
            {/* {params.image_url ? (
              <img src={params.image_url} alt="barcode" style={{ width: "100px", height: "100px" }} />
            ) : (
              <img src={DEFAULT_IMG} alt="barcode" style={{ width: "100px", height: "100px" }} />
            )}
            <TextField
              sx={{ mt: 2 }}
              value={params.image_url}
              label="이미지 URL"
              name="image_url"
              onChange={handleChangeParams}
              placeholder="이미지 URL을 입력해주세요."
            /> */}

            <TextField value={params.barcode} label="바코드" name="barcode" onChange={handleChangeParams} fullWidth />

            <TextField
              multiline
              rows={3}
              value={params.message}
              label="메시지"
              name="message"
              placeholder="알림에 표시될 메시지를 입력해주세요."
              onChange={handleChangeParams}
              sx={{ mt: 5 }}
            />
            <TextField
              multiline
              rows={3}
              value={params.desc_text}
              label="설명"
              name="desc_text"
              placeholder="알림을 추가하는 이유를 작성해주세요."
              onChange={handleChangeParams}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleSubmit} autoFocus variant="contained">
            등록
          </Button>
          <Button onClick={handleClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateBarcodeAlertModal;
