import { IconButton } from "@mui/material";
import React from "react";
import { LiaBarcodeSolid } from "react-icons/lia";
import { useModals } from "../../../service/modal/useModals";
import { BarCodeModal } from "./BarCodeModal";

interface BarcodeButtonProps {
  value: string;
}

const BarcodeButton: React.FC<BarcodeButtonProps> = ({ value }) => {
  const { openModal, closeModal } = useModals();

  const handleClose = () => {
    closeModal(BarCodeModal);
  };

  const handleClickOpen = () => {
    openModal(BarCodeModal, { value, handleClose });
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} color="primary">
        <LiaBarcodeSolid />
      </IconButton>
    </React.Fragment>
  );
};

export default BarcodeButton;
