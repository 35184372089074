import IndividualStoreTable from "../entities/individual-store/ui/IndividualStoreTable";

const IndividualStore = () => {
  return (
    <>
      <IndividualStoreTable />
    </>
  );
};

export default IndividualStore;
