export function convertKoreanToUpperEnglish(input: string) {
  const koreanToUpperEnglishMap: { [key: string]: string } = {
    ㅂ: "Q",
    ㅃ: "Q",
    ㅈ: "W",
    ㅉ: "W",
    ㄷ: "E",
    ㄸ: "E",
    ㄱ: "R",
    ㄲ: "R",
    ㅅ: "T",
    ㅆ: "T",
    ㅛ: "Y",
    ㅕ: "U",
    ㅑ: "I",
    ㅐ: "O",
    ㅒ: "O",
    ㅔ: "P",
    ㅖ: "P",
    ㅁ: "A",
    ㄴ: "S",
    ㅇ: "D",
    ㄹ: "F",
    ㅎ: "G",
    ㅗ: "H",
    ㅓ: "J",
    ㅏ: "K",
    ㅣ: "L",
    ㅠ: "B",
    ㅜ: "N",
    ㅡ: "M",
  };

  return input
    .split("")
    .map((char: string) => koreanToUpperEnglishMap[char] || char)
    .join("");
}
