// 컬러
export const customerOrderStatus: { label: string; value: string; color: string }[] = [
  {
    label: "발송전",
    value: "",
    color: "white",
  },
  {
    label: "1.반품완료",
    value: "1.반품완료",
    color: "pink",
  },
  {
    label: "2.반품접수",
    value: "2.반품접수",
    color: "blue",
  },
  {
    label: "3.취소완료",
    value: "3.취소완료",
    color: "yellow",
  },
  {
    label: "4.품절완료",
    value: "4.품절완료",
    color: "orange",
  },
  {
    label: "5.발송완료",
    value: "5.발송완료",
    color: "puple",
  },
  {
    label: "6.교환진행",
    value: "6.교환진행",
    color: "green",
  },
];

// 상태 필터링
export const customerOrderStatus2: { label: string; value: string }[] = [
  {
    label: "전체",
    value: "전체",
  },
  {
    label: "발송전",
    value: "발송전",
  },
  {
    label: "1.반품완료",
    value: "1.반품완료",
  },
  {
    label: "2.반품접수",
    value: "2.반품접수",
  },
  {
    label: "3.취소완료",
    value: "3.취소완료",
  },
  {
    label: "4.품절완료",
    value: "4.품절완료",
  },
  {
    label: "5.발송완료",
    value: "5.발송완료",
  },
  {
    label: "6.교환진행",
    value: "6.교환진행",
  },
];

// 일괄 상태변경 항목
export const customerOrderStatus3: { label: string; value: string }[] = [
  {
    label: "발송전",
    value: "발송전",
  },
  {
    label: "1.반품완료",
    value: "1.반품완료",
  },
  {
    label: "2.반품접수",
    value: "2.반품접수",
  },
  {
    label: "3.취소완료",
    value: "3.취소완료",
  },
  {
    label: "4.품절완료",
    value: "4.품절완료",
  },
  {
    label: "5.발송완료",
    value: "5.발송완료",
  },
  {
    label: "6.교환진행",
    value: "6.교환진행",
  },
];
