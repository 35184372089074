import React from "react";
import { useModals } from "../../../service/modal/useModals";
import { ShowInventoryModal } from "./ShowInventoryModal";
import { CustomButton } from "../../../shared/ui/buttom/CustomButton";

interface ShowInventoryButtonProps {
  BarCode: string;
  TotalCount: number;
  status?:string
  
}

const ShowInventoryButton: React.FC<ShowInventoryButtonProps> = ({ BarCode, TotalCount,status ="메인" }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowInventoryModal);
  };

  const handleClickOpen = () => {
    openModal(ShowInventoryModal, { BarCode, handleClose, status });
  };

  return (
    <React.Fragment>
      {/* <CustomButton onClick={handleClickOpen}>{TotalCount.toLocaleString()}</CustomButton> */}
      <CustomButton onClick={handleClickOpen}>{TotalCount}</CustomButton>
    </React.Fragment>
  );
};

export default ShowInventoryButton;
