export function excelDateToISOString(excelDate: number) {
  // 엑셀 날짜의 기준 (1900-01-01)로부터의 오프셋
  const excelEpoch = new Date(Date.UTC(1899, 11, 30));

  // 엑셀 날짜가 1900-03-01 이전일 경우 1일을 더해야 함 (엑셀의 오류 보정)
  const daysOffset = excelDate >= 60 ? excelDate : excelDate - 1;

  // 엑셀 날짜 형식을 밀리초 단위로 변환
  const dateInMillis = daysOffset * 24 * 60 * 60 * 1000;

  // 기준 날짜에 더해 실제 날짜를 구함
  const date = new Date(excelEpoch.getTime() + dateInMillis);

  // ISO 8601 형식으로 변환
  return date.toISOString();
}

