import { customAxios } from "../../config/axios-config";
import { getRocketFindListRequestDto, getRocketFindListResponseDto } from "./types";

//api/order_items/getFindData/?brand=PA

export const rocketApi = {
  getRocketFindList: async (params: getRocketFindListRequestDto): Promise<getRocketFindListResponseDto[]> => {
    return await customAxios.get(`/order_items/getFindData/`, { params }).then((res) => res.data);
  },
};
