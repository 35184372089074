import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { QUERY_KEY } from "../../../config/querykey";
import { useUpdateOrder } from "../../../service/order/orderHooks";
import { getOrderResponseDto } from "../../../service/order/types";

export const IndividualOrderMemoCell = (OrderID: number) => {
  const [text, setText] = useState<string>("");
  const { mutate: updateOrder } = useUpdateOrder();
  const queryClinet = useQueryClient();
  const data = queryClinet.getQueryData([QUERY_KEY.order, OrderID]) as getOrderResponseDto | undefined;

  useEffect(() => {
    setText(data?.MemoOrder || "");
  }, [data?.MemoOrder]);

  return (
    <div style={{ height: "120px", width: "100%" }}>
      <textarea
        style={{ height: "100%", width: "100%", padding: "10px", resize: "none" }}
        value={text}
        placeholder="메모장"
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && e.shiftKey) {
            e.stopPropagation();
            const cursorPos = e.target.selectionStart;
            const textBefore = e.target.value.substring(0, cursorPos);
            const textAfter = e.target.value.substring(cursorPos);
            e.target.value = textBefore + textAfter;
            e.target.selectionStart = cursorPos + 1;
            e.target.selectionEnd = cursorPos + 1;
          }
        }}
        onChange={(e) => setText(e.target.value)}
        onBlur={() => {
          updateOrder({ OrderID, MemoOrder: text });
        }}
      />
    </div>
  );
};
