/**
 * Get color by type and index
 * @param type
 * @param idx
 * @returns
 */
export const getColor = (type: number, idx: number) => {
  const color_list1 = ["#0073E6 ", "#338FE9 ", "#66ABE6 ", "#99C7E9 ", "#CCE3F0 ", "#E5F1F7 ", "#F0F8FC ", "#F3FBFE ", "#F7FDFF "];
  const color_list2 = ["#FF6384", "#FF7996", "#FF8EA7", "#FFA3B9", "#FFB8CB", "#FFCDDD", "#FFDDE6", "#FFE6EF", "#FFF0F5"];
  const green_list = ["#4CAF50", "#66BB6A", "#81C784", "#A5D6A7", "#C8E6C9", "#E8F5E9", "#F1F8E9", "#F9FBF5", "#FFFFFF"];

  switch (type) {
    case 1:
      return color_list1[Math.max(0, Math.min(idx, color_list1.length - 1))];
    case 3:
      return color_list2[Math.max(0, Math.min(idx, color_list2.length - 1))];
    case 2:
      return green_list[Math.max(0, Math.min(idx, green_list.length - 1))];
    default:
      return color_list1[Math.max(0, Math.min(idx, color_list1.length - 1))];
  }
};
