import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Divider } from "@mui/material";

import { getCustomerOrderListResponseDto } from "../../../../service/customer-find-order/types";
import { CardContainer } from "../../style/style";
import { DescriptionItem } from "../../../../shared/ui/description-item/DescriptionItem";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: getCustomerOrderListResponseDto;
}
export const ShowOrderPeopleModal: React.FC<Props> = ({ open, handleClose, data }) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">주문자 정보</DialogTitle>
      <DialogContent>
        <CardContainer>
          <Typography fontWeight="bold" fontSize="18px">
            {data.OrderProductName}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <>
            <DescriptionItem label={"주문자명"} value={data.OrderPeopleName} />
            <DescriptionItem label={"휴대전화"} value={data.OrderPeoplePhone} />
            <DescriptionItem label={"옵션"} value={data.OrderProductOption} />
          </>
        </CardContainer>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};


