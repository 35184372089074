import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import SelectForm from "../../../../widget/form/ui/SelectForm";
import { STATE_OPTION } from "../../const/const";
import { statusType } from "../../type/types";

interface BulkStatusUpdateModalProps {
  onSubmit: (status: statusType) => void;
  open: boolean;
  onClose: () => void;
}

const BulkStatusUpdateModal: React.FC<BulkStatusUpdateModalProps> = ({ open, onClose, onSubmit }) => {
  const queryClient = useQueryClient();
  const [status, setStatus] = React.useState<statusType>(undefined);
  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };
  const statusOptions = React.useMemo(() => {
    return STATE_OPTION.map((option) => ({
      label: option,
      value: option,
    }));
  }, [STATE_OPTION]);

  const handleSubmit = () => {
    if (confirm(`선택한 상품의 상태를 ${status}로 변경하시겠습니까?`)) {
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEY.productList] });
      queryClient.invalidateQueries();
      onSubmit(status);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>일괄 상태변경</DialogTitle>
      <DialogContent sx={{ width: 250, mt: 2 }}>
        <SelectForm sx={{ mt: 2 }} id="status" label="상태" value={status || ""} options={statusOptions} handleChange={handleChangeStatus} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained">
          확인
        </Button>
        <Button onClick={onClose} variant="outlined">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkStatusUpdateModal;
