import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";


/**
 * mui에서 사용하는 GridColDef의 field를 이용하여 숨길 필드를 정의
 */

const hiddenFields = [
  GRID_CHECKBOX_SELECTION_COL_DEF.field,
  //, "registerTime", "recentTime", "warehouseCode", "flexCode"
  // "id"
];

export const getTogglableColumns = (columns: GridColDef[], toggle=true) => {
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};
