import { Box, Button, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import { HistoryType, MAX_HISTORY_LENGTH, useHistoryStore } from "../../../../store/totalOrderHIstory";
import ClearIcon from "@mui/icons-material/Clear";

import SearchInputField from "../../../../widget/form/ui/SearchInpuField";
import HistoryItem from "./HistoryItem";
import RowStack from "../../../../shared/ui/RowStack";

const INTERVAL = 4;

const HistoryList = () => {
  const { historyList, clearHistory, open, setOpen } = useHistoryStore((v) => v);
  const [viewList, setViewList] = useState<HistoryType[]>(historyList.slice(0, INTERVAL));

  const [viewIndex, setViewIndex] = useState(INTERVAL);
  const observerRef = useRef(null);
  const [search, setSearch] = useState("");

  //view index가 변경될때마다 확인하기
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //콜백함수
        if (viewList.length < historyList.length && entries[0].isIntersecting) {
          setViewList([...viewList, ...historyList.slice(viewIndex, viewIndex + INTERVAL)]);
          setViewIndex(viewIndex + INTERVAL);
        }
      },
      { threshold: 0.8 }
    );

    if (observerRef.current) observer.observe(observerRef.current); //관찰시작

    // 컴포넌트가 언마운트될 때 옵저버 해제
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current); //관찰해제
    };
  }, [viewList, viewIndex]);

  useEffect(() => {
    setViewList([...historyList.slice(0, INTERVAL)]); //스프레드로 초기화 해야 viewList가 변경될것 같았음
    setViewIndex(INTERVAL); //인덱스 초기화
  }, [historyList]);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleOpenChange = () => {
    setOpen(!open);
  };

  const handleClearHistory = () => {
    if (confirm("히스토리의 모든 정보가 삭제 됩니다. \n정말 삭제하시겠습니까?")) {
      clearHistory();
    }
  };

  //필터를 적용함
  const filteredHistoryList = useMemo(() => {
    if (search === "") return viewList;

    return historyList.filter((data) => {
      return Object.values(data).some((value) => {
        return value?.toString().includes(search);
      });
    });
  }, [viewList, historyList, search]);

  return (
    <>
      <Button variant="outlined" onClick={handleOpenChange} sx={{ backgroundColor: "white" }} startIcon={<HistoryIcon />} size="small">
        히스토리
      </Button>
      <Collapse in={open}>
        <Box
          sx={{
            backgroundColor: "#dfdfdf",
            position: "absolute",
            zIndex: 100,
            width: "500px",
            height: "98vh",
            border: "1px solid #dfdfdf",
            boxSizing: "border-box",
            boxShadow: 10,
            right: "0px",
            top: "0px",

            transform: open ? "translateY(0)" : "translateY(-10px)",
            opacity: open ? 1 : 0,
            transition: "transform 0.2s ease, opacity 0.2s ease",
          }}
        >
          <Box sx={{ p: 3, backgroundColor: "white", borderBottom: "1px solid #dfdfdf", zIndex: 10 }}>
            <RowStack sx={{ mb: 2 }}>
              <Typography fontWeight={"bold"}>히스토리</Typography>
              <Button color={"error"} sx={{ marginLeft: "auto" }} onClick={handleClearHistory}>
                기록 전체삭제
              </Button>
              <IconButton onClick={handleOpenChange}>
                <ClearIcon />
              </IconButton>
            </RowStack>
            <RowStack>
              <SearchInputField onChange={handleChangeSearch} value={search} />
              <Typography color="#333">
                총 개수 : {historyList.length}/{MAX_HISTORY_LENGTH} 개
              </Typography>
            </RowStack>
          </Box>

          {open && historyList.length !== 0 ? (
            <Box
              key={historyList[0].Id}
              sx={{
                boxSizing: "border-box",
                pt: 4,
                height: "88%",
                overflow: "auto",

                "@keyframes slideDown": {
                  from: { transform: "translateY(-100px)" },
                  to: { transform: "translateY(0)" },
                },

                animation: "slideDown 0.3s ease",
              }}
            >
              <Stack gap={4}>
                {filteredHistoryList.map((data, index) => (
                  <HistoryItem key={index} data={data} />
                ))}
                {viewList.length < historyList.length && (
                  <Box ref={observerRef} sx={{ p: 3, backgroundColor: "", mb: 6 }}>
                    <Typography textAlign={"center"}>더보기</Typography>
                  </Box>
                )}
              </Stack>
            </Box>
          ) : (
            <Typography variant="subtitle1" color="#555" sx={{ mt: 10 }} textAlign={"center"}>
              기록이 없습니다.
            </Typography>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default HistoryList;
