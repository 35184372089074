import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RowStack from "../../../shared/ui/RowStack";

interface ProgressBarProps {
  maxCount: number;
  currentCount: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ maxCount, currentCount }) => {
  // 퍼센트 계산
  const percentage = Math.min((currentCount / maxCount) * 100, 100);

  // 색상 결정
  const getColor = (percentage: number): string => {
    if (percentage >= 75) return "#4caf50"; // Green (75% 이상)
    if (percentage >= 50) return "#ff9800"; // Orange (50% 이상)
    if (percentage >= 25) return "#ffc107"; // Amber (25% 이상)
    return "#f44336"; // Red (25% 미만)
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={1}>
      {/* Progress Percentage 표시 */}
      <RowStack sx={{ width: "100%" }}>
        <Typography variant="body1">{`Progress: ${Math.round(percentage)}%`}</Typography>
        <Typography variant="body1">{currentCount + "/" + maxCount}</Typography>
      </RowStack>

      {/* Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          width: "100%",
          height: 10,
          borderRadius: 5,
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": {
            backgroundColor: getColor(percentage),
          },
        }}
      />
    </Box>
  );
};

export default ProgressBar;
