import { customAxios } from "../../config/axios-config";
import { dateToString, stringToDate } from "../../shared/lib/convertStringToDate";
import {
  getOrderRequestDto,
  getOrderResponseDto,
  createOrderRequestDto,
  createOrderResponseDto,
  getOrderListResponseDto,
  updateOrderQuantityDto,
  updateOrderItemDto,
  updateOrderDto,
  updateSplitOrderItemDto,
  changeCenterDto,
  getOrderListRequestDto,
  deleteOrderDto,
  getOrderFulfillmentsRequestDto,
  getOrderFulfillmentsResponseDto,
  addOrderItemDto,
  getOrderItemsByBarCodeRequestDto,
  getOrderItemsByBarCodeResponseDto,
} from "./types";

export const orderApi = {
  getOrder: async (data: getOrderRequestDto): Promise<getOrderResponseDto> => {
    return await customAxios.get(`/orders/${data.OrderID}/`).then((res) => {
      const data = res.data;
      res.data.items = data.items.map((item: any, i: number) => {
        return {
          rowIdx: i + 1,
          Quantity_Error: (item.Quantity_OK || 0) - (item.Quantity_Ready || 0), // 정렬을 위해서는 Quantity_Error 라는 변수명이 꼭필요합니다. 없으면 오류수량 기준으로 정렬할 수 없습니다.
          BoxNumberList:
            item.Quantity_OK !== 0
              ? item.BoxNumberList
                ? item.BoxNumberList.filter((v: any) => v.Quantity !== 0)
                    .map((box: any, key: any) => `${box.ToObjectCode}(${box.Quantity})`)
                    .join("\n")
                : ""
              : "*",

          ...item,
        };
      });
      return data;
    });
  },
  getOrderList: async (data: getOrderListRequestDto): Promise<getOrderListResponseDto[]> => {
    const params = data;
    return await customAxios.get(`/orders/`, { params }).then((res) => res.data.map((data: getOrderListResponseDto) => stringToDate(data)));
  },
  createOrder: async (data: createOrderRequestDto): Promise<createOrderResponseDto> => {
    return await customAxios.post(`/orders/register_order/`, dateToString(data)).then((res) => stringToDate(res.data));
  },

  createOrderList: async (body: createOrderRequestDto[]): Promise<createOrderResponseDto[]> => {
    return await customAxios.post(`/orders/register_order/`, body).then((res) => res.data);
  },

  updateOrder: async (body: updateOrderDto): Promise<updateOrderDto> => {
    return await customAxios.patch(`/orders/${body.OrderID}/`, body).then(() => body);
  },

  updateOrderQuantity: async (data: updateOrderQuantityDto): Promise<updateOrderQuantityDto> => {
    const body = {
      Quantity_OK: data.Quantity_OK,
    };
    return await customAxios.patch(`/order_items/${data.OrderItemID}/`, data).then(() => data);
  },
  updateOrderItem: async (body: updateOrderItemDto): Promise<updateOrderItemDto> => {
    return await customAxios.patch(`/order_items/${body.OrderItemID}/`, body).then(() => body);
  },
  updateOrderItemBulk: async (body: updateOrderItemDto[]): Promise<updateOrderItemDto[]> => {
    return await customAxios.patch(`/order_items/bulk/`, body).then(() => body);
  },
  updateSplitOrderItem: async (body: updateSplitOrderItemDto[]): Promise<updateSplitOrderItemDto[]> => {
    return await customAxios.post("/order_items/splitOrderItem/", body).then(() => body);
  },
  changeCenter: async (body: changeCenterDto): Promise<changeCenterDto> => {
    return await customAxios.post("order_items/changeCenter/", body).then(() => body);
  },
  deleteOrder: async (data: deleteOrderDto): Promise<deleteOrderDto> => {
    return await customAxios.delete(`orders/${data.OrderID}/`).then(() => data);
  },
  getOrderFulfillments: async (params: getOrderFulfillmentsRequestDto): Promise<getOrderFulfillmentsResponseDto[]> => {
    return await customAxios.get(`order_fulfillments/getEndToteData/`, { params }).then((res) => res.data);
  },
  addOrderItem: async (body: addOrderItemDto): Promise<addOrderItemDto> => {
    return await customAxios.post(`orders/register_order_add/`, body).then(() => body);
  },
  updateHideOrderItems: async (data: number[]): Promise<any> => {
    return await customAxios.post(`order_items/hide_sajeon/`, data).then(() => data);
  },

  getOrderItemsByBarCode: async (data: getOrderItemsByBarCodeRequestDto): Promise<getOrderItemsByBarCodeResponseDto[]> => {
    const params = {
      barcode: data.BarCode,
      status: "R", // 준비중인것만 가져오는거임
    };
    return await customAxios
      .get(`/order_items/`, { params })
      .then((res) => res.data.map((data: any) => ({ ...data, Quantity_Error: data.Quantity_OK - data.Quantity_Ready })));
  },
};
