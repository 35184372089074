import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createOrderRequestDto,
  createOrderResponseDto,
  getOrderListResponseDto,
  getOrderRequestDto,
  getOrderResponseDto,
  ItemResponse,
  updateOrderItemDto,
  updateOrderDto,
  updateSplitOrderItemDto,
  changeCenterDto,
  getOrderListRequestDto,
  deleteOrderDto,
  getOrderFulfillmentsRequestDto,
  getOrderFulfillmentsResponseDto,
  addOrderItemDto,
  getOrderItemsByBarCodeRequestDto,
  getOrderItemsByBarCodeResponseDto,
} from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { addIdColumn } from "../../shared/lib/addIdColumn";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { Id } from "../../shared/type/Id";
import { orderApi } from "./orderAxios";
import { customToast } from "../../shared/lib/customToast";
import _ from "lodash";

export const useGetOrder = (data: getOrderRequestDto, isEdit?: boolean) => {
  return useQuery<getOrderResponseDto, Error>({
    queryKey: [QUERY_KEY.order, data.OrderID],
    queryFn: () => orderApi.getOrder(data),
    refetchInterval: isEdit ? false : 6000,
  });
};

export const useGetOrderList = (data: getOrderListRequestDto) => {
  return useQuery<getOrderListResponseDto[], Error, (getOrderListResponseDto & Id)[]>({
    queryKey: [QUERY_KEY.OrderList, data.brand, data.status],
    queryFn: () => orderApi.getOrderList(data),
    select: (data) => {
      return addIdColumn(data);
    },
  });
};

export const useCreateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation<createOrderResponseDto, Error, createOrderRequestDto>({
    mutationFn: (data: createOrderRequestDto) => orderApi.createOrder(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.OrderList] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: " 주문서 생성에 실패했습니다.", error: err });
    },
  });
};

export const useCreateOrderList = (onSuccess?: (data: any) => void) => {
  const queryClient = useQueryClient();
  return useMutation<createOrderResponseDto[], Error, createOrderRequestDto[]>({
    mutationFn: (data: createOrderRequestDto[]) => orderApi.createOrderList(data),
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.OrderList] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: " 주문서 생성에 실패했습니다.", error: err });
    },
  });
};

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation<updateOrderDto, Error, updateOrderDto>({
    mutationFn: (data: updateOrderDto) => orderApi.updateOrder(data),
    onSuccess: (data) => {
      // customToast("success", "발주서 상태를 변경하였습니다.");
      queryClient.setQueryData([QUERY_KEY.order, data.OrderID], (oldData: getOrderResponseDto | undefined) => {
        return { ...oldData, ...data };
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.OrderList] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "주문 업데이트에 실패하였습니다.", error: err });
    },
  });
};

export const useUpdateOrderItem = () => {
  const queryClient = useQueryClient();
  return useMutation<updateOrderItemDto, Error, updateOrderItemDto>({
    mutationFn: (data: updateOrderItemDto) => orderApi.updateOrderItem(data),
    onError: (err: Error) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
      displayErrorMessage({ defaultErrorMessage: "업데이트가 실패하였습니다.", error: err });
    },
  });
};

export const useUpdateOrderItemBulk = () => {
  const queryClient = useQueryClient();
  return useMutation<updateOrderItemDto[], Error, updateOrderItemDto[]>({
    mutationFn: (data: updateOrderItemDto[]) => orderApi.updateOrderItemBulk(data),
    onSuccess: () => {
      //캐싱값 업데이트
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
    },
    onError: (err: Error) => {
      displayErrorMessage({ defaultErrorMessage: "상세주문 업데이트에 실패하였습니다.", error: err });
    },
  });
};

export const useUpdateSplitOrderItem = () => {
  const queryClient = useQueryClient();
  return useMutation<updateSplitOrderItemDto[], Error, updateSplitOrderItemDto[]>({
    mutationFn: (data: updateSplitOrderItemDto[]) => orderApi.updateSplitOrderItem(data),
    onSuccess: (res) => {
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEY.Order, res.OrderID] });
      customToast("success", "사전확정이 변경되었습니다.");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.OrderList] });
    },
    onError: (err) => {
      // displayErrorMessage({ defaultErrorMessage: "사전확정변경에 실패했습니다. ", error: err });
      customToast("error", "사전확정 변경에 실패했습니다.");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.OrderList] });
    },
  });
};

export const useChangeCenter = () => {
  const queryClient = useQueryClient();
  return useMutation<changeCenterDto, Error, changeCenterDto>({
    mutationFn: (data: changeCenterDto) => orderApi.changeCenter(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.OrderList] });
    },
    onError: (err) => {
      customToast("error", "센터 변경에 실패하였습니다. 잠시 후 다시 시도해주세요.");
    },
  });
};

export const useDeleteOrder = (key: { BrandCode: string; status: string }) => {
  const queryClient = useQueryClient();
  return useMutation<deleteOrderDto, Error, deleteOrderDto>({
    mutationFn: (data: deleteOrderDto) => orderApi.deleteOrder(data),
    onSuccess: (data) => {
      customToast("info", "발주서가 삭제되었습니다.");
      queryClient.setQueryData([QUERY_KEY.OrderList, key.BrandCode, key.status], (oldData: getOrderListResponseDto[] | undefined) => {
        if (oldData) {
          return oldData.filter((order) => order.OrderID !== data.OrderID);
        }
        return [];
      });
    },
    onError: (err) => {
      customToast("error", "준비수량이 존재하여 삭제할 수 없습니다.");
    },
  });
};

export const useGetOrderFulfillments = (data: getOrderFulfillmentsRequestDto) => {
  return useQuery<getOrderFulfillmentsResponseDto[], Error>({
    queryKey: [QUERY_KEY.orderFulfillments, data.OrderItemID],
    queryFn: () => orderApi.getOrderFulfillments(data),
  });
};

export const useAdditionalOrder = () => {
  const queryClient = useQueryClient();
  return useMutation<addOrderItemDto, Error, addOrderItemDto>({
    mutationFn: (data: addOrderItemDto) => orderApi.addOrderItem(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
    },
    onError: () => {
      customToast("error", "주문서 추가에 실패 하였습니다. 잠시 후 다시 시도해주세요.");
    },
  });
};

export const useHideOrderItems = (success: (data: number[]) => void) => {
  return useMutation<number[], Error, number[]>({
    mutationFn: (data: number[]) => orderApi.updateHideOrderItems(data),
    onSuccess: (data) => {
      success(data);
      customToast("success", `${data.length}개의 항목에 대한 보관처리가 완료되었습니다.`);
    },
    onError: (err) => {
      customToast("error", "보관에 실패하였습니다.");
    },
  });
};

export const useGetOrderItemsByBarCode = (data: getOrderItemsByBarCodeRequestDto) => {
  return useQuery<getOrderItemsByBarCodeResponseDto[], Error>({
    queryKey: [QUERY_KEY.orderItem, data.BarCode],
    queryFn: () => orderApi.getOrderItemsByBarCode(data),
  });
};
