import * as React from "react";
import NewProductTable from "../entities/product/ui/NewProductTable";

const NewProduct: React.FC = () => {
  return (
    <>
      <NewProductTable />
    </>
  );
};

export default NewProduct;
