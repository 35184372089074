import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { searchProductResponseDto } from "../../../service/total-order/types";
import { useSearchProduct } from "../../../service/total-order/totalOrderListHooks";
import { FlexBoxModal } from "./modal/FlexModal";
import { GreenBasketModal } from "./modal/GreenBasketModal";
import { BoxModal } from "./modal/BoxModal";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { customToast } from "../../../shared/lib/customToast";
import { convertKoreanToUpperEnglish } from "../lib/convertKoreaToUpperEnglish";
import CardAlert from "./modal/CardAlert";
import { useGetActiveBarcodeAlertList } from "../../../service/barcode-alert/barcodeAlertHooks";

const BarCodeScanField = () => {
  const [value, setValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchInfo, setSearchInfo] = useState<searchProductResponseDto | null>(null);
  const { data: alertList } = useGetActiveBarcodeAlertList({ barcode: value });
  const { mutate: searhcProduct } = useSearchProduct(
    (data) => {
      setSearchInfo(data);
    },
    () => {
      if (value !== "") {
        customToast("warn", "등록되지 않은 제품입니다.");
      }
      dilayFocus(inputRef);
      handleModalClose();
    }
  ); // 제품 어디로 가는건지 검색하기

  const dilayFocus = useCallback((ref: any) => {
    setTimeout(() => {
      ref.current?.focus();
    }, 500);
  }, []);

  //enter를 눌러 제품이 어디로 가야하는지 받아옴
  const handleSearchProduct = (e: any) => {
    e.preventDefault();
    if (value.length > 0) {
      // 최소길이 9
      // searhcProduct({ Brand: BrandCode, BarCode: value });
      searhcProduct({ BarCode: value });
    }
  };

  //모달창 닫기
  const handleModalClose = useCallback(() => {
    setValue("");
    setSearchInfo(null);
  }, []);

  //
  useEffect(() => {
    if (value === "E") {
      setValue("");
    }
  }, [value]);

  useEffect(() => {
    dilayFocus(inputRef);
  }, []);

  const handleKeyDown = (e: any) => {
    const key = e.key;
    if (key === "C") {
      setTimeout(() => {
        setValue("");
      }, 500);
    }
  };

  const handleChangeValue = (e: any) => {
    const result = convertKoreanToUpperEnglish(e.target.value.trim());
    setValue(result);
  };

  return (
    <>
      <form onSubmit={handleSearchProduct}>
        <TextField
          sx={{ backgroundColor: "white", minWidth: "200px", width: "400px" }}
          size="small"
          inputRef={inputRef}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChangeValue}
          placeholder="바코드를 입력하세요."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <QrCode2Icon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </form>
      <Button />
      {searchInfo && (
        <>
          <Stack sx={{ position: "absolute", zIndex: 10000, right: "50%", transform: "translateX(50%)", top: "50px" }} gap={2} direction="row">
            {alertList?.map((alert, index) => (
              <CardAlert key={index} imageSrc={alert.image_url || ""} message={alert.message || ""} desc_text={alert.desc_text || ""} />
            ))}
            {searchInfo.ProductName.includes("선물") && (
              <CardAlert imageSrc={"https://cdn-icons-png.flaticon.com/128/5206/5206311.png"} message={"선물세트인지 확인해주세요!"} />
            )}
            {searchInfo.isCustomerOrder && (
              <CardAlert imageSrc={"https://cdn-icons-png.flaticon.com/128/891/891419.png"} message={"개인고객 주문건 입니다!"} />
            )}
            {searchInfo.ProductName.includes("03109") && (
              <CardAlert imageSrc={"https://cdn-icons-png.flaticon.com/128/1154/1154828.png"} message={"벨트를 확안해주세요!"} />
            )}
            {regex.test(searchInfo.ProductName) && (
              <CardAlert
                imageSrc={"https://cdn-icons-png.flaticon.com/128/12712/12712897.png"}
                message={`${searchInfo.ProductName.match(regex)?.[0] || ""} 를 추가하세요!`}
              />
            )}
          </Stack>
          <FlexBoxModal open={searchInfo.ToType === "flex"} info={searchInfo} onClose={handleModalClose} />
          <GreenBasketModal BarCode={value} open={searchInfo.ToType === "greenBasket"} info={searchInfo} onClose={handleModalClose} />
          <BoxModal open={searchInfo.ToType === "box"} info={searchInfo} onClose={handleModalClose} />
        </>
      )}
    </>
  );
};

//스카프링 정규식
const regex = /스카프\+?링\s*[A-D]/;

export default BarCodeScanField;
