import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Stack, Typography, Tooltip } from "@mui/material";

import { useUpdateProductBulk } from "../../../../service/product/productHooks";
import { customToast } from "../../../../shared/lib/customToast";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import * as XLSX from "xlsx";
import QueueIcon from "@mui/icons-material/Queue";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useQueryClient } from "@tanstack/react-query";
import ErrorList from "../../../customer-find-order/ui/upload-customer-order-file-modal/UploadErrorList";

const keyList = [
  "ProductCode",
  "Season",
  "ProductName",
  "Color",
  "Size",
  "BarCode",
  "SKU_ID",
  "SupplierCompany",
  "OrderLink",
  "QuotedPrice",
  "SalePrice",
  "ChinaCost",
  "LocalDeliveryFee",
  "KoreaCost",
  "TotalCost",
  "DeliveryPrice",
  "VAT",
  "RocketMargin",
  "AccountingCost",
  "OurMargin",
  "Memo0",
  "Memo00",
  "Status",
];

type ErrorType = {
  errors: string[];
  success: string[];
};

const initialValue = {
  errors: [],
  success: [],
};

export const UpdateProductButton: React.FC = () => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState<ErrorType>(initialValue);

  useEffect(() => {
    setResult(initialValue);
  }, [open]);

  const handleClose = () => {
    setResult(initialValue);
    setOpen(false);
  };
  const { mutate: updateProductBulk } = useUpdateProductBulk((data) => {
    const errors: string[] = data?.errors?.map((v: any) => v.item.BarCode) || [];
    const success: string[] = data?.success?.map((v: any) => v.BarCode) || [];
    const temp: ErrorType = { errors, success };
    setResult(temp);
  });

  const productExcelUploadRef = useRef<HTMLInputElement>(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const calcSeasonValue = (text: string) => {
    if (text.includes("봄") || text.includes("가을")) return 2;
    if (text.includes("여름")) return 1;
    if (text.includes("겨울")) return 8;
    return 2;
  };

  const handleProductFileUpload = async (files: FileList | null) => {
    const file = files?.[0];

    if (!file) {
      customToast("info", "파일이 없습니다.");
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const dto = rows
        .slice(1)
        .filter((v) => !!v[5])
        .map((row) => {
          const result: any = {};
          keyList.forEach((key, i) => {
            if (!!row[i]) {
              result[key] = typeof row[i] === "string" ? row[i].trim() : row[i];
              if (key === "Season") {
                result["Volume"] = calcSeasonValue(row[i] || "");
              }
            }
          });

          return result;
        });

      //업데이트하는 과정
      if (dto.length !== 0) updateProductBulk(dto);

      // 실패한 항목 뭔지 찾아보는 코드

      // if (dto.length !== 0) {
      //   for (let i = 0; i < dto.length; i++) {
      //     const BarCode = dto[i].BarCode;
      //     try {
      //       await updateProduct(dto[i]);
      //       temp.success.push(BarCode );
      //     } catch (e) {
      //       temp.errors.push( BarCode );
      //     }
      //   }
      // }
      queryClient.invalidateQueries();
      //여기 업데이트 코드 넣기

      // if (temp.errors.length === 0) {
      //   setOpen(false);
      // } else {
      //   setResult(temp);
      // }
    };
    reader.readAsArrayBuffer(file); //업로드 실행
    productExcelUploadRef!.current!.value = ""; // 파일 초기화
  };

  const handleDownload = async () => {
    window.location.href = "https://paperlife.kr/template/product_update_form.xlsx";
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} variant="contained" size="large" startIcon={<QueueIcon />}>
        일괄 업데이트
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {" "}
          상품 일괄 업데이트
          <Tooltip
            title={
              <Typography variant="caption">
                사품등록과 엑셀 양식이 다르며
                <br />
                바코드를 기준으로 상품이 업로드 됩니다.
                <br />
                업로드 할 때 엑셀의 빈칸은 무시됩니다.
              </Typography>
            }
          >
            <HelpOutlineIcon color={"primary"} />
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                size="large"
                startIcon={<FileUploadIcon />}
                fullWidth
                sx={{ width: "250px", height: "80px" }}
              >
                파일 업로드
              </Button>
            </label>
            <input
              ref={productExcelUploadRef}
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              accept=".xlsx"
              onChange={(e) => handleProductFileUpload(e.target.files)}
            />

            <Button variant="outlined" sx={{ width: "250px", height: "80px" }} onClick={handleDownload} startIcon={<InsertDriveFileOutlinedIcon />}>
              양식 다운로드
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorList data={result} onClose={handleClose} />
    </React.Fragment>
  );
};
