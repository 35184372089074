import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState, useMemo, useEffect, useRef, createContext, useContext } from "react";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import { useDeleteTargetFind, useGetTargetFindList, useUpdateTargetFindMemo } from "../../../service/target-find/targetFindHooks";
import { getTargetFindItemDto } from "../../../service/target-find/types";
import { exportToExcel } from "../../../shared/lib/exportToExcel";
import RowStack from "../../../shared/ui/RowStack";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import { KEY_LIST, COLUMN_LIST, FILE_NAME } from "../../rocket/const/const";
import { SUBTITLE_COLOT, MAIN_CONTENT_COLOR } from "../../rocket/ui/RocketTable";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import { useModals } from "../../../service/modal/useModals";
import { ShowInventoryModal } from "../../../widget/show-inventory-modal/ui/ShowInventoryModal";
import { copyToClicpboard } from "../../rocket/lib/copyToClipBoard";
import LabelWithContent from "./LabelWithContent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";

const INTERVAL = 20;
const SelectedContext = createContext<any>(null);
const NewTargetFindTable = () => {
  const { data, refetch, isFetching } = useGetTargetFindList();
  const { mutateAsync: deleteTargetFind } = useDeleteTargetFind();
  const [selected, setSelected] = useState<number[]>([]);
  const observerRef = useRef(null);
  const [filter, setFilter] = useState("");
  const [viewList, setViewList] = useState<getTargetFindItemDto[]>(data ? [...data.slice(0, INTERVAL)] : []);
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [location, setLocation] = useState<string>("전체");

  const handleLocationChange = (e: any) => {
    setLocation(e.target.value as string);
  };
  //view index가 변경될때마다 확인하기
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //콜백함수
        if (data && viewList.length < data.length && entries[0].isIntersecting) {
          setViewList([...viewList, ...data.slice(viewIndex, viewIndex + INTERVAL)]);
          setViewIndex(viewIndex + INTERVAL);
        }
      },
      { threshold: 0.8 }
    );

    if (observerRef.current) observer.observe(observerRef.current); //관찰시작

    // 컴포넌트가 언마운트될 때 옵저버 해제
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current); //관찰해제
    };
  }, [viewList, viewIndex]);

  useEffect(() => {
    setViewList(data ? [...data.slice(0, INTERVAL)] : []); //스프레드로 초기화 해야 viewList가 변경될것 같았음
    setViewIndex(INTERVAL); //인덱스 초기화
  }, [data]);

  const handleDeleteAll = async () => {
    if (confirm("정말 삭제하시겠습니까?")) {
      for (let FindItemID of selected) {
        await deleteTargetFind({ FindItemID });
      }
      setSelected([]);
    }
  };

  //검색한 결과를 필터링하는 함수
  const filterdRow = useMemo(() => {
    if (!viewList) return [];
    if (location === "전채" && filter === "") return viewList;

    return viewList
      .filter((row) => {
        return Object.values(row).some((value) => {
          const str = !!value ? value.toString() : "";
          return str.includes(filter);
        });
      })
      .filter((v) => {
        switch (location) {
          case "전체":
            return true;
          case "1":
            return v.Quantity_Find === v.Quantity_Target;
          case "0":
            return v.Quantity_Find !== v.Quantity_Target;
          default:
            return true;
        }
      });
  }, [viewList, filter, location]);

  const { totalListCount, totalQuantityToFind, totalQuantityTarget, completedItems, remainingItems } = useMemo(() => {
    let totalListCount = 0;
    let totalQuantityToFind = 0;
    let totalQuantityTarget = 0;
    let completedItems = 0;
    let remainingItems = 0;

    filterdRow.forEach((item: getTargetFindItemDto) => {
      totalListCount += 1;
      totalQuantityToFind += item.Quantity_Find;
      totalQuantityTarget += item.Quantity_Target;

      if (item.Quantity_Find === item.Quantity_Target) {
        completedItems += 1;
      } else {
        remainingItems += 1;
      }
    });

    return { totalListCount, totalQuantityToFind, totalQuantityTarget, completedItems, remainingItems };
  }, [filterdRow]);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <SelectedContext.Provider value={{ selected, setSelected }}>
      <BackDropLoading open={isFetching} />

      <Stack sx={{ margin: "0 auto", my: 10, width: "1200px" }} gap={4}>
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          임의 찾기
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    총 목록
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalListCount} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    찾을 수량
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalQuantityToFind} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    찾은수량
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {totalQuantityTarget} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    완료된 항목
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {completedItems} 건
                  </Typography>
                </Box>
                <Box sx={{ width: "120px", mr: "auto" }}>
                  <Typography variant="caption" color={SUBTITLE_COLOT}>
                    진행중인 항목
                  </Typography>
                  <Typography color={MAIN_CONTENT_COLOR} variant="h5">
                    {remainingItems} 건
                  </Typography>
                </Box>

                {/* 검색창 */}

                <SearchInputField value={filter} onChange={(e) => setFilter(e.target.value)} />

                <IconButton>
                  <RefreshIconButton onClick={() => refetch()} />
                </IconButton>
                {/* <IconButton
                  id="basic-button"
                  aria-controls={menuOpen ? "basic-menu" : undefined}
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={handleOpenMenu}
                ></IconButton>

                <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
                  <MenuItem onClick={() => exportToExcel({ data: data || [], keyList: KEY_LIST, columns: COLUMN_LIST, fileName: FILE_NAME })}>
                    <Tooltip
                      title={
                        <pre style={{ fontSize: "12px" }}>
                          부족 수량이 0이상인
                          <br />
                          제품만 다운로드됩니다.
                        </pre>
                      }
                      arrow
                      placement="top"
                    >
                      <a>
                        <ListItemIcon><DownloadIcon />adsf</ListItemIcon>
                        엑셀 다운로드
                      </a>
                    </Tooltip>
                  </MenuItem>
                </Menu> */}
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <RowStack gap={5}>
              <FormControl sx={{ ml: 3, mr: "auto" }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "row", gap: 5 }} value={location} onChange={handleLocationChange}>
                  <FormControlLabel value="전체" control={<Radio />} label="전체" />
                  <FormControlLabel value="1" control={<Radio />} label="완료" />
                  <FormControlLabel value="0" control={<Radio />} label="진행 중" />
                </RadioGroup>
              </FormControl>
              <Button color="error" size="large" startIcon={<DeleteOutlineIcon />} disabled={selected.length === 0} onClick={handleDeleteAll}>
                일괄 삭제 [{selected.length}]
              </Button>
              <CreateTargetFindButton />
            </RowStack>
          </Grid>
          {filterdRow?.map((item) => (
            <RocketItem key={item.BarCode} data={item} />
          ))}
          {data && viewList.length !== data.length && (
            <Typography variant="subtitle1" ref={observerRef} sx={{ textAlign: "cetner" }}>
              더보기
            </Typography>
          )}
        </Grid>
      </Stack>
    </SelectedContext.Provider>
  );
};
export default NewTargetFindTable;

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ProgressBar from "./ProgressBar";
import { customToast } from "../../../shared/lib/customToast";
import { CreateTargetFindButton } from "./create-targe-find-modal/CreateTargetFindModal";
interface RocketItemProps {
  data: getTargetFindItemDto;
}

export const RocketItem: React.FC<RocketItemProps> = ({ data }) => {
  const { selected, setSelected } = useContext(SelectedContext);
  const { BarCode, Color, RackID, Size, Status, Quantity_Find, Quantity_Target, Detail01, Detail02, Name, CreatedAt, FindItemID } = data;
  const { closeModal, openModal } = useModals();
  const [detial01, setDetail01] = useState(Detail01);
  const [detial02, setDetail02] = useState(Detail02);

  const { mutate: deleteTargetFind } = useDeleteTargetFind();
  const { mutate: updateTargetFindMemo } = useUpdateTargetFindMemo();

  const handleDeleteTargetFind = () => {
    deleteTargetFind({ FindItemID });
  };

  const handleSelected = (e: any) => {
    if (e.target.checked) {
      setSelected([...selected, FindItemID]);
    } else {
      setSelected(selected.filter((id: number) => id !== FindItemID));
    }
  };

  const handleUpdateMemo = () => {
    updateTargetFindMemo({ FindItemID: FindItemID.toString(), Detail01: detial01 });
    customToast("info", "블러됨!");
  };

  const handleClose = () => {
    closeModal(ShowInventoryModal);
  };

  const handleClickOpen = () => {
    openModal(ShowInventoryModal, { BarCode, handleClose, status });
  };

  const handleCopyClipboard = () => {
    copyToClicpboard(BarCode);
  };

  return (
    <Grid item xs={3}>
      <Card
        sx={{
          p: 6,
          backgroundColor: "#fff",
          // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Stack gap={5}>
          <Stack gap={2} sx={{ height: "150px" }} alignItems={"start"}>
            <RowStack sx={{ width: "100%" }}>
              <Checkbox checked={selected.includes(FindItemID)} onChange={handleSelected} />
              <Typography variant="caption" color="#999">
                {CreatedAt.slice(0, 10)}
              </Typography>
            </RowStack>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ width: "100%" }}>
              {/* <Chip sx={{ px: 1 }} label={BarCode} variant="outlined" color="primary" /> */}
              <Typography fontSize={"18px"} color="primary">
                {BarCode}
              </Typography>
              <Tooltip title={<p>클릭하면 바코드가 복사됩니다.</p>} placement="top" arrow>
                <IconButton onClick={handleCopyClipboard}>
                  <ContentCopyIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography variant="h6" color="#333">
              {Name}
            </Typography>
          </Stack>

          <Grid container>
            <Grid item xs={12}>
              <LabelWithContent label="색상" content={Color} />
            </Grid>
            <Grid item xs={12}>
              <LabelWithContent label="사이즈" content={Size} />
            </Grid>
            <Grid item xs={12} onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
              <LabelWithContent label="대표랙" content={RackID} contentColor="#0b57d0" contentSx={{ textDecoration: "underline" }} />
            </Grid>
            <Grid item xs={12}>
              <ProgressBar maxCount={Quantity_Target} currentCount={Quantity_Find} />
            </Grid>
          </Grid>
          <Divider />
          <Stack gap={2}>
            <TextField value={detial01} onChange={(e) => setDetail01(e.target.value)} label="메모1" multiline rows={3} onBlur={handleUpdateMemo} />
            {/* <TextField value={Detail02} label="메모2" multiline rows={3} /> */}
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};
