import styled from "@emotion/styled";

export const CardContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 100%;
`;


