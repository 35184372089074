import React, { createContext, useContext, useState, ReactNode } from "react";

//db 연결 전, 엑셀 파일 데이터 전역변수에 저장

//storePage에서도 동일하게 사용함
//매핑 후 db에 매핑데이터 저장 후 excelData변수 값 초기화
//엑셀 데이터 매핑할 때만 사용 후 실제 저장 데이터는 db에 있기 때문에
//동일한 전역변수 사용하려고 함,,

interface ExcelContextProps {
  excelData: any[]; // 실제 데이터 타입에 맞게 정의해야 함
  setExcelData: React.Dispatch<React.SetStateAction<any[]>>;
}
interface ExcelDataProviderProps {
  children: ReactNode;
}

const DataContext = createContext<ExcelContextProps | undefined>(undefined);

export const ExcelDataProvider: React.FC<ExcelDataProviderProps> = ({ children }) => {
  const [excelData, setExcelData] = useState<any[]>([]);

  return <DataContext.Provider value={{ excelData, setExcelData }}>{children}</DataContext.Provider>;
};

export const useExcelData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useMappedData must be used within a MappedDataProvider");
  }
  return context;
};
