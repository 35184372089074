import { customAxios } from "../../config/axios-config";
import {
  getBoxNameListByOrderItemIdRequestDto,
  getBoxNameListByOrderItemIdResponseDto,
  getIncommingProductByBarCodeRequestDto,
  getIncommingProductByBarCodeResponseDto,
  getProductListInToteRequestDto,
  getProductListinToteResponseDto,
  getTotalOrderListResponseDto,
  moveTableToBasketDto,
  searchProductRequsetDto,
  searchProductResponseDto,
  updateFulfillmentsDto,
  updateTotalOrderListMemoDto,
} from "./types";

export const totalOrderListApi = {
  getTotalOrderList: async (): Promise<getTotalOrderListResponseDto[]> => {
    // params.isStockZero = 1;
    return await customAxios.get("/order_items/getAllData/").then((res) => res.data);
  },
  updateTotalOrderListMemo: async (data: updateTotalOrderListMemoDto): Promise<updateTotalOrderListMemoDto> => {
    const { OrderItemID, ...body } = data;
    return await customAxios.patch(`/order_items/${OrderItemID}/`, body).then(() => data);
  },
  searchProduct: async (body: searchProductRequsetDto): Promise<searchProductResponseDto> => {
    return await customAxios.post("/order_items/checkOrderItem/", body).then((res) => res.data);
  },
  updateFulfillments: async (body: updateFulfillmentsDto): Promise<updateFulfillmentsDto> => {
    return await customAxios.post("/order_fulfillments/", body).then(() => body);
  },
  getProductListInTote: async (params: getProductListInToteRequestDto): Promise<getProductListinToteResponseDto[]> => {
    return await customAxios.get("/order_fulfillments/getToteData/", { params }).then((res) => res.data);
  },

  moveTableToBasket: async (data: moveTableToBasketDto): Promise<moveTableToBasketDto> => {
    const body = {
      ...data,
      Type: "TB",
      from: "table",
    };
    return await customAxios.post("/inventories/move/", body).then(() => data);
  },

  getIncommingProductByBarCode: async (params: getIncommingProductByBarCodeRequestDto): Promise<getIncommingProductByBarCodeResponseDto[]> => {
    return await customAxios.get("/incoming_order_items/getIncomingData/", { params }).then((res) => res.data);
  },
  getBoxNameListByOrderItemId: async (params: getBoxNameListByOrderItemIdRequestDto): Promise<getBoxNameListByOrderItemIdResponseDto[]> => {
    return await customAxios.get("/order_fulfillments/getBoxNumber/", { params }).then((res) => res.data);
  },
};
