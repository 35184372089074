export const boxStyle = {
  border: "1px solid #e0e0e0",
  height: "50px",
  width: "30px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.2rem",
};
