import React, { FormEvent, useMemo, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Stack, Box, Tooltip, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createProductDto } from "../../../../service/product/types";
import { useBrandCodeStore } from "../../../../store/brandStore";
import { useCreateAllProduct } from "../../../../service/product/productHooks";
import { customToast } from "../../../../shared/lib/customToast";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import * as XLSX from "xlsx";
import RowStack from "../../../../shared/ui/RowStack";
import { UploadChangeBrandButton } from "../upload-change-brand-button/UploadChangeBrandButton";

export const CreateProductButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { BrandCode } = useBrandCodeStore((state) => state);
  const { mutate: createAllProduct } = useCreateAllProduct();
  const productExcelUploadRef = useRef<HTMLInputElement>(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e: FormEvent) => {
    e.preventDefault();
    setOpen(false);
  };

  const calcSeasonValue = (text: string) => {
    if (text.includes("봄") || text.includes("가을")) return 2;
    if (text.includes("여름")) return 1;
    if (text.includes("겨울")) return 8;
    return 2;
  };

  const handleProductFileUpload = (files: FileList | null) => {
    const file = files?.[0];

    if (!file) {
      customToast("info", "파일이 없습니다.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const dto: createProductDto[] = rows
        .slice(1)
        .filter((v) => !!v[5])
        .map((row) => {
          return {
            BarCode: row[5] || "",
            ProductCode: row[0] || "",
            ProductName: row[2] || "",
            Season: row[1] || "",
            Color: row[3] || "",
            Size: row[4] || "",
            SKU_ID: row[6] || "",
            SupplierCompany: row[7] || "",
            OrderLink: row[8] || "",
            QuotedPrice: row[9] || 0,
            SalePrice: row[10] || 0,
            ChinaCost: row[11] || 0,
            LocalDeliveryFee: row[12] || 0,
            KoreaCost: row[13] || 0,
            TotalCost: row[14] || 0,
            DeliveryPrice: row[15] || 0,
            VAT: row[16] || 0,
            RocketMargin: row[17] || 0,
            AccountingCost: row[18] || 0,
            OurMargin: row[19] || 0,
            Memo0: row[20] || "",
            Memo00: "",
            Brand: BrandCode || "",
            Status: "메인",
            Volume: calcSeasonValue(row[1] || ""),
            TotalCount: 0,
          };
        });

      if (dto.length !== 0) createAllProduct(dto); // 업로드 실행
    };
    reader.readAsArrayBuffer(file); //업로드 실행
    productExcelUploadRef!.current!.value = ""; // 파일 초기화
    setOpen(false);
  };

  const handleDownload = async () => {
    window.location.href = "https://paperlife.kr/template/product_list_form.xlsx";
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} variant="contained" size="large" startIcon={<AddCircleOutlineIcon />}>
        상품등록
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>상품 일괄 업로드</DialogTitle>

        <DialogContent>
          <Stack gap={2}>
            <RowStack>
              <UploadChangeBrandButton />
            </RowStack>
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                size="large"
                startIcon={<FileUploadIcon />}
                fullWidth
                sx={{ width: "250px", height: "80px" }}
              >
                파일 업로드
              </Button>
            </label>
            <input
              ref={productExcelUploadRef}
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              accept=".xlsx"
              onChange={(e) => handleProductFileUpload(e.target.files)}
            />

            <Button variant="outlined" sx={{ width: "250px", height: "80px" }} onClick={handleDownload} startIcon={<InsertDriveFileOutlinedIcon />}>
              양식 다운로드
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
