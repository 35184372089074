import { Button } from "@mui/material";
import React from "react";
import { useModals } from "../../../service/modal/useModals";
import { ShowBoxNameListModal } from "./ShowBoxNameListModal";
import { CustomButton } from "../../../shared/ui/buttom/CustomButton";
import { ItemResponse } from "../../../service/order/types";

interface ShowBoxNameListButtonProps {
  BarCode: string;
  Quantity_Ready: number;
  OrderItemID: number;
  Center: string;
  row: ItemResponse;
  OrderID: number;
}

const ShowBoxNameListButton: React.FC<ShowBoxNameListButtonProps> = ({ OrderID, row, BarCode, Quantity_Ready, Center, OrderItemID }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowBoxNameListModal);
  };

  const handleClickOpen = () => {
    openModal(ShowBoxNameListModal, { OrderID, BarCode, handleClose, Center, OrderItemID, row });
  };

  return (
    <React.Fragment>
      <CustomButton onClick={handleClickOpen}>{Quantity_Ready.toLocaleString()}</CustomButton>
    </React.Fragment>
  );
};

export default ShowBoxNameListButton;
