export const ADMIN_COLUMNS = {
  UserName: true,
  RoleID: true,
  password: true,
  last_login: true,
  ExpireTime: true,
  actions: true,
};
export const NORMAL_COLUMNS = {
  UserName: true,
  RoleID: true,
  password: false,
  last_login: true,
  ExpireTime: true,
  actions: false,
};
