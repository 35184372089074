import { useModals } from "../../../../service/modal/useModals";

import { Box, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { ChangeBrandModal } from "./ChangeBrandModal";
import { useBrandCodeStore } from "../../../../store/brandStore";
import { brandIconOptions, BrandIconType } from "../../const/brandIconsOption";

export const ChangeBrandButton: React.FC = () => {
  const { openModal, closeModal } = useModals();
  const setBrandCode = useBrandCodeStore((state) => state.setBrandCode);
  const BrandCode = useBrandCodeStore((state) => state.BrandCode);
  const handleClose = () => {
    closeModal(ChangeBrandModal);
  };

  //props로 내릴 함수
  const handleSubmit = (brandCode: string) => {
    //여기서 axios 통신하고 성공하면 react-query에서 세팅하는게 맞음 여기에 setUserInfo를한건 임시임
    setBrandCode(brandCode); // 유저 정보에 포함
    handleClose();
  };

  //누르면 모달창 열리게
  const handleClickOpen = () => {
    openModal(ChangeBrandModal, { handleClose, handleSubmit });
  };

  const brandMap: Map<string, BrandIconType> = useMemo(() => {
    return new Map(brandIconOptions.map((item) => [item.brandCode, item]));
  }, []);

  return (
    <React.Fragment>
      <Tooltip title={<p>브랜드를 선택해주세요.</p>} arrow placement="right">
        <Box onClick={handleClickOpen} sx={{ cursor: "pointer", height: "45px" }}>
          {!BrandCode ? (
            <Box sx={{ p: 1, width: "50px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <DoNotDisturbIcon fontSize="large" color="disabled" />
            </Box>
          ) : (
            <Box
              component="div"
              sx={{
                width: "45px",
                height: "100%",
                backgroundImage: `url(${brandMap.get(BrandCode)?.imagePath || ""})`,
                backgroundSize: "cover", // 이미지 크기를 요소에 맞춤
                backgroundPosition: "center", // 이미지 위치를 가운데로 설정
                backgroundRepeat: "no-repeat", // 이미지 반복을 방지
              }}
            />
          )}
        </Box>
      </Tooltip>
    </React.Fragment>
  );
};
