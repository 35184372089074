import BarCodAlerTable from "../entities/barcode-alert/ui/BarCodeAlertTable";

const BarCodeAlert = ()=>{
    return (
        <>
        <BarCodAlerTable/>
        </>
    )
}

export default BarCodeAlert;