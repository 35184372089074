import { inisialDataType } from "../type/type";

export const inisialData: inisialDataType = {
    leftNumber: "",
    rightNumber: "",
    leftType: "",
    rightType: "",
    leftSelected: [],
    rightSelected: [],
    leftData: [],
    rightData: [],
    history: [],
  };