import { useModals } from "../../../../service/modal/useModalsTest";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateOrderModal from "./CreateOrderModal";
import React, { createContext, useState } from "react";
import { createOrderRequestDto } from "../../../../service/order/types";
import { useExcelData } from "../../../../service/order/orderContext";

export const ModalDataContext = createContext<createOrderRequestDto | null>(null);

export const CreateOrderButton: React.FC = () => {
  const [modalData, setModalData] = useState<createOrderRequestDto | null>(null);
  const { openModal, closeModal } = useModals();
  const { setExcelData } = useExcelData();

  const handleClose = () => {
    setModalData(null);
    setExcelData([]);
    closeModal(CreateOrderModal);
  };

  const handleClickOpen = () => {
    openModal(CreateOrderModal, { open: true, handleClose, handleSubmit: () => {} });
  };

  return (
    <React.Fragment>
      <ModalDataContext.Provider value={modalData}>
        <Button variant="contained" size="large"startIcon={<AddCircleOutlineIcon />} onClick={handleClickOpen} sx={{width:"170px" }}>
          발주서 등록
        </Button>
      </ModalDataContext.Provider>
    </React.Fragment>
  );
};
