import * as XLSX from "xlsx";
import { getCurrentFormattedTime } from "./getCurrentFormattedTime";

/**
 * 객체 배열을 excel로 다운로드
 *
 * @param data // 객체 배열
 * @param keyList // 객체의 key값 배열 => 시트에서 나오는 순서를 결정
 * @param columns // key값에 대한 한글 컬럼명 => keyList와 같은 순서로 작성
 * @param fileName // 파일명
 * @returns
 */

export const exportToExcel = (params: { data: any[]; keyList: string[]; columns: string[]; fileName: string }) => {
  const { data, keyList, columns, fileName } = params;
  if (!data.length) return;

  const formattedData = data.map((item, index) => {
    let obj: any = {};
    obj["연번"] = index + 1;
    keyList.forEach((key, idx) => {
      obj[columns[idx]] = item[key];
    });
    return obj;
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");

  const safeFileName = `[${getCurrentFormattedTime()}]${fileName}.xlsx`;
  XLSX.writeFile(workbook, safeFileName);
};
