import { toast } from "react-toastify";
import _ from "lodash";

type toastType = "success" | "error" | "info" | "warn";
/**
 * 쓰로틀링을 적용한 토스트 메시지
 * @param type 토스트 타입
 * @param message 토스트 메시지
 */
const throttledToast = _.throttle((type: toastType, message: any) => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "error":
      toast.error(message);
      break;
    case "warn":
      toast.warn(message);
      break;
    default:
      toast.info(message);
      break;
  }
}, 500); // 0.3초 (300ms)에 한 번씩만 실행

// customToast 함수
export const customToast = (type: toastType, message: any) => {
  throttledToast(type, message);
};
