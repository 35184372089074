import { create } from "zustand";

type statusType = "R" | "E" | "전체" | "";

type State = {
  status: statusType;
};

type Actions = {
  setStatus: (data: statusType) => void;
};

export const useOrderStatusStore = create<State & Actions>((set) => ({
  status: "R",
  setStatus: (data: statusType) => {
    set((state) => ({
      ...state,
      status: data,
    }));
  },
}));
