import { Button } from "@mui/material";
import React from "react";
import { useModals } from "../../service/modal/useModals";
import ProductListInRackModal from "./ProductListInRackModal";
interface ProductListButtonProps {
  code: string;
  brand?: string;
}

const ProductListInRackButton: React.FC<ProductListButtonProps> = ({ code, brand = "" }) => {
  const { openModal, closeModal } = useModals();

  const handleClose = () => {
    closeModal(ProductListInRackModal); // rack
  };

  const handleClickOpen = () => {
    openModal(ProductListInRackModal, { code, brand, handleClose }); // rack
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        보기
      </Button>
    </React.Fragment>
  );
};

export default ProductListInRackButton;
