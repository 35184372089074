/**
 * text를 클립보드에 복사합니다.
 * @param text
 */
export function copyClipboard(text: string) {
  const input = document.createElement("textarea");
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
}
