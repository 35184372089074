import { Dialog, DialogTitle, DialogContent, Stack, Typography, DialogActions, Button, Divider, IconButton, TextField } from "@mui/material";
import { useGetInventoryByProductId } from "../../../service/inventory/inventoryHooks";
import { useEffect, useMemo, useState } from "react";
import { getInventoryByProductIdResponseDto } from "../../../service/inventory/types";
import AddIcon from "@mui/icons-material/Add";
import { useUpdateProductWithRack } from "../../../service/product/productHooks";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../config/querykey";
import { useBrandCodeStore } from "../../../store/brandStore";
import { CardContainer } from "../style/style";
import { AddRackInProduct } from "./AddRackInProduct";
import { getProductResponseDto } from "../../../service/product/types";
import { cloneDeep } from "lodash";
import { AddBasketInProduct } from "./AddBasektInProduct";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { customToast } from "../../../shared/lib/customToast";
import RowStack from "../../../shared/ui/RowStack";
interface ShowInventoryModalProps {
  open: boolean;
  handleClose: () => void;
  BarCode: string;
  status?: string;
}
export const ShowInventoryModal: React.FC<ShowInventoryModalProps> = ({ open, handleClose, BarCode, status = "메인" }) => {
  const queryClient = useQueryClient();
  const { data, refetch, isError } = useGetInventoryByProductId({ BarCode });
  const [tempData, setTempData] = useState<getInventoryByProductIdResponseDto[]>([]);
  const [addRackModalOpen, setAddRackModalOpen] = useState<boolean>(false);
  const [addBasketModalOpen, setAddBasketModalOpen] = useState<boolean>(false);

  //랙에 있는 상품 업데이트
  const { mutate: updateProductWithRack } = useUpdateProductWithRack(() => {
    queryClient.invalidateQueries();
  });

  useEffect(() => {
    refetch(); // 열때마다 새로고침
  }, [open]);

  //상품목록 재고 업데이트할 최종 값
  const totalQuantity = useMemo(() => {
    return tempData.reduce((acc, v) => acc + v.Quantity, 0);
  }, [tempData]);

  //모달창 열기
  const handleChangeRackModalOpen = () => {
    setAddRackModalOpen(!addRackModalOpen);
  };

  //모달창 열기
  const handleChangeBasektModalOpen = () => {
    setAddBasketModalOpen(!addBasketModalOpen);
  };

  useEffect(() => {
    if (data) {
      setTempData(data); // 값을 변경하기 위해 임시로 설정
    } else {
      setTempData([]);
    }
  }, [open, data]);

  //setTempData 값 수정
  const handleQuantityChange = (id: any, delta: number) => {
    const updatedTempData = tempData.map((item, index) => {
      if (index === id) {
        const newQuantity = Math.min(2000, Math.max(0, item.Quantity + delta));
        return { ...item, Quantity: newQuantity };
      }
      return item;
    });
    setTempData(updatedTempData);
  };

  // 서버에 보는 값 세팅
  const handleRackSubmit = (newValue: number, oldValue: number, RackID: number) => {
    const count = newValue - oldValue;
    if (newValue === 0) {
      setTempData(tempData.filter((item) => item.RackID !== RackID).map((v) => v));
    }
    if (count === 0) return;
    if (count < 0) {
      updateProductWithRack({ BarCode, to: "table", from: RackID.toString(), Type: "RT", count: Math.abs(count) });
    } else {
      updateProductWithRack({ BarCode, to: RackID.toString(), from: "table", Type: "TR", count: Math.abs(count) });
    }
    refetch();
  };

  const handleBasketSubmit = (newValue: number, oldValue: number, BasketID: number) => {
    const count = newValue - oldValue;
    if (newValue === 0) {
      setTempData(tempData.filter((item) => item.BasketID !== BasketID).map((v) => v));
    }
    if (count === 0) return;

    if (count < 0) {
      updateProductWithRack({ BarCode, to: "table", from: BasketID.toString(), Type: "BT", count: Math.abs(count) });
    } else {
      updateProductWithRack({ BarCode, to: BasketID.toString(), from: "table", Type: "TB", count: Math.abs(count) });
    }
    refetch();
  };

  //수량 변경
  const handleEditChange = (InventoryID: number, Quantity: number) => {
    setTempData(
      tempData.map((v) => {
        if (v.InventoryID === InventoryID) {
          return {
            ...v,
            Quantity,
          };
        }
        return v;
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">상품 재고정보</DialogTitle>
      <DialogContent sx={{ mr: 5 }}>
        <Typography sx={{ mb: 1 }} color="primary">
          바코드 : {BarCode}
        </Typography>
        <Stack gap={3}>
          <CardContainer>
            <RowStack>
              <Typography fontWeight="bold" fontSize="18px">
                랙
              </Typography>
              <IconButton onClick={handleChangeRackModalOpen}>
                <AddIcon color="primary" />
              </IconButton>
            </RowStack>

            <Divider sx={{ my: 1 }} />
            {!isError &&
              data &&
              tempData &&
              tempData.length !== 0 &&
              tempData.map((item, i: number) => {
                if (!item?.RackID) return null;
                if (!data[i]?.Quantity) return null;
                return (
                  <RowStack key={i} sx={{ mb: 1 }}>
                    <Typography>{item.RackID}</Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <Typography sx={{ mr: 1 }}>{item.Quantity} 개</Typography>

                      <IconButton onClick={() => handleQuantityChange(i, +1)}>
                        <AddCircleOutlineIcon color="primary" />
                      </IconButton>
                      <IconButton onClick={() => handleQuantityChange(i, -1)}>
                        <RemoveCircleOutlineIcon color="primary" />
                      </IconButton>
                      <NumberEditModal id={item.InventoryID} onChange={handleEditChange} quantity={item.Quantity} />
                      <Button
                        variant="contained"
                        disabled={item.Quantity === data[i].Quantity}
                        onClick={() => handleRackSubmit(item.Quantity, data[i].Quantity, item.RackID)}
                      >
                        ({item.Quantity - data[i].Quantity})변경
                      </Button>
                    </Stack>
                  </RowStack>
                );
              })}
            {data?.filter((v) => v.RackID).length === 0 && (
              <Typography color="#999" textAlign={"center"} sx={{ my: 2 }}>
                없음
              </Typography>
            )}
          </CardContainer>
          <CardContainer>
            <Typography fontWeight="bold" fontSize="18px">
              바구니
            </Typography>

            <Divider sx={{ my: 1 }} />
            {!isError &&
              data &&
              tempData &&
              tempData.length !== 0 &&
              tempData.map((item, i) => {
                if (!item.BasketID) return null;

                return (
                  <RowStack key={i} sx={{ mb: 1 }}>
                    <Typography>{item.BasketID}</Typography>
                    {/* <Typography sx={{ mr: 1 }}>{item.Quantity.toLocaleString()} 개</Typography> */}
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography sx={{ mr: 1 }}>{item.Quantity} 개</Typography>
                      <IconButton onClick={() => handleQuantityChange(i, +1)}>
                        <AddCircleOutlineIcon color="primary" />
                      </IconButton>
                      <IconButton onClick={() => handleQuantityChange(i, -1)}>
                        <RemoveCircleOutlineIcon color="primary" />
                      </IconButton>
                      <NumberEditModal id={item.InventoryID} onChange={handleEditChange} quantity={item.Quantity} />
                      <Button
                        variant="contained"
                        disabled={item.Quantity === data[i].Quantity}
                        onClick={() => handleBasketSubmit(item.Quantity, data[i].Quantity, item.BasketID)}
                      >
                        ({item.Quantity - data[i].Quantity})변경
                      </Button>
                    </Stack>
                  </RowStack>
                );
              })}
            {data?.filter((v) => v.BasketID).length === 0 && (
              <Typography color="#999" textAlign={"center"} sx={{ my: 2 }}>
                없음
              </Typography>
            )}
          </CardContainer>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
      <AddRackInProduct open={addRackModalOpen} handleClose={handleChangeRackModalOpen} BarCode={BarCode} />
      <AddBasketInProduct open={addBasketModalOpen} handleClose={handleChangeBasektModalOpen} BarCode={BarCode} />
    </Dialog>
  );
};

interface NumberEditModalProps {
  id: number;
  quantity: number;
  onChange: (InventoryID: number, value: number) => void;
}

const NumberEditModal: React.FC<NumberEditModalProps> = ({ onChange, id, quantity }) => {
  const [value, setValue] = useState<number>(quantity);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setValue(quantity);
  }, [open]);

  const handleOpenChange = () => {
    setOpen(!open);
  };

  const handleChange = (e: any) => {
    setValue(e.target.value || 0);
  };

  const onSubmit = () => {
    if (isNaN(value)) {
      customToast("info", "숫자만 입력해주세요.");
      return;
    }
    onChange(id, value);
    handleOpenChange();
  };

  return (
    <>
      <IconButton onClick={handleOpenChange}>
        <BorderColorIcon color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleOpenChange}>
        <DialogContent sx={{ mt: 2 }}>
          <TextField label="수정할 재고 수량" variant="outlined" value={value} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} variant="contained">
            변경
          </Button>
          <Button onClick={handleOpenChange} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
