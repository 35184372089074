import IndividualOrderTable from "../entities/individual-order/ui/IndividualOrderTable";

const IndividualOrder = () => {
  return (
    <>
      <IndividualOrderTable />
    </>
  );
};

export default IndividualOrder;
