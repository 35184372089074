import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type brandCodeType = {
  hiddenColumns: string[];
};

type BrandCodeActions = {
  setHiddenColumns: (data: string[]) => void;
};

export const useHiddenColumnsStore = create(
  persist<brandCodeType & BrandCodeActions>(
    (set) => ({
      hiddenColumns: [],
      setHiddenColumns: (data: string[]) => {
        set({
          hiddenColumns: data,
        });
      },
    }),
    {
      name: "SALES_SUMMARY_TABLE_HIDDEN_COLUMNS",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
