import * as React from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridToolbar,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridRowSelectionModel,
} from "@mui/x-data-grid";

import BarcodeButton from "../../../widget/show-bar-code-modal/ui/BarCodeButton";
import QrCodeButton from "../../../widget/show-qr-code-modal/ui/QrCodeButton";
import { ProgressBar } from "./progress-bar/ProgressBar";
import { useDeleteRack, useGetRackList, useUpdateRack } from "../../../service/rack/rackHooks";
import { deleteRackRequestDto } from "../../../service/rack/types";
import AddRackButton from "./create-rack-modal/CreateRackButton";
import CustomPagination from "../../../widget/custom-pagination/ui/CustomPagination";
import { dataGridStyle, otherToolbar, initialState } from "../../../shared/style/mui-table-style/MuiTableStyle";
import { getTogglableColumns } from "../../../shared/lib/columnMenuPanel";
import { useUserInfoState } from "../../../store/UserInfoStore";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import TitleAndMenu from "../../../widget/title-and-menu/TitleAndMenu";
import ProductListInRackButton from "../../../widget/show-productlist-modal/ProductListInRackButton";
import { useColumnStore } from "../../../store/dataGridColumnStore";

export default function RackTable() {
  const { getColumns, setColumns } = useColumnStore((v) => v);
  const { data: rows, refetch, isFetching } = useGetRackList();
  const { mutate: deleteRack } = useDeleteRack();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({}); // 현재 변경중인 값
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const { IsStaff } = useUserInfoState((s) => s);
  const { mutate: updateRack } = useUpdateRack();
  //편집 중지
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  //삭제 클릭
  const handleDeleteClick = (data: deleteRackRequestDto) => () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteRack(data);
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    const { RackID, CoupangRackID, Brand } = newRow;
    console.table(newRow);
    updateRack({ RackID, CoupangRackID, IsCoupang: !CoupangRackID ? false : true });
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "RackID",
      headerName: "코드",
      type: "string",
      width: 550,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const RackID = params.row.RackID;
        return <span>{RackID}</span>;
      },
    },

    {
      field: "Volume",
      headerName: "사용량",
      type: "string",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <ProgressBar value={params.row.Volume} />;
      },
    },
    {
      field: "LastUpdated",
      headerName: "수정시간",
      width: 300,
      align: "center",
      headerAlign: "center",
      type: "dateTime",
    },

    {
      field: "barCode",
      headerName: "바코드",
      type: "actions",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <BarcodeButton key={params.row.RackID} value={params.row.RackID} />;
      },
    },
    {
      field: "qrCode",
      headerName: "qr코드",
      type: "actions",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <QrCodeButton key={params.row.RackID} value={params.row.RackID} />;
      },
    },
    {
      field: "products",
      headerName: "물품목록 ",
      type: "actions",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const code = params.row.RackID;
        const brand = params.row.Brand?.split(", ")[0] || "-";
        return <ProductListInRackButton code={code} brand={brand} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      align: "center",
      width: 150,
      headerAlign: "center",
      headerName: "삭제",
      cellClassName: "actions",
      getActions: (params) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const RackID = params.row.RackID;
        return [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick({ RackID })} color="inherit" disabled={!IsStaff} />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomTableContainer>
        <TitleAndMenu
          type="R"
          maxDownloadCount={40}
          refetch={refetch}
          AddButton={<AddRackButton />}
          pdfDownLoadButton
          objectCodeList={rowSelectionModel}
          title="랙 목록"
        />
        <DataGrid
          // checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          sx={dataGridStyle}
          initialState={initialState}
          rows={rows ? rows : []}
          loading={isFetching}
          columns={columns.map((item) => {
            const columnWidth = getColumns()[item.field];
            return { ...item, cellClassName: "cell-divider", width: columnWidth ? columnWidth : item.width };
          })}
          rowModesModel={rowModesModel}
          disableColumnFilter
          disableDensitySelector
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onColumnWidthChange={(e) => {
            const { field, width } = e.colDef;
            setColumns(field, width || 100);
          }}
          slots={{
            toolbar: GridToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            columnsManagement: {
              getTogglableColumns,
            },
            toolbar: {
              setRowModesModel,
              ...otherToolbar,
            },
          }}
        />
      </CustomTableContainer>
    </>
  );
}
