import { customAxios } from "../../config/axios-config";
import {
  getProductRequestDto,
  getProductResponseDto,
  getProductListRequestDto,
  createProductDto,
  updateProductDto,
  ProductInRackItemDto,
  moveRackToRackDto,
  newProductRequestDto,
  newProductResponseDto,
} from "./types";

export const productApi = {
  getProduct: async (data: getProductRequestDto): Promise<getProductResponseDto> => {
    return await customAxios.get(`/products/${data.BarCode}/`).then((res) => res.data);
  },
  //새로운 api
  getNewProductList: async (params?: newProductRequestDto): Promise<newProductResponseDto> => {
    return await customAxios.get(`/products/`, { params }).then((res) => res.data);
  },
  getProductList: async (params?: getProductListRequestDto): Promise<getProductResponseDto[]> => {
    return await customAxios.get(`/products/`, { params }).then((res) => res.data);
  },
  createProduct: async (data: createProductDto): Promise<createProductDto> => {
    return await customAxios.post(`/products/`, data).then(() => data);
  },
  createAllProduct: async (data: createProductDto[]): Promise<createProductDto[]> => {
    return await customAxios.post(`/products/`, data).then(() => data);
  },
  updateProduct: async (data: updateProductDto): Promise<any> => {
    const { BarCode, ...body } = { ...data };
    return await customAxios.patch(`/products/${BarCode}/`, body).then(() => data);
  },
  udpateProductIntoRack: async (body: ProductInRackItemDto): Promise<ProductInRackItemDto> => {
    return await customAxios.post(`/inventories/move/`, body).then(() => body);
  },
  moveRackToRack: async (body: moveRackToRackDto): Promise<moveRackToRackDto> => {
    return await customAxios.post(`/inventories/move_rr/`, body).then(() => body);
  },
  updateProductBulk: async (body: updateProductDto[]) => {
    return await customAxios.post("/products/bulk_update/", body).then((res) => res.data);
  },

  deleteProduct: async (barcode: string) => {
    return await customAxios.delete(`/products/${barcode}/`).then(() => barcode);
  },
};
