// page/totalorderList

import TotalOrderListTable from "../entities/total-order/ui/TotalOrderTable";

export const TotalOrderList = () => {
  return (
    <>
      <TotalOrderListTable />
    </>
  );
};

export default TotalOrderList;
