import styled from "@emotion/styled";

export const CenterCellRander = styled.span`
  text-align: center;
  display: block;
`;


export const CardContainer = styled.div`
  white-space: break-spaces;
  min-height:120px;
  width: 320px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
`;

export const CountTextStyle = {
  width:"100%",
  fontSize:"50px",
  height:"60px",
  fontWeight:"bold",
  color:"#0068D0"
}