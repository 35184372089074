import { Button } from "@mui/material";
import { useModals } from "../../../service/modal/useModals";
import { ShowIncommingInventoryModal } from "./ShowIncommingInventoryModal";
import React from "react";
import { CustomButton } from "../../../shared/ui/buttom/CustomButton";

interface ShowIncommingInventoryButtonProps {
  BarCode: string;
  IncommingCount: number;
}

const ShowIncommingInventoryButton: React.FC<ShowIncommingInventoryButtonProps> = ({ BarCode, IncommingCount }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowIncommingInventoryModal);
  };

  const handleClickOpen = () => {
    openModal(ShowIncommingInventoryModal, { BarCode, handleClose });
  };

  return (
    <React.Fragment>
      <CustomButton onClick={handleClickOpen}>{IncommingCount.toLocaleString()}</CustomButton>
    </React.Fragment>
  );
};

export default ShowIncommingInventoryButton;
