import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import SelectForm from "../../../../widget/form/ui/SelectForm";
import { changeCenterDto, getOrderListResponseDto, getOrderResponseDto } from "../../../../service/order/types";
import { useChangeCenter, useGetOrderList } from "../../../../service/order/orderHooks";
import { customToast } from "../../../../shared/lib/customToast";
import { useBrandCodeStore } from "../../../../store/brandStore";
import RowStack from "../../../../shared/ui/RowStack";
interface ChangeCenterButtonProps {
  data?: getOrderResponseDto;
}

const ChangeCenterButton: React.FC<ChangeCenterButtonProps> = ({ data }) => {
  const { BrandCode } = useBrandCodeStore((s) => s);
  const [open, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const [selectedIndividualNumber, setSelectedIndividualNumber] = React.useState<string[]>([]);
  const { mutateAsync: changeCenter } = useChangeCenter();
  const { data: res } = useGetOrderList({ brand: BrandCode, status: "R" });
  const [orderAlias, setOrderAlias] = useState<string>("");
  const [newOrderAlias, setNewOrderAlias] = useState<string>("");
  const [newCenter, setNewCenter] = useState<string>("");
  const [receiveData, setReceiveData] = useState<string>(data?.ReceiveDate || "");

  const handleChangeReceiveData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (data?.OrderDate) {
      if (data.OrderDate > value) {
        customToast("warn", "발주일 보다 입고일이 빠를순 없습니다.");
      } else {
        setReceiveData(value);
      }
    } else {
      setReceiveData(value);
    }
  };

  const orderAliasOptions = useMemo(() => {
    return res
      ? data?.IsFlex
        ? res //  플렉스 이름만 가져오기
            .filter((v: getOrderListResponseDto) => !v.OrderAlias.includes("사전확정") && v.IsFlex) // 사전확정이 아닌데 isFlex가 true인것
            .filter((v) => v.OrderAlias !== data?.OrderAlias) // 이동할 발주서 이름 제거
            .map((v) => ({ label: v.OrderAlias, value: v.OrderAlias }))
        : res // 일반발주서 이름 가져오기
            .filter((v: getOrderListResponseDto) => !v.IsFlex) // 플렉스가 아닌 모든것
            .filter((v) => v.OrderAlias !== data?.OrderAlias) // 이동할 발주서 이름 제거
            .map((v) => ({ label: v.OrderAlias, value: v.OrderAlias }))
      : [];
  }, [res, data?.OrderAlias, data?.IsFlex]);

  const handleChangeOrderAlias = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setOrderAlias(value);
  };

  //입력창 초기화
  useEffect(() => {
    setSelectedIndividualNumber([]);
    setOrderAlias("");
    setNewOrderAlias("");
    setNewCenter("");
  }, [open]);

  //새로운 발주서 사용
  const handleSubmit = async () => {
    const dtoList: changeCenterDto[] = [];

    for (let i = 0; i < selectedIndividualNumber.length; i++) {
      let body = {} as changeCenterDto;
      if (i === 0 && checked) {
        body = {
          isNewOrder: 1,
          IndividualOrderNumber: selectedIndividualNumber[i],
          OrderDate: data?.OrderDate,
          OrderAlias: checked ? newOrderAlias : orderAlias,
          ReceiveDate: receiveData,
          Center: data?.IsFlex ? "VF150" : newCenter,
          Brand: BrandCode,
        };
      } else {
        body = {
          isNewOrder: 0,
          IndividualOrderNumber: selectedIndividualNumber[i] || "",
          toOrderAlias: checked ? newOrderAlias : orderAlias,
        };
      }

      dtoList.push(body);
    }

    if (dtoList.length > 0) {
      for (let dto of dtoList) {
        await changeCenter(dto);
      }
      handleChangeOpen();
    } else {
      customToast("warn", "모든정보를 입력해주세요2");
    }
  };

  //체크
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  //발주번호 리스트
  const individualNumberList: string[] = useMemo(() => {
    const individualSet = new Set<string>(); // 모든 발주번호
    const imposibleSet = new Set<string>(); //불가능한 발주번호

    data?.items.forEach((item) => {
      if (item.Quantity_Ready !== 0) {
        imposibleSet.add(item.IndividualOrderNumber);
      }
      individualSet.add(item.IndividualOrderNumber);
    });

    //플렉스 발주서 일때는 모두 이동가능하게 변경
    if (data?.IsFlex) return Array.from(individualSet);

    //일반 발주서일 경우에는 준비수량이 0인 발주서만 센터이동 가능
    return individualSet.size === 0 ? [] : Array.from(individualSet)?.filter((v) => !imposibleSet.has(v)); // imposibleSet에 없는 항목만 필터링
  }, [data]);

  //모달창 열기
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const handleSelected = (number: string) => {
    if (selectedIndividualNumber.includes(number)) {
      setSelectedIndividualNumber(selectedIndividualNumber.filter((item) => item !== number));
    } else {
      setSelectedIndividualNumber([...selectedIndividualNumber, number]);
    }
  };

  const handleSelectAll = () => {
    if (selectedIndividualNumber.length === individualNumberList.length) {
      setSelectedIndividualNumber([]);
    } else {
      setSelectedIndividualNumber(individualNumberList);
    }
  };

  return (
    <React.Fragment>
      <Button variant="outlined" sx={{ backgroundColor: "white" }} onClick={handleChangeOpen} startIcon={<ChangeCircleOutlinedIcon />}>
        센터 변경
      </Button>
      <Dialog
        open={open}
        onClose={handleChangeOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "100%",
              minWidth: "300px",
              maxWidth: "600px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle>
          <RowStack>
            센터 변경
            <Button onClick={handleSelectAll}>발주번호 전체선택</Button>
          </RowStack>
        </DialogTitle>

        <DialogContent>
          <FormGroup sx={{ border: "1px solid #dfdfdf", p: 3, mb: 4, maxHeight: "250px", overflow: "auto" }}>
            {!!individualNumberList &&
              individualNumberList.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={<Checkbox checked={selectedIndividualNumber.includes(item)} onClick={() => handleSelected(item)} />}
                  label={item}
                />
              ))}
          </FormGroup>
          {data && <FormControlLabel control={<Switch checked={checked} onChange={handleChangeSwitch} />} label="새로운 발주서 사용" />}

          <Box sx={{ my: 5 }} />
          {!checked ? (
            <>
              <SelectForm label="기존 발주서 선택" handleChange={handleChangeOrderAlias} value={orderAlias} options={orderAliasOptions} />
            </>
          ) : (
            <>
              <DialogContentText id="alert-dialog-description" sx={{ mb: 1 }}>
                <Typography variant="subtitle2">정보를 모두 입력해주세요.</Typography>
              </DialogContentText>
              <Stack gap={2}>
                <TextField
                  fullWidth
                  label="센터 이름"
                  id="orderCenter"
                  disabled={data?.IsFlex}
                  value={data?.IsFlex ? "VF150" : newCenter}
                  onChange={(e) => setNewCenter(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="발주일"
                  value={data?.OrderDate}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField fullWidth label="입고예정일" value={receiveData} type="date" onChange={handleChangeReceiveData} />
                <TextField
                  fullWidth
                  label="발주서 별명"
                  id="OrderAlias"
                  placeholder="ex) 비0712고1"
                  value={newOrderAlias}
                  onChange={(e) => setNewOrderAlias(e.target.value)}
                />
              </Stack>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={selectedIndividualNumber.length === 0 || !(checked ? newOrderAlias : orderAlias)}
          >
            등록
          </Button>
          <Button variant="outlined" onClick={handleChangeOpen}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ChangeCenterButton;
