import { customToast } from "../../../shared/lib/customToast";

export function copyToClicpboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        customToast("success", "클립보드에 복사되었습니다!");
        // 여기서 원하는 로직 (예: 알림 표시)을 추가할 수 있습니다.
      })
      .catch((err) => {
        console.error("클립보드 복사에 실패했습니다: ", err);
      });
  }