
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateRange } from "react-date-range";
import { Button, Menu } from "@mui/material";
import { ko } from "date-fns/locale";
import { useState } from "react";
import { useCustomerBrandStore } from "../../../store/customerBrandStore";

const DateRangeButton: React.FC = () => {
  const { term, setTerm, startDate, endDate } = useCustomerBrandStore((v) => v);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleCloseTermForm = () => {
    setAnchorEl(null);
  };
  const handleOpenTermForm = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDateSelect = (e: any) => {
    setTerm(e.selection);
  };

  const dateFormated = (date: string) => {
    return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
  };

  return (
    <>
      <Button onClick={handleOpenTermForm} variant="outlined" endIcon={<CalendarMonthIcon />} size={"large"}>
        기간 : {dateFormated(startDate)} ~ {dateFormated(endDate)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseTermForm}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <DateRange dateDisplayFormat={"yyyy / MM / dd"} editableDateInputs={false} locale={ko} ranges={[term]} onChange={handleDateSelect} />
      </Menu>
    </>
  );
};


export default DateRangeButton;