import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { SIDENAV_WIDTH } from "../shared/const/constant";
import SideNav from "../entities/side-navigation/ui/SideNav";

const Layout = () => {
  return (
    <Stack direction={"row"} sx={{ width: `100%`, height: "100vh", minHeight: "800px", boxSizing: "border-box" }}>
      <SideNav />
      <Stack sx={{ width: `calc(100% - ${SIDENAV_WIDTH})`, height:"100%", overflowY:"auto" }}>
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default Layout;
