
/**
 * 객체가 하나라도 빈값인지 확인하는 함수 
 * @param data 
 * @returns 
 */
export function isEmpty(data: object) {
  return Object.values(data).some((x) => !x);
}

