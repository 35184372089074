import { Stack, Box } from "@mui/material";
import { boxStyle } from "../../style/boxStyle";
import RowStack from "../../../../shared/ui/RowStack";

export interface ShowPreviewBasketCodeProps {
  basketCode: string;
}

export const ShowPreviewBasketCode: React.FC<ShowPreviewBasketCodeProps> = ({ basketCode }) => {
  return (
    <RowStack sx={{ mt: 2 }} gap={1}>
      <Box sx={boxStyle}>{6}</Box>
      <Box sx={boxStyle}>{2}</Box>
      <Box sx={boxStyle}>{basketCode[0]}</Box>
      &nbsp;
      <Box sx={boxStyle}>{basketCode[1]}</Box>
      <Box sx={boxStyle}>{basketCode[2]}</Box>
      <Box sx={boxStyle}>{basketCode[3]}</Box>
    </RowStack>
  );
};
