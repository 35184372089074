import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createProductDto,
  getProductListRequestDto,
  getProductRequestDto,
  getProductResponseDto,
  moveRackToRackDto,
  newProductRequestDto,
  newProductResponseDto,
  ProductInRackItemDto,
  updateProductDto,
} from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { productApi } from "./productAxios";
import { addIdColumn } from "../../shared/lib/addIdColumn";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { Id } from "../../shared/type/Id";
import { customToast } from "../../shared/lib/customToast";

export const useGetProduct = (data: getProductRequestDto) => {
  return useQuery<getProductResponseDto, Error>({
    queryKey: [QUERY_KEY.product, data],
    queryFn: () => productApi.getProduct(data),
  });
};

export const useGetProductList = (data: getProductListRequestDto) => {
  return useQuery<getProductResponseDto[], Error, (getProductResponseDto & Id)[]>({
    queryKey: [QUERY_KEY.productList, data.Status, data.Brand],
    queryFn: () => productApi.getProductList(data),
    select: (data) => {
      return addIdColumn(data);
    },
  });
};

export const useNewGetProductList = (data: newProductRequestDto) => {
  return useQuery<newProductResponseDto, Error>({
    queryKey: ["newProductList", ...Object.values(data)],
    queryFn: () => productApi.getNewProductList(data),
    select: (data) => {
      const newData = {
        ...data,
        results: addIdColumn(data.results),
      };
      return newData;
    },
  });
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<createProductDto, Error, createProductDto>({
    mutationFn: (data: createProductDto) => productApi.createProduct(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.productList] });
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "상품 생성에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useCreateAllProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<createProductDto[], Error, createProductDto[]>({
    mutationFn: (data: createProductDto[]) => productApi.createAllProduct(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.productList] });
      customToast("info", `${data.length}개의 상품이 새롭게 등록되었습니다.`);
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "상품 생성에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useUpdateProduct = (success?: (data: { [key: string]: any }) => void) => {
  return useMutation<updateProductDto, Error, updateProductDto>({
    mutationFn: (data: updateProductDto) => productApi.updateProduct(data),
    onSuccess: (data) => {
      if (success) {
        success(data); // 다양한 형태로 업데이트
      }
    },
    onError: (err) => {
      // console.log(err);
      // displayErrorMessage({ error: err, defaultErrorMessage: "상품 업데이트에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useUpdateProductWithRack = (onsuccess: () => void) => {
  const quyeryClient = useQueryClient();
  return useMutation<ProductInRackItemDto, Error, ProductInRackItemDto>({
    mutationFn: (data: ProductInRackItemDto) => productApi.udpateProductIntoRack(data),
    onSuccess: (data) => {
      onsuccess();
      if (data.Type === "TR") {
        customToast("success", "상품이 랙에 추가되었습니다.");
      } else if (data.Type === "TB") {
        customToast("success", "상품이 바구니에 추가되었습니다.");
      } else {
        customToast("success", "상품이 제거되었습니다.");
      }

      quyeryClient.invalidateQueries({ queryKey: [QUERY_KEY.inventory, data.BarCode] });
    },
    onError: (err) => {
      customToast("error", "상품 이동에 실패했습니다.");
    },
  });
};

export const useRackToRack = () => {
  return useMutation<moveRackToRackDto, Error, moveRackToRackDto>({
    mutationFn: (data: moveRackToRackDto) => productApi.moveRackToRack(data),
    onSuccess: (data) => {
      customToast("success", "상품이 이동되었습니다.");
    },
    onError: (err) => {
      customToast("error", "상품 이동에 실패했습니다.");
    },
  });
};

export const useUpdateProductBulk = (onsuccess?: (data: any) => void) => {
  const queryClient = useQueryClient();
  return useMutation<updateProductDto[], Error, any>({
    mutationFn: (data: updateProductDto[]) => productApi.updateProductBulk(data),
    onSuccess: (data) => {
      if (onsuccess) {
        onsuccess(data);
      }
      customToast("success", `[${data.length}]개의 상품이 업데이트 되었습니다.`);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.productList] });
    },
    onError: (err) => {
      console.log(err);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.productList] });
      customToast("error", "상품 업데이트에 실패했습니다. 잠시후 다시 시도해주세요.");
    },
  });
};

export const useDeleteProduct = () => {
  return useMutation<string, Error, string>({
    mutationFn: (data: string) => productApi.deleteProduct(data),
    onSuccess: (data) => {},
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "상품 삭제에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};
