import { Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions, Stack, IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ProductListInBasketTable from "./ProductListInBasketTable";
import { useGetBasket } from "../../service/basket/BasketHooks";
import { useUpdateProductWithRack } from "../../service/product/productHooks";
import { ProductInRackItemDto } from "../../service/product/types";
import BackDropLoading from "../../shared/ui/loading/BackDropLoading";
import RowStack from "../../shared/ui/RowStack";
interface ProductListInBasketModalProps {
  open: boolean;
  code: string;
  handleClose: () => void;
}
const ProductListInBasketModal: React.FC<ProductListInBasketModalProps> = ({ open, code, handleClose }) => {
  const { data, refetch, isFetching } = useGetBasket({ BasketID: code });
  const { mutateAsync: moveInventory } = useUpdateProductWithRack(() => {});

  const handleClearBasket = async () => {
    if (!!data && data.inventory && data.inventory.length > 0 && confirm("정말 바구니를 비우시겠습니까?")) {
      const dtoList: ProductInRackItemDto[] = data.inventory.map((v: any) => {
        return { BarCode: v.BarCode, count: v.Quantity, Type: "BT", from: code, to: "table" };
      });

      for (const dto of dtoList) {
        await moveInventory(dto);
      }

      refetch();
    }
    // 바구니 비우기
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
              height: "570px", // Set your width here
            },
          },
        }}
      >
        <BackDropLoading open={isFetching} />
        <DialogTitle id="alert-dialog-title">
          <RowStack>
            <Stack sx={{ p: 2, marginRight: "auto" }}>
              바구니 번호 [{code}]
              <Typography variant="body2" color="grey">
                현재 적재된 물품 목록입니다.
              </Typography>
            </Stack>
            <Button onClick={handleClearBasket}>바구니 비우기</Button>
            <Tooltip arrow placement="top" title="새로고침">
              <IconButton>
                <RefreshIcon color="primary" onClick={() => refetch()} />
              </IconButton>
            </Tooltip>
          </RowStack>
        </DialogTitle>

        <DialogContent>
          {data?.inventory.length === 0 ? (
            <Typography textAlign={"center"} variant="subtitle1">
              상품이 없습니다.{" "}
            </Typography>
          ) : (
            <ProductListInBasketTable data={data?.inventory || []} />
          )}
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductListInBasketModal;
