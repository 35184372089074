import { Button } from "@mui/material";
import React from "react";
import { useModals } from "../../service/modal/useModals";
import ProductListInBasketModal from "./ProductListInBasketModal";
interface ProductListButtonProps {
  code: string;
  brand?: string;
}

const ProductListInBasketButton: React.FC<ProductListButtonProps> = ({ code, brand = "" }) => {
  const { openModal, closeModal } = useModals();
  const handleClose = () => {
    closeModal(ProductListInBasketModal); //basekt
  };
  const handleClickOpen = () => {
    openModal(ProductListInBasketModal, { code, handleClose }); // basekt
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        보기
      </Button>
    </React.Fragment>
  );
};

export default ProductListInBasketButton;
