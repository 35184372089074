import styled from "@emotion/styled";

const NumberCell = styled.span`
  white-space: normal;
  word-break: keep-all;
  width: 100%;
  display: inline-block;
  text-align: right;
  height: 100%;
`;

export default NumberCell;