import * as XLSX from "xlsx";

/**
 *  상품 데이터를 엑셀 형식으로 변환
 * @param data
 * @param fileName
 */
export const productDatatoExcelFormmat = (data: any[], fileName: string) => {
  const formattedData = data.map((item: any, index: number) => ({
    No: index,
    바코드: item.BarCode,
    상품명: item.ProductName || "",
    자동상품메모: item.Memo0 || "",
    메모: item.Memo00 || "",
    시즌: item.Season || "",
    색상: item.Color || "",
    사이즈: item.Size || "",
    // 브랜드: brandMap[item.Brand] || "",
    재고: item.TotalCount || 0,
    SKU_ID: item.SKU_ID || "",
    거래처: item.SupplierCompany || "",
    주문링크: item.OrderLink || "",
    상태: item.Status || "",
    견적가: Math.round(parseInt(item.QuotedPrice) || 0),
    판매가: Math.round(parseInt(item.SalePrice) || 0),
    중국원가: Math.round(parseInt(item.ChinaCost) || 0),
    현지배송비: Math.round(parseInt(item.LocalDeliveryFee) || 0),
    한국원가: Math.round(parseInt(item.KoreaCost) || 0),
    총원가: Math.round(parseInt(item.TotalCost) || 0),
    납품가: Math.round(parseInt(item.DeliveryPrice) || 0),
    부가세: Math.round(parseInt(item.VAT) || 0),
    로켓마진: Math.round(parseInt(item.RocketMargin) || 0),
    회계원가: Math.round(parseInt(item.AccountingCost) || 0),
    자사마진: Math.round(parseInt(item.OurMargin) || 0),
  }));

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "상품목록");

  const safeFileName = `${fileName}.xlsx`;
  XLSX.writeFile(workbook, safeFileName);
};
