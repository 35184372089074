import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  FormControlLabel,
  Checkbox,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import { createUserDto } from "../../../../service/user/types";
import { isEmpty } from "../../../../shared/lib/isEmpty";
import { customToast } from "../../../../shared/lib/customToast";

interface AddUserModalProps {
  open: boolean;
  handleSubmit: (data: createUserDto) => void;
  handleClose: () => void;
}

// 관리자 101, 나머지 102
export const CreateUserModal: React.FC<AddUserModalProps> = ({ open, handleSubmit, handleClose }) => {
  const [isStaff, setIsStaff] = React.useState(false);
  const [user, setUser] = React.useState<createUserDto>({
    UserName: "",
    password: "",
    RoleID: "102",
    ExpireTime: new Date(),
  });

  React.useEffect(() => {
    if (isStaff) {
      setUser({
        ...user,
        ExpireTime: new Date("9999-12-31T23:59"),
      });
    } else {
      handleToday();
    }
  }, [isStaff]);

  //오늘 마감시간까지
  const handleToday = () => {
    const today = new Date();
    today.setHours(27, 0, 0, 0); // 시간을 18시 0분 0초로 설정
    setUser({
      ...user,
      ExpireTime: today,
    });
  };

  const handleChangeMemeber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, type } = e.target;

    if (id === "isStaff") {
      setIsStaff(checked);
      setUser((prev) => ({ ...prev, RoleID: checked ? "101" : "102", ExpireTime: checked ? new Date("9999-12-31T23:59") : new Date() }));
    } else if (id === "ExpireTime") {
      const expireDate = new Date(value);
      // 9시간을 더해줍니다.
      expireDate.setHours(expireDate.getHours() + 9);
      setUser((prevUser) => ({
        ...prevUser,
        [id]: expireDate,
      }));
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [id]: value,
      }));
    }
  };

  return (
    <>
      {" "}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">사용자 추가</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ width: "400px" }}>
            <Typography variant="subtitle2">사용자 정보를 모두 입력해주세요.</Typography>
          </DialogContentText>
          <Stack gap={2} sx={{ mt: 4 }}>
            {/* 만료일 */}

            <Stack direction="row" gap={1}>
              <TextField
                fullWidth
                label="만료일"
                disabled={isStaff}
                onChange={handleChangeMemeber}
                value={user.ExpireTime.toISOString().slice(0, 16)}
                id="ExpireTime"
                type="dateTime-local"
              />
              <Button variant="contained" sx={{ width: "120px" }} onClick={handleToday}>
                금일 6시
              </Button>
            </Stack>
            {/* 이름 */}

            <TextField fullWidth label="이름" onChange={handleChangeMemeber} value={user.UserName} id="UserName" placeholder="이름을 입력해주세요." />
            {/* 아이디 */}

            <TextField
              value={user.password}
              onChange={handleChangeMemeber}
              id="password"
              fullWidth
              label="비밀번호"
              placeholder="8자리 번호를 입력해주세요. ex)12345678"
            />
            {/* 관리자 여부 */}
            <FormControlLabel control={<Checkbox id="isStaff" onChange={handleChangeMemeber} value={isStaff} />} label={"관리자 여부"} />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button
            onClick={() => {
              if (!isEmpty(user)) {
                handleSubmit(user);
              } else {
                customToast("warn", "모든 정보를 입력해주세요.");
              }
            }}
            autoFocus
            variant="contained"
          >
            등록
          </Button>
          <Button onClick={handleClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
