import { useQuery } from "@tanstack/react-query";
import {
  getBrandCustomerOrderSummaryRequestDto,
  getBrandCustomerOrderSummaryResponseDto,
  getBrandSalesSummaryRequestDto,
  getBrandSalesSummaryResponseDto,
  getTotalCustomerOrderSummaryRequestDto,
  getTotalCustomerOrderSummaryResponseDto,
  getTotalSalesSummaryRequestDto,
  getTotalSalesSummaryResponseDto,
} from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { salesSummaryApi } from "./salesSummaryAxios";

export const useGetTotalSalesSummary = (data: getTotalSalesSummaryRequestDto) => {
  return useQuery<getTotalSalesSummaryResponseDto, Error>({
    queryKey: [QUERY_KEY.totalSalesSummary, data.startDate, data.endDate],
    queryFn: () => salesSummaryApi.getTotalSalesSummary(data),
  });
};

export const useGetBrandSalesSummary = (data: getBrandSalesSummaryRequestDto) => {
  return useQuery<getBrandSalesSummaryResponseDto, Error>({
    queryKey: [QUERY_KEY.brandSalesSummary, data.startDate, data.endDate, data.brand],
    queryFn: () => salesSummaryApi.getBrandSalesSummary(data),
  });
};

export const useGetTotalCustomerOrderSummary = (data: getTotalCustomerOrderSummaryRequestDto) => {
  return useQuery<getTotalCustomerOrderSummaryResponseDto, Error>({
    queryKey: [QUERY_KEY.totalCustomerOrderSummary, data.startDate, data.endDate],
    queryFn: () => salesSummaryApi.getTotalCustomerOrderSalesSummary(data),
  });
};

export const useGetBrandCustomerOrderSummary = (data: getBrandCustomerOrderSummaryRequestDto) => {
  return useQuery<getBrandCustomerOrderSummaryResponseDto, Error>({
    queryKey: [QUERY_KEY.totalCustomerOrderSummary, data.startDate, data.endDate, data.brand],
    queryFn: () => salesSummaryApi.getBrandCustomerOrderSalesSummary(data),
  });
};
