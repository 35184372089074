import { Backdrop, CircularProgress } from "@mui/material";

interface Props {
  open: boolean;
  // onClose: () => void;
}

const BackDropLoading = ({ open }: Props) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 1000 }}
      open={open}
      // onClick={onClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackDropLoading;
