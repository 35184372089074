import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, SelectChangeEvent, Tooltip } from "@mui/material";
import { Dialog } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import SelectForm from "../../../../widget/form/ui/SelectForm";
import { getOrderListResponseDto, ItemResponse, updateOrderItemDto } from "../../../../service/order/types";
import { useGetOrderList, useUpdateOrderItemBulk } from "../../../../service/order/orderHooks";
import { customToast } from "../../../../shared/lib/customToast";
import { useBrandCodeStore } from "../../../../store/brandStore";
import { OrderInfoType, OrderItemType } from "../IndividualOrderTable";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { orderApi } from "../../../../service/order/orderAxios";
import BackDropLoading from "../../../../shared/ui/loading/BackDropLoading";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
interface ChangeCenterButtonProps {
  orderItems: OrderItemType[];
  orderInfo: OrderInfoType;
}

const MoveMemoButton: React.FC<ChangeCenterButtonProps> = ({ orderItems, orderInfo }) => {
  const { mutateAsync: updateOrderItemBulk, isPending } = useUpdateOrderItemBulk();
  const { BrandCode } = useBrandCodeStore((s) => s);
  const [open, setOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>("");
  const { data: res } = useGetOrderList({ brand: BrandCode, status: "R" });
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const handleMove = () => {
    navigate(`/individualorder/${orderId}`);
    handleChangeOpen();
  };

  const orderAliasOptions = useMemo(() => {
    return (
      res //  플렉스 이름만 가져오기
        ?.filter((v: getOrderListResponseDto) => !v.OrderAlias.includes("사전확정") && v.OrderAlias !== orderInfo?.OrderAlias) // 사전확정이 아닌데 isFlex가 true인것
        .map((v) => ({ label: v.OrderAlias, value: v.OrderID.toString() })) || []
    );
  }, [res, orderItems, orderInfo]);

  const targetOrderAlias = useMemo(() => {
    return res?.find((v) => v.OrderID.toString() === orderId)?.OrderAlias || "";
  }, [orderId, orderAliasOptions]);

  const handleChangeOrderAlias = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setOrderId(value);
  };

  //입력창 초기화
  useEffect(() => {
    setVisible(false);
    setOrderId("");
  }, [open]);

  //센터 변경 버튼

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const handleSubmit = async () => {
    const current: { [key: string]: ItemResponse[] } = {};
    const target: { [key: string]: ItemResponse[] } = {};
    if (!!orderId) {
      //현재 발주서  map으로 변경
      orderItems.forEach((item) => {
        if (item.BarCode)
          if (!!current[item.BarCode]) {
            current[item.BarCode].push(item);
          } else {
            current[item.BarCode] = [item];
          }
      });

      orderApi.getOrder({ OrderID: parseInt(orderId) }).then((res) => {
        //이동할 발주서 map으로 변경
        res.items.forEach((item) => {
          if (!!target[item.BarCode]) {
            target[item.BarCode].push(item);
          } else {
            target[item.BarCode] = [item];
          }
        });

        //현재 발주서와 이동할 발주서의 공통된 제품을 찾아서 메모 이동

        const currentUpdateListDto: updateOrderItemDto[] = [];
        const targetUpdateListDto: updateOrderItemDto[] = [];

        Object.keys(current).forEach((key) => {
          //key가 있으면 메모이동로직 동작

          if (target[key]) {
            //current 에 있는 메모 합하기
            const memo = current[key].reduce((acc, cur) => {
              if (cur?.Memo2) {
                return acc + "\n" + (cur?.Memo2 || "");
              }
              return acc;
            }, "");

            //기존메모 이동완료 표시
            current[key].forEach((item) => {
              currentUpdateListDto.push({ OrderItemID: item.OrderItemID, Memo2: `-메모이동완료 => [${res.OrderAlias}]-` });
            });

            //target메모 업데이트
            if (memo !== "") {
              target[key].forEach((item) => {
                targetUpdateListDto.push({ OrderItemID: item.OrderItemID, Memo2: (item.Memo2 || "") + "\n\n----추가된 메모----" + memo });
              });
            }
          }
        });

        if (currentUpdateListDto.length > 0 && targetUpdateListDto.length > 0) {
          updateOrderItemBulk(targetUpdateListDto);
          updateOrderItemBulk(currentUpdateListDto);
          setVisible(true);
        }
      });
    } else {
      customToast("info", "이동할 발주서를 선택해주세요.");
    }
  };

  return (
    <React.Fragment>
      <Button variant="outlined" sx={{ backgroundColor: "white" }} onClick={handleChangeOpen} startIcon={<ChangeCircleOutlinedIcon />}>
        메모이동
      </Button>
      <BackDropLoading open={isPending} />
      <Dialog
        open={open}
        onClose={handleChangeOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
          메모이동
          <Tooltip
            title={
              <p>
                메모이동은 청도주문메모만 이동됩니다.
                <br />
                이동할 발주서를 선택한뒤 등록을 누르면
                <br />
                메모가 이동합니다. <br />
                <br /> 이동한 주문메모는 -[발주서이름]메모이동완료-
                <br /> 라고 표시되며 이동할 발주서에 제품이 존재하지
                <br /> 않을경우 메모는 이동되지 않습니다.{" "}
              </p>
            }
            placement="right"
            arrow
          >
            <HelpOutlineIcon color="primary" />
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>이동할 발주서를 선택해주세요.</DialogContentText>

          <SelectForm label="발주서 선택" handleChange={handleChangeOrderAlias} value={orderId} options={orderAliasOptions} />

          {orderId !== "" && visible && (
            <Button endIcon={<OpenInNewIcon />} onClick={handleMove}>
              [{targetOrderAlias}]으(로) 바로 이동하기
            </Button>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleSubmit} disabled={!orderId || visible}>
            {visible ? "이동완료" : "등록"}
          </Button>
          <Button variant="outlined" onClick={handleChangeOpen}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MoveMemoButton;

// ----추가된 메모----
// 주완4(11/1)
// 발송완료
// 11/06 도착
