import RackTable from "../entities/rack/ui/RackTable";

const Rack: React.FC = () => {
  return (
    <>
      <RackTable />
    </>
  );
};

export default Rack;
