import { useModals } from "../../../../service/modal/useModals";
import React from "react";
import { CustomButton } from "../../../../shared/ui/buttom/CustomButton";
import { ItemResponse } from "../../../../service/order/types";
import { ShowQuantityCoupangModal } from "./ShowQuantityCoupangModal";

interface Props {
  row: ItemResponse;
}

const ShowQuantityCoupangButton: React.FC<Props> = ({ row }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowQuantityCoupangModal);
  };

  const handleClickOpen = () => {
    openModal(ShowQuantityCoupangModal, { OrderItemID: row.OrderItemID, handleClose });
  };

  return (
    <React.Fragment>
      <CustomButton onClick={handleClickOpen}>{row.Quantity_coupang.toLocaleString()}</CustomButton>
    </React.Fragment>
  );
};

export default ShowQuantityCoupangButton;
