import { copyToClicpboard } from "../../../rocket/lib/copyToClipBoard";
import { Dialog, DialogTitle, DialogContent, Typography, List, ListItem, ListItemIcon, Divider, DialogActions, Button } from "@mui/material";
interface ErrorListProps {
  data: any;
  onClose: () => void;
}

const ErrorList: React.FC<ErrorListProps> = ({ data, onClose }) => {
  const errorList = data?.errors?.map((v: any) => v?.item?.BarCode || v) || [];

  const handleCopyAndClose = () => {
    copyToClicpboard(errorList.join("\n"));
    onClose();
  };

  return (
    <Dialog open={errorList.length !== 0}>
      <DialogTitle>
        업로드 실패 리스트
        <Typography variant="body2" color="#666">
          실패 : {data?.errors?.length || 0}, 성공 : {data?.success?.length || 0}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ width: "300px" }}>
        <List sx={{ p: 4, backgroundColor: "#f9f9f9", maxHeight: "500px", overflow: "auto" }}>
          {errorList.map((v: any, i: number) => (
            <ListItem key={i} sx={{ borderBottom: errorList.length - 1 !== i ? "1px solid #dfdfdf" : "" }}>
              <ListItemIcon sx={{ mr: 4 }}>{i + 1}</ListItemIcon>
              {v}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCopyAndClose}>
          오류목록 복사 후 닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorList;