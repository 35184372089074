/**
 *
 * @param children
 * @param type
 * @returns
 */
export const filterChildren = (children: any, type: string) => {
  let disableColumnList: string[] = [];

  if (type === "flex") {
    disableColumnList = ["보존"];
  }

  if (type === "pre") {
    disableColumnList = ["플완", "사전확정"];
  }

  if (type === "normal") {
    disableColumnList = ["플완", "사전확정", "IBC하차번호", "보존"];
  }
  return children
    .filter((child: any) => !disableColumnList.includes(child.name))
    .map((child: any) => {
      if (child.children) {
        return {
          ...child,
          children: filterChildren(child.children, type),
        };
      }
      return child;
    });
};
