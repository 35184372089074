import { CopyEvent, PasteEvent } from "react-data-grid";
import { customToast } from "./customToast";
import { copyClipboard } from "./copyClipboard";



/**
 * react-data-grid에서 사용하는 copy 이벤트 핸들러
 * @param param0 
 * @returns 
 */
export function handlePaste({
    sourceColumnKey, //복사한 키 
    sourceRow,
    targetColumnKey, //붙여넣을 키
    targetRow
  }: PasteEvent<any>): any {
    const pastableList = ["Memo","Detail","IBC"]; // 호환되지 않는 컬럼

    const isPosible = pastableList.filter( v => targetColumnKey.includes(v)).length > 0

    if(isPosible){
      return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }else{
      customToast("warn","해당 셀은 편집할 수 없습니다.")
      return targetRow
    }

  }


  export function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    copyClipboard(sourceRow[sourceColumnKey])
  }