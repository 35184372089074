import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//송장 출력
export const invoiceExcelDownloader = (data: any, filename: string) => {
  // 엑셀 워크북 생성
  const wb = XLSX.utils.book_new();

  // 엑셀 워크시트 생성
  // const ws = XLSX.utils.json_to_sheet(data);
  const ws = XLSX.utils.aoa_to_sheet(data);

  // 워크시트를 워크북에 추가
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // 워크북을 바이너리 스트링으로 변환
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  // 바이너리 스트링을 Blob으로 변환
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  // Blob을 파일로 저장
  saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), filename);
};
