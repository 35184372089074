import { customAxios } from "../../config/axios-config";
import {
  getTotalSalesSummaryRequestDto,
  getTotalSalesSummaryResponseDto,   
  getBrandSalesSummaryRequestDto,
  getBrandSalesSummaryResponseDto,
  getTotalCustomerOrderSummaryRequestDto,
  getTotalCustomerOrderSummaryResponseDto,
  getBrandCustomerOrderSummaryRequestDto,
  getBrandCustomerOrderSummaryResponseDto,
} from "./types";

export const salesSummaryApi = {
  getTotalSalesSummary: async (params: getTotalSalesSummaryRequestDto): Promise<getTotalSalesSummaryResponseDto> => {
    return await customAxios.get(`/orders/get_summary/`, { params }).then((res) => res.data);
  },
  getBrandSalesSummary: async (params: getBrandSalesSummaryRequestDto): Promise<getBrandSalesSummaryResponseDto> => {
    return await customAxios.get(`/orders/get_brand_summary/`, { params }).then((res) => res.data);
  },

  getTotalCustomerOrderSalesSummary: async (params: getTotalCustomerOrderSummaryRequestDto): Promise<getTotalCustomerOrderSummaryResponseDto> => {
    return await customAxios.get(`/orders/get_summary_customer_order/`, { params }).then((res) => res.data);
  },
  getBrandCustomerOrderSalesSummary: async (params: getBrandCustomerOrderSummaryRequestDto): Promise<getBrandCustomerOrderSummaryResponseDto> => {
    return await customAxios.get(`/orders/get_brand_summary_customer_order/`, { params }).then((res) => res.data);
  },
};
