import { Card, Stack, Typography } from "@mui/material";
import CountUp from "../../../shared/ui/count-up/CountUp";

interface Props {
  title: string;
  value: number;
  unit?: string;
}
const ValueCard: React.FC<Props> = ({ title, value, unit = "" }) => {
  return (
    <Card sx={{ py: 5 }}>
      <Typography variant="subtitle2" color="#666">
        {title}
      </Typography>
      <Stack direction="row" gap={2} alignItems={"end"}>
        <CountUp duration={500} end={value} variant="h5" /> {unit}
      </Stack>
    </Card>
  );
};

export default ValueCard;
