import { Dialog, DialogTitle, DialogContent, Stack, Typography, DialogActions, Button, Divider } from "@mui/material";

import styled from "@emotion/styled";
import { useGetIncommingProductByBarCode } from "../../../service/total-order/totalOrderListHooks";
import { useEffect } from "react";
import RowStack from "../../../shared/ui/RowStack";

interface ShowIncommingInventoryModalProps {
  open: boolean;
  handleClose: () => void;
  BarCode: string;
}
export const ShowIncommingInventoryModal: React.FC<ShowIncommingInventoryModalProps> = ({ open, handleClose, BarCode }) => {
  const { data,refetch } = useGetIncommingProductByBarCode({ BarCode });

  useEffect(()=>{
    refetch()
  },[open])

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">입고정보</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <CardContainer>
            <Typography fontWeight="bold" fontSize="18px">
              {BarCode}
            </Typography>
            {data?.length === 0 && <Divider sx={{ my: 1 }} />}
            {data && data.length !== 0 ? (
              data?.map((item, i) => (
                <Stack sx={{ mb: 1 }} key={i}>
                  <Divider sx={{ my: 1 }} />
                  <RowStack>
                    <Typography sx={{ color: "#666" }}>입고서 :</Typography>
                    <Typography>{item.Alias}</Typography>
                  </RowStack>
                  <RowStack>
                    <Typography sx={{ color: "#666" }}>묶음 번호 :</Typography>
                    <Typography>{item.BoxNumber}</Typography>
                  </RowStack>
                  <RowStack>
                    <Typography sx={{ color: "#666" }}>수량 :</Typography>
                    <Typography>{item.Quantity.toLocaleString()} 개</Typography>
                  </RowStack>
                  <RowStack>
                    <Typography sx={{ color: "#666" }}>쿠팡 날짜 :</Typography>
                    <Typography>{item.CoupangDate || "-"}</Typography>
                  </RowStack>
                </Stack>
              ))
            ) : (
              <Typography textAlign={"center"} sx={{ color: "#999" }}>
                없음
              </Typography>
            )}
            <Divider sx={{ my: 1 }} />
            <RowStack>
              <Typography sx={{ color: "#666" }}>총개수 :</Typography>
              <Typography>{!!data ? data?.reduce((r, a) => r + a.Quantity, 0) : 0} 개</Typography>
            </RowStack>
          </CardContainer>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CardContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 300px;
`;
