import BasketTable from "../entities/basket/ui/BasketTable";

const Basket: React.FC = () => {
  return (
    <>
      <BasketTable />
    </>
  );
};

export default Basket;
