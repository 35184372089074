import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { BasketInProductItemDto } from "../../service/basket/types";
import ShowInventoryButton from "../show-inventory-modal/ui/ShowInventoryButton";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "center";
}

const columns: readonly Column[] = [
  { id: "BarCode", label: "상품코드", align: "center" },
  { id: "ProductName", label: "상품명", align: "center" },
  { id: "Color", label: "색상", align: "center" },
  { id: "Size", label: "사이즤", align: "center" },
  { id: "Quantity", label: "재고", align: "center" },
];

interface ProductListInBasketTableProps {
  data: BasketInProductItemDto[];
}

const ProductListInBasketTable: React.FC<ProductListInBasketTableProps> = ({ data }) => {
  return (
    <>
      <TableContainer sx={{ height: 400, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell sx={{ fontWeight: "bold" }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a, b) => a.BarCode.localeCompare(b.BarCode))
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.BarCode}>
                    <TableCell align="center">{row.BarCode}</TableCell>
                    <TableCell align="left">{row.ProductName}</TableCell>
                    <TableCell align="center">{row.Color}</TableCell>
                    <TableCell align="center">{row.Size}</TableCell>
                    <TableCell align="center">
                      <ShowInventoryButton BarCode={row.BarCode} TotalCount={row.Quantity} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductListInBasketTable;
