import React, { useState, useEffect, useRef, ReactNode } from "react";
import "../style/style.css";

interface AnimatedComponentProps {
  children: ReactNode; // children 타입 지정
  delay?: number;
}

const AnimationWrapper: React.FC<AnimatedComponentProps> = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // 컴포넌트가 10% 이상 보이면 애니메이션 트리거
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className={isVisible ? `fadeInMoveUp fadeInMoveUp--delay-${delay}` : ""} style={{ opacity: 0 }}>
      {children}
    </div>
  );
};

export default AnimationWrapper;
