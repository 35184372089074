import { Stack, Typography, Card, Skeleton } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";

import { getColor } from "../lib/getColor";
import { brandIconOptions } from "../../side-navigation/const/brandIconsOption";
import { useMemo } from "react";
import CountUp from "../../../shared/ui/count-up/CountUp";

type PieData = {
  brand: string; 
  value: number;
};

interface Props {
  title: string;
  data: PieData[];
  unit: string;
  colorType:number
}

const SalesPieChart: React.FC<Props> = ({ title, data, unit,colorType }) => {
  //퍼센트를 구하기 위한 값
  const totalCount = useMemo(() => {
    return data.reduce((acc, v) => {
      return v.value + acc;
    }, 0);
  }, [data]);

  const brandCodeToBrandName = useMemo(() => {
    const result: { [key: string]: string } = {};
    brandIconOptions.forEach((v) => {
      result[v.brandCode] = v.brandName;
    });
    return result;
  }, [brandIconOptions]);

  return (
    <Card>
      {!data ? (
        <Skeleton variant="rectangular" width={210} height={60} />
      ) : (
        <Stack>
          <Typography variant={"h6"}>{title}</Typography>
          <Typography variant={"body1"}>
            <Stack direction="row" gap={2} alignItems={"end"} sx={{mt:1}}>
              총 합계 : <CountUp duration={500} end={totalCount} variant="body1" />
              
               {unit}
            </Stack>
          </Typography>
          <PieChart
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "14px",
              },
              "& .MuiChartsLegend-series text": { fontSize: "1em !important" },
            }}
            series={[
              {
                innerRadius: 70,
                arcLabel: (item) => `${Math.round((item.value / totalCount) * 100)}%`,
                arcLabelMinAngle: 45,
                data: data
                  .sort((a, b) => b.value - a.value)
                  .map((v, i) => ({
                    id: i,
                    value: v.value,
                    label: brandCodeToBrandName[v.brand],
                    color: getColor(colorType,i),
                  })),
              },
            ]}
            height={320}
            width={400}
            margin={{ left: 110, right: 40, bottom: 50, top: 10 }}
            slotProps={{
              legend: {
                // markGap: 2,
                itemGap: 5,
                itemMarkHeight: 12,
                itemMarkWidth: 8,
                direction: "column",
                position: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              },
            }}
          />
        </Stack>
      )}
    </Card>
  );
};

export default SalesPieChart;
