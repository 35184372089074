import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, Stack, TextField, Button, DialogActions, Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { createBasketDto } from "../../../../service/basket/types";
import { customToast } from "../../../../shared/lib/customToast";
import { ShowPreviewBasketCode } from "./ShowPreviewBasketCode";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (data: createBasketDto) => void;
}

const CreateBasketModal: React.FC<Props> = ({ open, handleClose, handleSubmit }) => {
  const [basketCode, setBasketData] = useState<string>("");
  const [CoupangBaskID, setCoupangBaskID] = useState<string>("");

  const handleChangeBasketCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    setBasketData(value.slice(0, 4));
  };

  const createObjectCode = () => {
    return "62" + basketCode;
  };

  const handleCreateBasket = () => {
    const BasketID = createObjectCode();
    if (BasketID.length === 6) {
      if (window.confirm(`[${BasketID}] 바구니를 추가하시겠습니까?`)) {
        handleSubmit({ BasketID, CoupangBaskID, IsCoupang: CoupangBaskID ? true : false });
      }
    } else {
      customToast("warn", "입력값을 확인해주세요.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">바구니 추가</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ width: "400px", mb: 4 }}>
          <Typography variant="subtitle2">바구니 번호 4자리를 입력해주세요.</Typography>
          <ShowPreviewBasketCode basketCode={basketCode} />
        </DialogContentText>
        <Stack gap={2}>
          <Stack gap={1}>
            <TextField label="바구니 번호" id="basketCode" placeholder="ex) 1234, 5678 등" onChange={handleChangeBasketCode} value={basketCode} />
            <TextField
              label="쿠팡 바구니 번호"
              id="CoupangeBaskID"
              placeholder=""
              onChange={(e) => setCoupangBaskID(e.target.value)}
              value={CoupangBaskID}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleCreateBasket} autoFocus variant="contained">
          등록
        </Button>
        <Button onClick={handleClose} variant="outlined">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateBasketModal;
