export const statusOptions = [
  {
    label: "전체",
    value: "전체",
  },
  {
    label: "준비",
    value: "R",
  },
  {
    label: "완료",
    value: "E",
  },
];
