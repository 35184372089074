import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const MAX_HISTORY_LENGTH = 300;

export type HistoryType = {
  Id: number;
  OrderID: number | null;
  OrderAlias: string;
  BarCode: string;
  CreatedAt: string;
  CreatedBy: string;
  Count: number;
  BasketID: string;
  ProductName: string;
  Size: string;
  Color: string;
  Type: string;
};

type HistoryListType = {
  idIndex: number;
  open: boolean;
  historyList: HistoryType[];
};

type HistoryActions = {
  setHistory: (data: Omit<HistoryType, "Id">) => void;
  clearHistory: () => void;
  deleteHistory: (Id: number) => void;
  setOpen: (open: boolean) => void;
};

export const useHistoryStore = create(
  persist<HistoryListType & HistoryActions>(
    (set, get) => ({
      idIndex: 0,
      open: false,
      historyList: [],
      setHistory: (data: Omit<HistoryType, "Id">) => {
        set((state) => {
          // 새로운 값을 추가하고, 길이가 MAX_HISTORY_LENGTH를 초과하면 맨 뒤의 값을 제거
          const Id = state.idIndex + 1;
          const newHistoryList = [{ Id, ...data }, ...state.historyList];
          if (newHistoryList.length > MAX_HISTORY_LENGTH) {
            newHistoryList.pop(); // 맨 뒤의 값을 제거
          }
          return {
            idIndex: Id,
            historyList: newHistoryList,
          };
        });
      },
      clearHistory: () => {
        set({
          idIndex: 0,
          historyList: [],
        });
      },
      deleteHistory: (Id: number) => {
        set((state) => ({
          historyList: state.historyList.filter((v) => v.Id !== Id),
        }));
      },
      setOpen: (open: boolean) => {
        set({
          open: open,
        });
      },
    }),
    {
      name: "ATTERLIC_HISTORY",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
