import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addIdColumn } from "../../shared/lib/addIdColumn";
import { QUERY_KEY } from "../../config/querykey";
import { rackApi } from "./rackAxios";
import {
  createRackRequestDto,
  createRackResponseDto,
  deleteRackRequestDto,
  getRackListResponseDto,
  getRackRequestDto,
  getRackResponseDto,
  updateRackDto,
} from "./types";
import { Id } from "../../shared/type/Id";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";

export const useGetRack = (data: getRackRequestDto) => {
  return useQuery<getRackResponseDto, Error>({
    queryKey: [QUERY_KEY.rack, data.RackID],
    queryFn: () => rackApi.getRack(data),
  });
};

export const useGetRackList = () => {
  return useQuery<getRackListResponseDto[], Error, (getRackListResponseDto & Id)[]>({
    queryKey: [QUERY_KEY.rackList],
    queryFn: () => rackApi.getRackList(),
    select: (data) => {
      return addIdColumn(data);
    },
  });
};

export const useCreateRack = () => {
  const queryClient = useQueryClient();
  return useMutation<createRackResponseDto, Error, createRackRequestDto>({
    mutationFn: (data: createRackRequestDto) => rackApi.createRack(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.rackList] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "랙 생성에 실패했습니다. 잠시후 다시 시도해주세요.", error: err });
    },
  });
};

export const useUpdateRack = () => {
  const queryClient = useQueryClient();
  return useMutation<updateRackDto, Error, updateRackDto>({
    mutationFn: (data: updateRackDto) => rackApi.updateRack(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.rackList], (oldData: (getRackListResponseDto & Id)[] | undefined) => {
        if (oldData) {
          return oldData.map((rack) => {
            if (rack.RackID === data.RackID) {
              return {
                ...rack,
                Brand: data.Brand,
                IsCoupang: data.IsCoupang,
                CoupangRackID: data.CoupangRackID,
              };
            }
            return rack;
          });
        }
        return [];
      });

      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.rackList] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "랙 업데이트에 실패했습니다. 잠시후 다시 시도해주세요.", error: err });
    },
  });
};

export const useDeleteRack = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, deleteRackRequestDto>({
    mutationFn: (data: deleteRackRequestDto) => rackApi.deleteRack(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.rackList], (oldData: (getRackListResponseDto & Id)[] | undefined) => {
        return oldData ? oldData.filter((rack) => rack.RackID !== data.RackID) : [];
      });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "랙 삭제에 실패했습니다. 잠시후 다시 시도해주세요.", error: err });
    },
  });
};

//export const useUpdateRack = ()
