import { create } from "zustand";

type State = {
  filterToggle: boolean;
};

type Actions = {
  setFilterToggle: (data: boolean) => void;
};

export const useFilterToggleStore = create<State & Actions>((set) => ({
  filterToggle: false,
  setFilterToggle: (data: boolean) => {
    set((state) => ({
      ...state,
      filterToggle: data,
    }));
  },
}));
