//table/totalorderListtable
import { Button, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import _ from "lodash";
import { useState, useMemo, createContext, useContext, useEffect, useCallback, useRef } from "react";
import DataGrid, { RenderHeaderCellProps, textEditor } from "react-data-grid";
import { useGetTotalOrderList, useUpdateTotalOrderListMemo } from "../../../service/total-order/totalOrderListHooks";
import RefreshButton from "../../../shared/ui/buttom/RefreshButton";
import "../style/style.css";
import { CenterCellRander } from "../style/style";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getTotalOrderListResponseDto } from "../../../service/total-order/types";
import ShowInventoryButton from "../../../widget/show-inventory-modal/ui/ShowInventoryButton";
import { useNavigate } from "react-router-dom";
import ShowIncommingInventoryButton from "../../../widget/show-incomming-inventory-modal/ui/ShowIncommingInventoryButton";
import ShowBoxNameListButton from "../../../widget/show-basket-list-modal/ui/ShowBoxNameListButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { autoRowHeight } from "../../../shared/lib/autoRowHeight";
import { MultiRowCell } from "../../../shared/lib/multiRowCell";
import AutoMultiCell from "../../../shared/ui/grid-cell/AutoMultiCell";
import { useUpdateProduct } from "../../../service/product/productHooks";
import { CustomButton } from "../../../shared/ui/buttom/CustomButton";
import { useLocalStorage } from "../../../service/localStorage/useLocalStorage";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import { SortType } from "../../../shared/type/SortType";
import { handleCopy, handlePaste } from "../../../shared/lib/dataGridTableCopyPaste";
import { useFilterToggleStore } from "../../../store/totalOrderTableFilterStore";
import HistoryList from "./history-list/HistoryList";
import { tableSortFunc } from "../../../shared/lib/tableSortFunction";
import { initialFilter } from "../const/initialFilter";
import RowStack from "../../../shared/ui/RowStack";
import BarCodeScanFiled from "./BarCodeScanField";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";

interface Filter extends getTotalOrderListResponseDto {}

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation();
  }
}
const FilterContext = createContext<Filter | undefined>(undefined);
const SortContext = createContext<SortType | undefined>(undefined);
const TOTAL_ORDER_TABLE_COLUMNS_VALUES = [90, 40, 50, 50, 70, 220, 40, 55, 40, 30, 30, 30, 30, 30, 150, 150, 150, 150, 150];

const TotalOrderTable = () => {
  const { filterToggle, setFilterToggle } = useFilterToggleStore((v) => v);
  const [widthList, setWidthList] = useLocalStorage("ATTERIC_TOTAL_ORDER_TABLE", TOTAL_ORDER_TABLE_COLUMNS_VALUES);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { data, refetch, isFetching, isLoading } = useGetTotalOrderList(isEdit);
  const { mutate: updateMemo } = useUpdateTotalOrderListMemo();
  const { mutate: updateProduct } = useUpdateProduct();
  const [sortType, setSortType] = useState<SortType>({ columnKey: "ReceiveDate", direction: "ASC" });
  const [filters, setFilters] = useState(initialFilter);
  const [orderItems, setOrderItems] = useState<getTotalOrderListResponseDto[]>(data || []);
  // const historyList = useRef<{ newData: any; oldData: any }[]>([]);

  // const handleUndo = () => {
  //   const lastData = historyList.current.pop();

  //   if (lastData) {
  //     console.log("ctrl+z를 사용한 undo 실행");
  //     handleUpdateCustomerInfo(lastData.newData, lastData.oldData);
  //   }
  // };

  // useEffect(() => {
  //   const handleKeyDown = (event: any) => {
  //     if (event.ctrlKey && event.key === "z") {
  //       console.log("ctrl + z");
  //       event.preventDefault();
  //       handleUndo();
  //     }
  //   };
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [historyList]);

  useEffect(() => {
    if (data) {
      //예전 데이터 기준으로 reducer 돌기
      //n번
      const keyValueMap = new Map<number, getTotalOrderListResponseDto>();
      const usedKeySet = new Set<number>();
      data.forEach((item: getTotalOrderListResponseDto) => {
        keyValueMap.set(item.OrderItemID, item); // 조회를 빠르게 하기 위해 map에 넣어둠
      });

      // n번
      //값 업데이트 + 삭제하기
      const newOrderItems: any[] = orderItems.reduce((acc: any[], item) => {
        const id = item.OrderItemID;
        if (keyValueMap.has(id)) {
          usedKeySet.add(id);
          acc.push(keyValueMap.get(id));
        }
        return acc;
      }, []);

      // n번 새로운 값 추가하기 (추가된 값이 있을 경우)
      data.forEach((item) => {
        if (!usedKeySet.has(item.OrderItemID)) {
          newOrderItems.push(item);
        }
      });

      setOrderItems(newOrderItems); //이게 안되는이유가 뭘까...
    }
  }, [data]);

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateCustomerInfo = async (newData: any, oldData: any) => {
    // if (!oldData?.isCtrlZ) {
    //   const preOldData = { ...oldData, isCtrlZ: true };
    //   historyList.current.push({ newData, oldData: preOldData });
    //   console.log(historyList.current);
    //   console.log("일반 수정 실행");
    // } else {
    //   console.log("ctrl+z를 사용한 undo 실행");
    //   console.log(historyList.current);
    // }
    /**
     * newData : 새롭게 변경된 row[]
     * oldData : 변경되기 전 row
     */

    console.log(oldData);

    const idx = oldData.indexes[0];
    const key = oldData.column.key;
    const value = newData[idx][key];
    const BarCode = newData[idx].BarCode; //필수키
    const OrderItemID = newData[idx].OrderItemID; //필수키

    if (key === "Quantity_OK") {
      //Quantity_Error 수정
      updateMemo({ OrderItemID, [key]: value });
      setOrderItems(
        newData.map((v: getTotalOrderListResponseDto) =>
          v.OrderItemID === OrderItemID ? { ...v, Quantity_Error: v.Quantity_OK - v.Quantity_Ready } : v
        )
      );
    } else if (key === "Memo0" || key === "MemoCustomerOrder") {
      //product
      updateProduct({ BarCode, [key]: value });
      setOrderItems(newData.map((v: getTotalOrderListResponseDto) => (v.BarCode === BarCode ? { ...v, [key]: value } : v)));
    } else {
      // 나머지 수정
      updateMemo({ OrderItemID, [key]: value });
      setOrderItems(newData);
    }

    setIsEdit(false);
  };

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    setIsEdit(false);
    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType((prev) => ({ ...prev, direction: prev.direction === "ASC" ? "DESC" : "ASC" }));
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //필터를 적용함
  const filteredRows = useMemo(() => {
    if (!orderItems || orderItems.length === 0) return [];
    return orderItems.filter((v) => {
      return (
        (filters.MemoCustomerOrder === "" ? true : v.MemoCustomerOrder?.includes(filters.MemoCustomerOrder)) &&
        (filters.BarCode === "" ? true : v.BarCode?.includes(filters.BarCode)) &&
        (filters.Brand === "" ? true : v.Brand?.includes(filters.Brand)) &&
        (filters.Center === "" ? true : v.Center?.includes(filters.Center)) &&
        (filters.Memo0 === "" ? true : v.Memo0?.includes(filters.Memo0)) &&
        (filters.Color === "" ? true : v.Color?.includes(filters.Color)) &&
        (filters.Size === "" ? true : v.Size?.includes(filters.Size)) &&
        (filters.Memo1 === "" ? true : v.Memo1?.includes(filters.Memo1)) &&
        (filters.Memo2 === "" ? true : v.Memo2?.includes(filters.Memo2)) &&
        (filters.Memo3 === "" ? true : v.Memo3?.includes(filters.Memo3)) &&
        (filters.OrderLink === "" ? true : v.OrderLink?.includes(filters.OrderLink)) &&
        (filters.ProductName === "" ? true : v.ProductName?.includes(filters.ProductName)) &&
        (filters.OrderDate === "" ? true : v.OrderDate?.includes(filters.OrderDate)) &&
        (filters.SKU_ID === "" ? true : v.SKU_ID?.includes(filters.SKU_ID)) &&
        (filters.SupplierCompany === "" ? true : v.SupplierCompany?.includes(filters.SupplierCompany)) &&
        (filters.individualOrderNumber === "" ? true : v.individualOrderNumber?.includes(filters.individualOrderNumber)) &&
        (filters.Quantity === 0 ? true : v?.Quantity === filters.Quantity) &&
        (filters.Quantity_OK === 0 ? true : v?.Quantity_OK === filters.Quantity_OK) &&
        (filters.Quantity_Ready === 0 ? true : v?.Quantity_Ready === filters.Quantity_Ready) &&
        (filters.OrderItemID === 0 ? true : v?.OrderItemID === filters.OrderItemID) &&
        (filters.OrderID === 0 ? true : v?.OrderID === filters.OrderID)
      );
    });
  }, [orderItems, filters, sortType, data]);

  useEffect(() => {
    setOrderItems(orderItems.sort((a, b) => tableSortFunc(a, b, sortType)));
  }, [sortType]);

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [
      {
        totalCount: orderItems?.length || 0,
      },
    ];
  }, [orderItems]);

  function clearFilters() {
    setFilters(initialFilter);
  }

  //컬럼 테이블 생성
  const columns: any[] = [
    {
      key: "ReceiveDate",
      name: "입고일",
      width: 90,
      frozen: true,
      headerCellClass: (sortType.columnKey === "ReceiveDate" ? "" : "header") + " green-header",
      renderCell: ({ row }: any) => {
        return <CenterCellRander>{row.ReceiveDate}</CenterCellRander>;
      },
      renderSummaryCell: ({ row }: any) => {
        return "총 : " + row.totalCount + " 개";
      },
    },
    {
      key: "Brand",
      name: "브랜드",
      width: 40,
      headerCellClass: "filter-cell green-header",
      renderCell: ({ row }: any) => {
        return <CenterCellRander>{row.Brand}</CenterCellRander>;
      },
      renderHeaderCell: (p: any) => {
        return (
          <FilterRenderer<getTotalOrderListResponseDto> {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                className={"filterClassname"}
                value={filters.Brand}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    Brand: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        );
      },
    },
    {
      key: "individualOrderNumber",
      name: "발주번호",
      headerCellClass: "filter-cell green-header",
      renderCell: ({ row }: any) => {
        const { OrderID, BarCode } = row;
        const handleMoveToIndividualOrder = () => {
          navigate(`/individualorder/${OrderID}?keyword=${BarCode}`);
        };

        return (
          <AutoMultiCell>
            <CustomButton onClick={handleMoveToIndividualOrder} style={{ textDecoration: "underline" }}>
              {row.individualOrderNumber}
            </CustomButton>
          </AutoMultiCell>
        );
      },
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.individualOrderNumber}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  individualOrderNumber: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "Center",
      name: "물류센터",
      headerCellClass: "filter-cell green-header",

      renderCell: ({ row }: any) => {
        return <CenterCellRander>{row.Center}</CenterCellRander>;
      },
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.Center}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  Center: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "BarCode",
      name: "상품바코드",
      headerCellClass: "filter-cell green-header",
      renderCell: ({ row }: any) => {
        return <AutoMultiCell>{row.BarCode}</AutoMultiCell>;
      },
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.BarCode}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  BarCode: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "ProductName",
      name: "상품이름",
      headerCellClass: "filter-cell green-header",
      renderCell: ({ row }: any) => {
        return <AutoMultiCell>{row.ProductName}</AutoMultiCell>;
      },
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.ProductName}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  ProductName: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "Size",
      name: "사이즈",
      headerCellClass: "filter-cell green-header",
      renderCell: ({ row }: any) => {
        return <CenterCellRander>{row.Size}</CenterCellRander>;
      },
      renderHeaderCell: (p: any) => {
        return (
          <FilterRenderer<getTotalOrderListResponseDto> {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                className={"filterClassname"}
                value={filters.Size}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    Size: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        );
      },
    },
    {
      key: "Color",
      name: "색상",
      headerCellClass: "filter-cell green-header center-header",
      renderCell: ({ row }: any) => {
        return <AutoMultiCell>{row.Color}</AutoMultiCell>;
      },
      renderHeaderCell: (p: any) => {
        return (
          <FilterRenderer<getTotalOrderListResponseDto> {...p}>
            {({ filters, ...rest }) => (
              <input
                {...rest}
                className={"filterClassname"}
                value={filters.Color}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    Color: e.target.value,
                  })
                }
                onKeyDown={inputStopPropagation}
              />
            )}
          </FilterRenderer>
        );
      },
    },
    {
      key: "TotalCount",
      name: "재고",
      headerCellClass: (sortType.columnKey === "TotalCount" ? "" : "header") + " green-header",
      renderCell: ({ row }: any) => {
        const { TotalCount, BarCode } = row;
        return <ShowInventoryButton BarCode={BarCode} TotalCount={TotalCount} />;
      },
    },
    {
      key: "IncomingCount",
      name: "입고현황",
      renderHeaderCell: () => <AutoMultiCell>입고 현황</AutoMultiCell>,
      headerCellClass: (sortType.columnKey === "IncomingCount" ? "" : "header") + " green-header",
      renderCell: ({ row }: any) => {
        const { IncomingCount, BarCode } = row;
        return <ShowIncommingInventoryButton BarCode={BarCode} IncommingCount={IncomingCount} />;
      },
    },
    {
      key: "Quantity",
      name: "발주수량",
      cellClass: "orange-header",
      renderHeaderCell: () => <AutoMultiCell>발주 수량</AutoMultiCell>,
      headerCellClass: (sortType.columnKey === "Quantity_OK" ? "" : "header") + " orange-header",
      renderCell: ({ row }: any) => {
        return <CenterCellRander className="orange-header">{row?.Quantity.toLocaleString()}</CenterCellRander>;
      },
    },
    {
      key: "Quantity_OK",
      name: "확정수량",
      cellClass: "orange-header",
      renderHeaderCell: () => <AutoMultiCell>확정 수량</AutoMultiCell>,
      headerCellClass: (sortType.columnKey === "Quantity_OK" ? "" : "header") + " orange-header",
      renderCell: ({ row }: any) => {
        return <CenterCellRander className="orange-header">{row?.Quantity_OK.toLocaleString()}</CenterCellRander>;
      },
      renderEditCell: textEditor,
    },
    {
      key: "Quantity_Ready",
      name: "준비수량",
      renderHeaderCell: () => <AutoMultiCell>준비 수량</AutoMultiCell>,
      cellClass: "yellow-header",
      headerCellClass: (sortType.columnKey === "Quantity_Ready" ? "" : "header") + " yellow-header",
      renderCell: ({ row }: any) => {
        const { Quantity_Ready, Center, OrderItemID, BarCode } = row;
        return (
          <ShowBoxNameListButton
            row={row}
            OrderID={row.OrderID}
            Quantity_Ready={Quantity_Ready}
            Center={Center}
            OrderItemID={OrderItemID}
            BarCode={BarCode}
          />
        );
      },
    },
    {
      key: "Quantity_Error",
      name: "오류수량",
      cellClass: "blue-header11",
      renderHeaderCell: () => <AutoMultiCell>오류 수량</AutoMultiCell>,
      headerCellClass: (sortType.columnKey === "Quantity_Error" ? "" : "header") + " blue-header11",
      renderCell: ({ row }: any) => {
        return <CenterCellRander>{row?.Quantity_Error.toLocaleString()}</CenterCellRander>;
      },
    },
    {
      key: "Memo0",
      name: "자동상품메모",
      headerCellClass: "filter-cell",
      ...MultiRowCell(),
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.Memo0}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  Memo0: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "MemoCustomerOrder",
      name: "오더관리메모",
      width: 150,
      headerCellClass: "filter-cell",
      ...MultiRowCell(),
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.MemoCustomerOrder}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  MemoCustomerOrder: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "Memo1",
      name: "발주담당자 메모",
      headerCellClass: "filter-cell",
      ...MultiRowCell(),
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.Memo1}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  Memo1: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },

    {
      key: "Memo2",
      name: "청도주문 메모",
      headerCellClass: "filter-cell",
      ...MultiRowCell(),

      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.Memo2}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  Memo2: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "Memo3",
      name: "기타 메모",
      headerCellClass: "filter-cell",
      ...MultiRowCell(),
      renderHeaderCell: (p: any) => (
        <FilterRenderer<getTotalOrderListResponseDto> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.Memo3}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  Memo3: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
  ];

  const handleColumnResize = useCallback(
    _.throttle((index, width) => {
      setWidthList((prev: number[]) => {
        const newWidthList = [...prev];
        newWidthList[index] = width;
        return newWidthList;
      });
    }, 400),
    [setWidthList, widthList]
  );

  return (
    <>
      <BackDropLoading open={isLoading} />
      <CustomTableContainer>
        <Typography fontWeight="bold" fontSize="22px">
          통합발주현황
        </Typography>
        <Stack gap={2} sx={{ mt: 3 }}>
          <RowStack>
            <BarCodeScanFiled />
            <Stack direction="row" justifyContent={"end"} gap={2}>
              <FormControlLabel control={<Switch checked={filterToggle} onChange={() => setFilterToggle(!filterToggle)} />} label="필터링" />
              <HistoryList />
              <Button variant="outlined" onClick={clearFilters} startIcon={<FilterAltOffIcon />} sx={{ backgroundColor: "white" }}>
                필터 초기화
              </Button>
              <RefreshIconButton
                onClick={() => {
                  refetch();
                }}
              />
            </Stack>
          </RowStack>
          <FilterContext.Provider value={filters}>
            <SortContext.Provider value={sortType}>
              {widthList.length !== 0 && (
                <DataGrid
                  className="rdg-light"
                  onSortColumnsChange={handleChangeSortType}
                  headerRowHeight={filterToggle ? 95 : DEFAULT_HEADER_HEIGHT}
                  style={{ height: "85vh", paddingBottom: "20px" }}
                  rowHeight={(row) => autoRowHeight(row, 40)}
                  // onSelectedCellChange={handleSelectedRow}
                  onRowsChange={handleUpdateCustomerInfo}
                  columns={columns.map((column, i) => ({
                    ...column,
                    width: widthList[i],
                  }))}
                  sortColumns={sortType ? [sortType] : []}
                  rows={filteredRows}
                  topSummaryRows={summaryRows} // 하단 요약 행 추가d
                  defaultColumnOptions={{
                    sortable: true,
                    resizable: true,
                  }}
                  onColumnResize={handleColumnResize}
                  onCopy={handleCopy}
                  onPaste={handlePaste}
                  onCellClick={(e) => {
                    setIsEdit(true);
                  }}
                />
              )}
            </SortContext.Provider>
          </FilterContext.Provider>
        </Stack>
      </CustomTableContainer>
    </>
  );
};
export default TotalOrderTable;

const DEFAULT_HEADER_HEIGHT = 50;

function FilterRenderer<R>({
  tabIndex,
  column,
  children,
}: RenderHeaderCellProps<R> & {
  children: (args: { tabIndex: number; filters: Filter }) => React.ReactElement;
}) {
  const filterToggle = useFilterToggleStore((v) => v.filterToggle);
  const filters = useContext(FilterContext)!;
  const sortType = useContext(SortContext)!;

  return (
    <>
      <Stack direction="row" justifyContent={column.key === sortType.columnKey ? "space-between" : "center"} alignItems="center">
        <div style={{ padding: "0 0px", height: `${DEFAULT_HEADER_HEIGHT}px`, lineHeight: `${DEFAULT_HEADER_HEIGHT}px` }}>{column.name}</div>
        {column.key === sortType.columnKey && (sortType.direction === "ASC" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)}
      </Stack>
      {filterToggle && <div>{children({ tabIndex, filters })}</div>}
    </>
  );
}





