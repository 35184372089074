import html2canvas from "html2canvas";
import { useRef } from "react";

export const useHtmlToImage = () => {
  const htmlRef = useRef<HTMLDivElement>(null);
  //저장함수
  const onSaveAs = (uri: string, filename: string) => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };

  const onDownLoad = (title:string) => {
    if (htmlRef.current === null) return;
    html2canvas(htmlRef.current).then((canvas) => {
      onSaveAs(canvas.toDataURL("image/png"), `${title}.png`);
    });
  };

  return { htmlRef, onDownLoad };
};
