import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BarcodeAlertDto, createBarcodeAlertDto } from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { barcodeAlertAPi, getBarcodeAlertListDto } from "./barcodeAlertAxios";
import { customToast } from "../../shared/lib/customToast";

export const useGetBarcodeAlertList = (data: getBarcodeAlertListDto) => {
  return useQuery<BarcodeAlertDto[], Error>({
    queryKey: [QUERY_KEY.barcodeAlert, data.barcode],
    queryFn: () => barcodeAlertAPi.getBarcodeAlertList(data),
  });
};

export const useGetActiveBarcodeAlertList = (data: getBarcodeAlertListDto) => {
  return useQuery<BarcodeAlertDto[], Error>({
    queryKey: [QUERY_KEY.barcodeAlert,"active", data.barcode],
    queryFn: () => barcodeAlertAPi.getActiveBarCodeAlertList(data),
  });
};

export const useUpdateBarcodeAlert = (onSuccess: (data: BarcodeAlertDto[]) => void) => {
  const queryClient = useQueryClient();
  return useMutation<BarcodeAlertDto[], Error, BarcodeAlertDto[]>({
    mutationFn: (data: BarcodeAlertDto[]) => barcodeAlertAPi.updateBarcodeAlert(data),
    onSuccess,
    onError: (err) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.barcodeAlert] });
    },
  });
};

export const useCreateBarcodeAlert = () => {
  const queryClient = useQueryClient();
  return useMutation<createBarcodeAlertDto[], Error, createBarcodeAlertDto[]>({
    mutationFn: (data: createBarcodeAlertDto[]) => barcodeAlertAPi.createBarcodeAlert(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.barcodeAlert] });
    },
    onError: (err) => {
      customToast("error", "알림 수정에 실패했습니다.");
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEY.barcodeAlert] });
    },
  });
};

export const useDeleteBarcodeAlert = () => {
  const queryClient = useQueryClient();
  return useMutation<number[], Error, number[]>({
    mutationFn: (data: number[]) => barcodeAlertAPi.deleteBarcodeAlert(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.barcodeAlert] });
    },
    onError: (err) => {
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEY.barcodeAlert] });
    },
  });
};
