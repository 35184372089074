import { customAxios } from "../../config/axios-config";
import {
  createCustomerOrderRequestDto,
  deleteCustomerOrderDto,
  getCustomerOrderListRequestDto,
  getCustomerOrderListResponseDto,
  updateCustomerOrderRequestDto,
} from "./types";

export const customerFindOrderApi = {
  //고객 주문서 업로드
  createCustomerOrder: async (body: createCustomerOrderRequestDto[]): Promise<any> => {
    return await customAxios.post(`/customer_order/`, body).then((res) => res.data);
  },

  updateCustomerOrder: async (body: updateCustomerOrderRequestDto[]): Promise<updateCustomerOrderRequestDto[]> => {
    return await customAxios.post(`/customer_order/bulk_update/`, body).then(() => body);
  },
  getCustomerOrderList: async (data: getCustomerOrderListRequestDto): Promise<getCustomerOrderListResponseDto[]> => {
    const params = {
      ...data,
      EqualShow: 1,
    };
    return await customAxios.get("/customer_order/", { params }).then((res) =>
      res.data.map((item: any) => ({
        ...item,
        MissingCount: Math.max(0, item.Quantity_Target - item.Quantity_Find - item.TotalCount - item.OrderCount - item.CancelCount),
      }))
    );
  },
  deleteCustomerOrderDelete: async (body: deleteCustomerOrderDto[]): Promise<deleteCustomerOrderDto[]> => {
    return await customAxios.post("/customer_order/bulk_delete/", body).then(() => body);
  },
};
