import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Chip, Grid, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { FormEvent, createContext, useEffect, useMemo, useState } from "react";
import { basketNumberDto, updateOrderDto } from "../../../../service/order/types";
import { useUpdateOrder } from "../../../../service/order/orderHooks";
import { ShipmentItem } from "../../../../service/order/types";
import _ from "lodash";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';



export const ModalDataContext = createContext<basketNumberDto | null>(null);

interface Props {
  items: any | undefined;
  OrderId: any | undefined;
  ShipMent: ShipmentItem | undefined;
}

export const ShipMentModal: React.FC<Props> = ({ items, OrderId, ShipMent }) => {
  const [modalData, setModalData] = useState<basketNumberDto | null>(null);
  const [open, setOpen] = useState(false);
  const { mutate: updatedOrders } = useUpdateOrder();
  const [shipmentItems, setShipmentItems] = useState<ShipmentItem>({});

  useEffect(() => {
    if (items) {
      const basketListSet = new Set<string>();
      items.forEach((item: any) => {
        item.BoxNumberList?.filter((boxNumber: any) => boxNumber !== null).forEach((boxNumber: any) => {
          if(boxNumber.Quantity !== 0) {
            basketListSet.add(boxNumber.ToObjectCode)
          }
        });
      });

      const data:ShipmentItem = {}
      basketListSet.forEach((v:any)=>{
        data[v] = ''
      })
      setShipmentItems({ ...data, ...ShipMent });
    }
  }, [open, items, OrderId]);


  
  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (window.confirm("송장번호를 저장하시겠습니까?")) {
      /// 송장번호 post 함수

      // 값이 '' 인건 제외시키고 저장함
      const shipmentItemsTemp:ShipmentItem = {}
      Object.keys(shipmentItems).forEach(v =>{
        if(shipmentItems[v] !== '' ){
          shipmentItemsTemp[v] = shipmentItems[v]
        }
      })

      const data: updateOrderDto = {
        OrderID: OrderId,
        ShipMent: shipmentItemsTemp,
      };
      updatedOrders(data);
    }
    setModalData(null);
    handleClose(e);
  };

  const handleClose = (e: FormEvent) => {
    setModalData(null);
    setOpen(false);
  };

  const boxCount = useMemo(()=>{
    return Object.keys(shipmentItems).length;
  },[shipmentItems])

  const handleClickOpen = () => {
    if (boxCount > 9) {
      window.alert("바구니 번호 개수가 10개 이상입니다.");
      setOpen(false);
    } else if (boxCount < 1) {
      window.alert("바구니 번호가 없습니다.");
      setOpen(false);
    } else {
      setOpen(true);
    }
  };


  return (
    <React.Fragment>
      <ModalDataContext.Provider value={modalData}>
        <Button variant="contained" startIcon={<Inventory2OutlinedIcon />} onClick={handleClickOpen}>
          쉽먼트
        </Button>
        {boxCount > 9 ? null : (
          <Dialog open={open} onClose={handleClose} maxWidth="sm">
            <DialogTitle>송장번호 입력</DialogTitle>

            <DialogContent>
              <Stack gap={2}>
                {Object.keys(shipmentItems)
                  .slice(0, 9)
                  .map((key, index) => {
                    return (
                      <Stack flexDirection="row" justifyContent="space-betwenn" alignItems="center" key={index} gap={4}>
                        <Typography sx={{marginRight:"auto"}}>{key}</Typography>
                        <TextField value={shipmentItems[key]} onChange={(e) => setShipmentItems((pre) => ({ ...pre, [key]: e.target.value }))} />
                      </Stack>
                    );
                  })}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSave} variant="contained">
                저장
              </Button>
              <Button onClick={handleClose} variant="outlined">
                취소
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </ModalDataContext.Provider>
    </React.Fragment>
  );
};
