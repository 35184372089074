import { BrushRounded } from "@mui/icons-material";
import { useModals } from "../../service/modal/useModals";
import { CustomButton } from "../../shared/ui/buttom/CustomButton";
import { ShowOrderItemsByBarCodeModal } from "./ShowOrderItemsByBarCodeModal";
import { Button } from "@mui/material";

interface ShowOrderItemsByBarCodeButtonProps {
  BarCode: string;
}

const ShowOrderItemsByBarCodeButton: React.FC<ShowOrderItemsByBarCodeButtonProps> = ({ BarCode }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowOrderItemsByBarCodeModal);
  };

  const handleClickOpen = () => {
    openModal(ShowOrderItemsByBarCodeModal, { BarCode, handleClose });
  };

  return (
    <>
      {/* <CustomButton onClick={handleClickOpen}>보기</CustomButton> */}
      <Button onClick={handleClickOpen} variant="outlined">
        {" "}
        보기
      </Button>
    </>
  );
};

export default ShowOrderItemsByBarCodeButton;
