export const seasonOptions = [
  { label: "-", value: '-' },
  { label: "봄", value: "봄" },
  { label: "여름", value: "여름" },
  { label: "가을", value: "가을" },
  { label: "겨울", value: "겨울" },
  { label: "봄, 가을", value: "봄, 가을" },
  { label: "사계절", value: "사계절" },
];

