import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContentText,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect } from "react";
import { useGetOrderItemsByBarCode } from "../../service/order/orderHooks";
import RowStack from "../../shared/ui/RowStack";
import RefreshIconButton from "../../shared/ui/buttom/RefreshIconButton";
import { getOrderItemsByBarCodeResponseDto } from "../../service/order/types";
import { useNavigate } from "react-router-dom";

interface Column {
  id: keyof getOrderItemsByBarCodeResponseDto;
  label: string;
  minWidth?: number;
  align?: string;
  className?: string;
}

const columns: readonly Column[] = [
  { id: "IndividualOrderNumber", label: "발주번호", align: "left", minWidth: 100 },
  // { id: "ProductName", label: "상품명", align: "center" },
  // { id: "SKU_ID", label: "SKU_ID", align: "center" },
  { id: "Quantity", label: "발주수량", align: "center", className: "orange-header" },
  { id: "Quantity_OK", label: "확정수량", align: "center", className: "orange-header" },
  { id: "Quantity_Ready", label: "준비수량", align: "center", className: "yellow-header" },
  { id: "Quantity_Error", label: "오류수량", align: "center", className: "blue-header11" },
  { id: "Memo1", label: "발주 담당자메모", align: "left" }, // 발주 담당자 메모
  { id: "Memo2", label: "청도 주문메모", align: "left" }, // 청도 주문메모
  // { id: "Memo3", label: "기타메모", align: "center" }, // 기타메모
  // { id: "IBCNumber", label: "IBC 하차번호", align: "center" },
];

interface ShowOrderItemsByBarCodeModalProps {
  open: boolean;
  handleClose: () => void;
  BarCode: string;
}

export const ShowOrderItemsByBarCodeModal: React.FC<ShowOrderItemsByBarCodeModalProps> = ({ open, handleClose, BarCode }) => {
  const { data, refetch } = useGetOrderItemsByBarCode({ BarCode });
  const navigate = useNavigate();
  const handleMoveToIndividualOrder = (OrderID: number, BarCode: string) => {
    navigate(`/individualorder/${OrderID}?keyword=${BarCode}`);
    handleClose();
  };

  useEffect(() => {
    refetch();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px",
            height: "600px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <RowStack>
          <Typography variant="subtitle1" color="#333">
            발주 정보
          </Typography>
          <RefreshIconButton onClick={() => refetch()} />
        </RowStack>
      </DialogTitle>

      <DialogContent sx={{ width: "850px" }}>
        <TableContainer sx={{ height: 430, boxSizing: "border-box", border: "0.5px solid #DFDFDF" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell sx={{ fontWeight: "bold" }} align={column.align as any} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.BarCode}>
                      <TableCell
                        align={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          handleMoveToIndividualOrder(row["OrderID"], row["BarCode"]);
                        }}
                      >
                        <Stack direction="row" alignItems={"center"} gap={1} sx={{ color: "#6799ff", "&:hover": { color: "blue" } }}>
                          {row["IndividualOrderNumber"]}
                          <OpenInNewIcon />
                        </Stack>
                      </TableCell>
                      {columns
                        .filter((v) => v.id !== "IndividualOrderNumber")
                        .map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align as any} className={column.className || ""}>
                              {value}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
