import { inisialData } from "../const/initialData";
import { inisialDataType } from "../type/type";

export  function moveInventoryReducer(state: inisialDataType, action: { type: string; payload?: any }) {
    const { leftSelected, rightData, rightSelected, leftData } = state;
  
    switch (action.type) {
      case "INIT": // 초기화
        return inisialData;
      case "SET_LEFT_NUMBER":
        return { ...state, leftNumber: action.payload };
      case "SET_RIGHT_NUMBER":
        return { ...state, rightNumber: action.payload };
      case "SET_LEFT_SELECTED_ALL":
        return { ...state, leftSelected: leftData.length === leftSelected.length ? [] : leftData.map((v) => v.BarCode) };
      case "SET_RIGHT_SELECTED_ALL":
        return { ...state, rightSelected: rightData.length === rightSelected.length ? [] : rightData.map((v) => v.BarCode) };
      case "SET_SELECTED_CLEAR":
        return { ...state, rightSelected: [], leftSelected: [] };
      case "SET_LEFT_SELECTED":
        return {
          ...state,
          leftSelected: leftSelected.includes(action.payload) ? leftSelected.filter((v) => v !== action.payload) : [...leftSelected, action.payload],
        };
      case "SET_LEFT_TYPE":
        return { ...state, leftType: action.payload };
      case "SET_RIGHT_TYPE":
        return { ...state, rightType: action.payload };
      case "SET_RIGHT_SELECTED":
        return {
          ...state,
          rightSelected: rightSelected.includes(action.payload)
            ? rightSelected.filter((v) => v !== action.payload)
            : [...rightSelected, action.payload],
        };
      case "SET_LEFT_DATA":
        return { ...state, leftData: action.payload };
      case "SET_RIGHT_DATA":
        return { ...state, rightData: action.payload };
      case "DELETE_LEFT_DATA":
        return { ...state, leftData: leftData.filter((v) => !leftSelected.includes(v.BarCode)) };
      case "DELETE_RIGHT_DATA":
        return { ...state, rightData: rightData.filter((v) => !rightSelected.includes(v.BarCode)) };
      case "SET_HISTORY":
        return { ...state, history: action.payload };
  
      default:
        return state;
    }
  }