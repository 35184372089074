
/**
 * 자동으로 row의 높이를 계산하는 함수
 * \n을 기준으로 줄바꿈이 되며, 줄바꿈이 될 때마다 20px을 추가로 높이를 더해준다.
 * @param row 
 * @param defaultRowHeight 
 * @returns 
 */

export const autoRowHeight = (row: any, defaultRowHeight: number = 40) => {
  const add_height = 20;

  if (row.length === 0 || !row) return defaultRowHeight;

  const rowCount = Math.max(
    Math.max(
      ...Object.values(row)
        .filter((item) => typeof item === "string")
        .map((item) => (item as string).split("\n").length)
    ) - 1,
    0
  );

  return rowCount * add_height + defaultRowHeight;
};