import { Dialog, DialogTitle, DialogContent, DialogContentText, Stack, Typography, DialogActions, Button, Divider } from "@mui/material";

import styled from "@emotion/styled";
import { useGetOrderFulfillments } from "../../../../service/order/orderHooks";
import { useEffect } from "react";
import RowStack from "../../../../shared/ui/RowStack";

interface Props {
  open: boolean;
  handleClose: () => void;
  OrderItemID: number;
}
export const ShowQuantityCoupangModal: React.FC<Props> = ({ open, handleClose, OrderItemID }) => {
  const { data: list, refetch } = useGetOrderFulfillments({ OrderItemID });

  useEffect(() => {
    refetch();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">재고정보</DialogTitle>
      <DialogContent>
        <CardContainer>
          <Typography fontWeight="bold" fontSize="18px">
            히스토리
          </Typography>
          <Divider sx={{ my: 2 }} />
          {list && list.length !== 0 ? (
            list?.map((item: any, i: number) => {
              return (
                <>
                  <CardItem label={"토트번호"} value={item.ToObjectCode} />
                  <CardItem label={"개수"} value={item.Quantity} />
                  {/* <CardItem label={"작업자"} value={item.FulfilledBy} /> */}
                  <CardItem label={"날짜"} value={dateFormatter(item.FulfillmentDate)} />
                  {list.length !== i + 1 && <Divider sx={{ my: 2 }} />}
                </>
              );
            })
          ) : (
            <Typography textAlign={"center"} sx={{ color: "#999" }}>
              없음
            </Typography>
          )}
        </CardContainer>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CardContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 300px;
`;

interface CardItemProps {
  label: string;
  value: string | number;
}

const CardItem: React.FC<CardItemProps> = ({ label, value }) => {
  return (
    <RowStack>
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </RowStack>
  );
};

const dateFormatter = (str: string) => {
  const date = new Date(str);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
