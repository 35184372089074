import { Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

interface CountUpProps {
  end: number; // 최종 숫자 (n)
  duration: number; // 애니메이션 지속 시간 (밀리초)
  variant:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "overline"
    | "inherit"
    | undefined;
}

const CountUp: React.FC<CountUpProps> = ({ end, duration, variant }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false); // 화면에 보일 때만 카운트 실행
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current!); // 한 번 보이면 더 이상 관찰하지 않음
        }
      },
      { threshold: 0.1 } // 10% 이상 보일 때 트리거
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const start = performance.now(); // 애니메이션 시작 시간 기록

    const easeOutQuad = (t: number) => t * (2 - t); // 'ease-out' 효과를 위한 함수

    const step = (currentTime: number) => {
      const elapsedTime = currentTime - start; // 경과 시간 계산
      const progress = Math.min(elapsedTime / duration, 1); // 진행도는 0 ~ 1로 제한

      const easedProgress = easeOutQuad(progress); // 'ease-out' 곡선을 적용

      setCount(Math.floor(easedProgress * end)); // 끝 값에 따른 숫자 증가

      if (elapsedTime < duration) {
        requestAnimationFrame(step); // duration 이내면 애니메이션 지속
      }
    };

    requestAnimationFrame(step); // 애니메이션 시작
  }, [isVisible, end, duration]);

  return (
    <Typography variant={variant} ref={ref}>
      {count.toLocaleString()}
    </Typography>
  );
};

export default CountUp;
