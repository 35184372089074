
/**
 * 에러코드를 알림창으로 보여주는 메시지 
 * @param data 
 */
export function displayErrorMessage(data: { error: any; defaultErrorMessage: string }) {
  const { error, defaultErrorMessage } = data;
  let errorMessage = "";

  function processErrorData(responseData: any) {
    for (const key in responseData) {
      if (Array.isArray(responseData[key])) {
        // 배열인 경우 배열의 각 항목을 처리하여 새로운 줄에 추가
        /* eslint-disable */
        responseData[key].forEach((item: any) => {
          if (typeof item === "string") {
            errorMessage += item + "\n";
          }
        });
      } else if (typeof responseData[key] === "string") {
        // 문자열인 경우 바로 사용
        errorMessage += responseData[key] + "\n";
      } else {
        // 그 외의 경우 기본적으로 toString() 메서드를 사용하여 문자열로 변환
        errorMessage += responseData[key].toString() + "\n";
      }
    }
  }

  if (error.response && error.response.data) {
    const responseData = error.response.data;
    processErrorData(responseData);
  } else {
    errorMessage = defaultErrorMessage;
  }

  if (errorMessage.trim() === "") {
    errorMessage = defaultErrorMessage;
  }

  alert(errorMessage);
}
