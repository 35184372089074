import { Button, Card, Stack, TextField, Typography } from "@mui/material";
import { customAxios } from "../config/axios-config";
import { customToast } from "../shared/lib/customToast";
import { useState } from "react";

const ChangeBarCode = () => {
  const [old, setOld] = useState<string>("");
  const [newBarCode, setNewBarCode] = useState<string>("");

  const handleSubmit = async () => {
    if (old === "" || newBarCode === "") {
      customToast("info", "값을 넣으세요");
    } else {
      const body = {
        old_barcode: old,
        new_barcode: newBarCode,
      };

      customAxios
        .post("/products/update_barcode/", body)
        .then((res) => {
          customToast("success", "성공");
        })
        .catch((err) => {
          customToast("warn", "실패");
        });
    }
  };

  return (
    <Card sx={{ margin: "0 auto", mt: 10 }}>
      <Stack gap={2} alignItems={"center"}>
        <TextField value={old} onChange={(e) => setOld(e.target.value)} label="old" size="small" />
        <Typography>to</Typography>
        <TextField value={newBarCode} onChange={(e) => setNewBarCode(e.target.value)} label="new" size="small" />
        <Button variant="contained" onClick={handleSubmit} size="large">
          클릭
        </Button>
      </Stack>
    </Card>
  );
};

export default ChangeBarCode;
