import * as React from "react";
import Button from "@mui/material/Button";
import { useModals } from "../../../../service/modal/useModals";
import { CreateUserModal } from "./CreateUserModal";
import { useCreateUser } from "../../../../service/user/userHook";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createUserDto } from "../../../../service/user/types";

const CreateUserButton = () => {
  const { openModal, closeModal } = useModals();
  const { mutate: createUser } = useCreateUser();

  const handleClose = () => {
    closeModal(CreateUserModal);
  };

  const handleSubmit = (user: createUserDto) => {
    createUser(user);
    handleClose();
  };

  const handleOpenModal = () => {
    openModal(CreateUserModal, {
      handleClose: handleClose,
      handleSubmit: handleSubmit,
    });
  };
  return (
    <React.Fragment>
      <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={handleOpenModal}>
        사용자 추가
      </Button>
    </React.Fragment>
  );
};

export default CreateUserButton;
