import { Box, Card, Stack, Typography } from "@mui/material";
import RowStack from "../../../../shared/ui/RowStack";

interface Props {
  imageSrc: string;
  message: string;
  desc_text?: string;
}

const CardAlert: React.FC<Props> = ({ imageSrc, message, desc_text = "" }) => {
  return (
    <Box
      sx={{
        minWidth: "180px",
        maxWidth: "300px",
        px: 5,
        py: 4,
        borderRadius: "8px 8px 8px 8px",
        borderLeft: "20px solid #ffc100",
        backgroundColor: "white",
      }}
    >

      <RowStack gap={5}>
        {imageSrc ? <img src={imageSrc} alt="이미지" height="60px" /> : <img src={DEFAULT_IMG} alt="이미지" height="60px" />}
        <Stack gap={2}>
          <Typography sx={{ color: "#333", fontSize: "18px" }}>{message}</Typography>
          <Typography sx={{ color: "#999" }} variant="caption">
            {desc_text}
          </Typography>
        </Stack>
      </RowStack>
    </Box>
  );
};

export default CardAlert;

// export const DEFAULT_IMG = "https://cdn-icons-png.flaticon.com/128/17235/17235414.png";
export const DEFAULT_IMG = "https://cdn-icons-png.flaticon.com/128/6897/6897039.png";
