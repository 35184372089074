import { customAxios } from "../../config/axios-config";
import { dateToString, stringToDate } from "../../shared/lib/convertStringToDate";
import { getBasketRequestDto, getBasketResponseDto, createBasketDto, updateBasketDto, deleteBasketDto } from "./types";

export const basketApi = {
  getBasket: async (data: getBasketRequestDto): Promise<getBasketResponseDto> => {
    return await customAxios.get(`/baskets/${data.BasketID}/`).then((res) => stringToDate(res.data));
  },
  getBasketList: async (): Promise<getBasketResponseDto[]> => {
    return await customAxios.get("/baskets/").then((res) => res.data.map((data: getBasketResponseDto) => stringToDate(data)));
  },
  createBasket: async (body: createBasketDto): Promise<createBasketDto> => {
    return await customAxios.post("/baskets/", dateToString(body)).then(() => stringToDate(body));
  },
  updateBasket: async (data: updateBasketDto): Promise<updateBasketDto> => {
    const body = {
      IsCoupang: data.IsCoupang,
      CoupangBaskID: data.CoupangBaskID,
    };
    return await customAxios.patch(`/baskets/${data.BasketID}/`, body).then(() => data);
  },
  deleteBasket: async (data: deleteBasketDto): Promise<deleteBasketDto> => {
    return await customAxios.delete(`/baskets/${data.BasketID}/`).then(() => data);
  },
  
};
