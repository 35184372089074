

/**
 * @returns 현재 시간을 포맷팅하여 반환
 */
export function getCurrentFormattedTime():string {
    const now = new Date();
    const year = String(now.getFullYear()).slice(2); // yy
    const month = String(now.getMonth() + 1).padStart(2, '0'); // mm
    const day = String(now.getDate()).padStart(2, '0'); // dd
    const hours = String(now.getHours()).padStart(2, '0'); // hh
    const minutes = String(now.getMinutes()).padStart(2, '0'); // mm
    const seconds = String(now.getSeconds()).padStart(2, '0'); // ss
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  
  