import { Stack, Typography, TextField, Tooltip, IconButton } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { QUERY_KEY } from "../../../config/querykey";
import { useGetOrderList, useUpdateOrder } from "../../../service/order/orderHooks";
import { getOrderResponseDto, updateOrderDto } from "../../../service/order/types";
import { customToast } from "../../../shared/lib/customToast";
import { useBrandCodeStore } from "../../../store/brandStore";
import EditIcon from "@mui/icons-material/Edit";

interface OrderAliasProps {
  OrderID: number;
}

export const OrderAliasTitle: React.FC<OrderAliasProps> = ({ OrderID }) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([QUERY_KEY.order, OrderID]) as getOrderResponseDto;

  const BrandCode = useBrandCodeStore((s) => s.BrandCode);
  const { data: orderList } = useGetOrderList({ brand: BrandCode });
  const [subtitle, setSubTitle] = useState<string>("");
  const { mutate: updateOrderName } = useUpdateOrder();

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (data) {
      setSubTitle(data.OrderAlias);
    }
  }, [data]);

  if (!data || OrderID === 0) return <>로딩중...</>;

  const handleSubmit = () => {
    setIsEdit(false);
    const dto: updateOrderDto = {
      OrderID,
      OrderAlias: subtitle,
      // OrderAlias : data.OrderAlias
    };

    const titleList = orderList?.map((v) => v.OrderAlias);
    if (titleList?.includes(dto.OrderAlias || "")) {
      customToast("warn", "이미 있는 발주서 이름 입니다.");
    }
    if (subtitle.trim().includes("사전확정")) {
      customToast("warn", "사전확정은 사용할 수 없는 단어입니다.");
    } else {
      customToast("info", "발주서 이름이 변경되었습니다.");
      updateOrderName(dto);
    }
  };

  const handleChangeTitle = (e: any) => {
    setIsEdit(true);
    const { value } = e.target;
    if (value === data.OrderAlias) setIsEdit(false);
    setSubTitle(value);
  };

  return (
    <Stack sx={{ color: "white", height: "100%" }} alignItems={"center"} gap={1} direction="row">
      <Typography> 발주서 : </Typography>

      {data.OrderAlias.includes("사전확정") ? (
        <Typography> {subtitle}</Typography>
      ) : (
        <TextField
          InputProps={{
            sx: {
              color: "white", // 글씨 색상을 하얀색으로 변경
              height: "30px",
            },
          }}
          value={subtitle}
          onChange={handleChangeTitle}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(); // Enter 키를 눌렀을 때 호출할 함수
            }
          }}
        />
      )}

      {data.IsFlex && <Typography>[Flex]</Typography>}

      {isEdit && (
        <Tooltip arrow title="클릭시 발주서명이 수정됩니다.">
          <IconButton onClick={handleSubmit} sx={{ color: "white" }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default OrderAliasTitle;
