import { create } from "zustand";

type State = {
  term: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
};

type Actions = {
  setTerm: (data: any) => void;
  getStartDateToString: () => string;
  getEndDateToString: () => string;
  getRange: () => string[];
};

export const useDateRangeStore = create<State & Actions>((set, get) => ({
  term: {
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
    key: "selection",
  },
  setTerm: (data: any) => {
    set((state) => ({
      ...state,
      term: data,
    }));
  },
  getStartDateToString: () => {
    return formatDate(get().term.startDate);
  },
  getEndDateToString: () => {
    return formatDate(get().term.endDate);
  },
  getRange: () => {
    const { startDate, endDate } = get().term;
    return getDatesInRange(startDate, endDate);
  },
}));

//배열로 출력할 때 어떤 형태로 출력할지 나타내는 함수
export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월은 0부터 시작하므로 +1
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

//지정된 날짜구간 날짜배열 생성함수
function getDatesInRange(startDate: Date, endDate: Date) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(formatDate(currentDate));
    currentDate.setDate(currentDate.getDate() + 1); // 하루씩 더함
  }

  return dateArray;
}
