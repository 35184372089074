// date => string
export function dateToString(obj: { [key: string]: any }): { [key: string]: any } {
  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  let newObj: { [key: string]: any } = {};

  for (let key in obj) {
    if (obj[key] instanceof Date) {
      newObj[key] = formatDate(obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

// string => date
export function stringToDate<T>(obj: { [key: string]: any }): T {
  const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*$/;

  let newObj: { [key: string]: any } = {};

  for (let key in obj) {
    if (typeof obj[key] === "string" && datePattern.test(obj[key])) {
      // 날짜 문자열을 "T"와 "Z"를 추가하여 ISO 8601 형식으로 변환
        const isoString = obj[key].replace(" ", "T") + "Z";
        const date = new Date(isoString);

        // 9시간 빼기
        date.setHours(date.getHours() - 9);
        newObj[key] = date
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj as T;
}
