import React, { createContext, useContext, useState, ReactNode } from "react";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (data: any) => void;
}

interface ModalContextType {
  openModal: (Component: React.FC<any>, props: ModalProps) => void;
  closeModal: (Component: React.FC<any>) => void;
}

const ModalContext = createContext<ModalContextType | null>(null);

export const useModals = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModals must be used within a ModalProvider");
  }
  return context;
};

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<{ Component: React.FC<any>; props: ModalProps }[]>([]);

  const openModal = (Component: React.FC<any>, props: ModalProps) => {
    setModals([...modals, { Component, props }]);
  };

  const closeModal = (Component: React.FC<any>) => {
    setModals(modals.filter((modal) => modal.Component !== Component));
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modals.map((modal, index) => {
        const { Component, props } = modal;
        return <Component key={index} {...props} />;
      })}
    </ModalContext.Provider>
  );
};
