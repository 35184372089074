import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type State = {
  brandStr: string; // 브랜드
  startDate: string; //시작날
  endDate: string; //마지막날
  sortType: { columnKey: string; direction: "ASC" | "DESC" }; //정렬 순서
  term: {
    //이걸 따로 두는이유는 DateRageButton.tsx에서 사용하는 값과 실제로 눈에 보이는 값이 다르기 때문에
    startDate: Date;
    endDate: Date;
    key: string;
  };
  statusMenoFilter: string;
};

type Actions = {
  setBrandStr: (data: string) => void;
  setSortType: (data: { columnKey: string; direction: "ASC" | "DESC" } | any) => void;
  setTerm: (data: any) => void;
  setStatusMenoFilter: (data: string) => void;
};

export const useCustomerBrandStore = create(
  persist<State & Actions>(
    (set, get) => ({
      brandStr: "B001",
      startDate: getFirstAndLastDayOfMonth().firstDay,
      endDate: getFirstAndLastDayOfMonth().lastDay,
      sortType: { columnKey: "StatusMeno", direction: "ASC" },
      term: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        key: "selection",
      },
      statusMenoFilter: "전체",
      // 브랜드 셋팅
      setBrandStr: (data: string) => {
        set((state) => ({
          ...state,
          brandStr: data,
        }));
      },
      setSortType: (data: { columnKey: string; direction: "ASC" | "DESC" }) => {
        set((state) => ({
          ...state,
          sortType: data,
        }));
      },
      //날짜 세팅
      setTerm: (data: any) => {
        set((state) => ({
          ...state,
          term: data,
          startDate: formatDateToYYYYMMDD(data.startDate),
          endDate: formatDateToYYYYMMDD(data.endDate),
        }));
      },
      setStatusMenoFilter: (data: string) => {
        set((state) => ({
          ...state,
          statusMenoFilter: data,
        }));
      },
    }),
    {
      name: "CUSTOMER_DATE_BRAND_FILTER",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

//첫번재 날과 마지막 날을 꺼내주는 함수
function getFirstAndLastDayOfMonth() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  // 이번 달의 첫 번째 날
  const firstDay = new Date(year, month, 1);

  // 이번 달의 마지막 날
  const lastDay = new Date(year, month + 1, 0);

  // 날짜를 yyyy-MM-dd 형식으로 변환
  const formatDate = (date: any) => date.toISOString().split("T")[0];

  return {
    firstDay: formatDate(firstDay),
    lastDay: formatDate(lastDay),
  };
}

function formatDateToYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더해줌, 2자리로 맞춤
  const day = String(date.getDate()).padStart(2, "0"); // 일도 2자리로 맞춤

  return `${year}${month}${day}`;
}
