import RocketTable from "../entities/rocket/ui/RocketTable";

const RocketFind = () => {
  return (
    <>
      <RocketTable />
    </>
  );
};

export default RocketFind;
