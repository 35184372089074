import OrderListTable from "../entities/order-list/ui/OrderListTable";

const OrderList = () => {
  return (
    <>
      <OrderListTable />
    </>
  );
};

export default OrderList;
