import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; //
import { Card } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { getColor } from "../lib/getColor";
import { useDateRangeStore } from "../../../store/DateRangeStore";
import { useMemo } from "react";
import { DateInfoType } from "../../../service/sales-summary/types";
import { cumulativeSum } from "../../../shared/lib/cumulativeSum";

interface Props {
  data: DateInfoType;
  colorType: number;
  isMall?: boolean;
}

//버튼 스타일
const SalesQuantityLineChart: React.FC<Props> = ({ data, colorType, isMall = false }) => {
  const { getRange } = useDateRangeStore((v) => v);

  const totalQuantityList = useMemo(() => {
    return !!data ? getRange().map((v: string) => data[v]?.total_Quantity || 0) : [];
  }, [data]);

  const totalQuantityOkList = useMemo(() => {
    return !!data ? getRange().map((v: string) => data[v]?.total_QuantityOK || 0) : [];
  }, [data]);

  const totalQuantityFindList = useMemo(() => {
    return !!data ? getRange().map((v: string) => data[v]?.total_QuantityFind || 0) : [];
  }, [data]);

  const totalQuantityTargetList = useMemo(() => {
    return !!data ? getRange().map((v: string) => data[v]?.total_QuantityTarget || 0) : [];
  }, [data]);

  return (
    <>
      <Card>
        <LineChart
          xAxis={[
            {
              data: getRange(), // x축 범위 데이터
              scaleType: "point",
              // valueFormatter: (item) => {
              //   //날짜 포멧 지정하기
              //   return MMDDArray[item];
              // },
              tickInterval: (value, index) => {
                return tickIntervalFunction(index, getRange().length);
              },
              tickLabelStyle: {
                angle: -45,
                textAnchor: "end",
                fontSize: 12,
              },
            },
          ]}
          series={[
            {
              curve: "linear",
              id: "id",
              label:isMall?"보낸수량" : "발주수량",
              data: cumulativeSum(isMall ? totalQuantityFindList : totalQuantityList),
              showMark: false,
              color: getColor(colorType, 1),
              valueFormatter: (v) => {
                return v?.toLocaleString() + "개";
              },
            },
            {
              curve: "linear",
              id: "id2",
              label:isMall?"주문수량" : "확정수량",
              data: cumulativeSum(isMall ? totalQuantityTargetList : totalQuantityOkList),
              showMark: false,
              color: getColor(colorType, 4),
              valueFormatter: (v) => {
                return v?.toLocaleString() + "개";
              },
            },
          ]}
          height={400} // 높이
          margin={{ left: 70, top: 50, bottom: 80 }}
        />
      </Card>
    </>
  );
};

export default SalesQuantityLineChart;

function tickIntervalFunction(index: number, length: number) {
  const divider = Math.ceil((length || 1) / 20);
  return index % divider === 0;
}
