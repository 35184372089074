import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useModals } from "../../../../service/modal/useModals";
import { BarcodeAlertDto } from "../../../../service/barcode-alert/types";
import UpdateBarcodeAlertModal from "./UpdateBarcodeAlertModal";
import { MenuItem } from "@mui/material";
import { CustomButton } from "../../../../shared/ui/buttom/CustomButton";



interface Props {
  data: BarcodeAlertDto
}

const UpdateBarcodeAlertButton: React.FC<Props> = ({ data }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(UpdateBarcodeAlertModal);
  };

  const handleClickOpen = () => {
    openModal(UpdateBarcodeAlertModal, { data, handleClose });
  };

  return (
    <React.Fragment>
         <MenuItem onClick={handleClickOpen}>
              <EditIcon sx={{ mr: 2 }} />
              수정하기
            </MenuItem>
    </React.Fragment>
  );
};

export default UpdateBarcodeAlertButton;
