import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEY } from "../../config/querykey";
import { Id } from "../../shared/type/Id";
import { addIdColumn } from "../../shared/lib/addIdColumn";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { basketApi } from "./BasketAxios";
import { getBasketRequestDto, getBasketResponseDto, getBasketListResponseDto, updateBasketDto, createBasketDto, deleteBasketDto } from "./types";

export const useGetBasket = (data: getBasketRequestDto) => {
  return useQuery<getBasketResponseDto, Error>({
    queryKey: [QUERY_KEY.basket, data],
    queryFn: () => basketApi.getBasket(data),
  });
};

export const useGetBasketList = () => {
  return useQuery<getBasketListResponseDto[], Error, (getBasketListResponseDto & Id)[]>({
    queryKey: [QUERY_KEY.basketList],
    queryFn: () => basketApi.getBasketList(),
    select: (data) => addIdColumn(data),
  });
};

export const useUpdateBasket = () => {
  const queryClient = useQueryClient();
  return useMutation<updateBasketDto, Error, updateBasketDto>({
    mutationFn: (data: updateBasketDto) => basketApi.updateBasket(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.basketList], (oldData: (getBasketListResponseDto & Id)[]) => {
        return oldData.map((item) => {
          if (item.BasketID === data.BasketID) {
            return {
              ...item,
              BasketID: data.BasketID,
              CoupangBaskID: data.CoupangBaskID,
              isCoupang: data.IsCoupang,
            };
          } else {
            return item;
          }
        });
      });
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "바구니 업데이트에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useCreateBasket = () => {
  const queryClient = useQueryClient();
  return useMutation<createBasketDto, Error, createBasketDto>({
    mutationFn: (data: createBasketDto) => basketApi.createBasket(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.basketList] });
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "바구니 생성에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useDeleteBasket = () => {
  const queryClient = useQueryClient();
  return useMutation<deleteBasketDto, Error, deleteBasketDto>({
    mutationFn: (data) => basketApi.deleteBasket(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.basketList], (oldData: (getBasketListResponseDto & Id)[] | undefined) => {
        return oldData ? oldData.filter((item) => item.BasketID !== data.BasketID) : [];
      });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "바구니 삭제에 실패했습니다. 잠시후 다시 시도해주세요.", error: err });
    },
  });
};
