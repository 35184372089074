import { Stack, Box } from "@mui/material";
import { boxStyle } from "../../style/boxStyle";
import RowStack from "../../../../shared/ui/RowStack";

interface ShowPreviewRackCodeProps {
  rackData: any;
}

export const ShowPreviewRackCode: React.FC<ShowPreviewRackCodeProps> = ({ rackData }) => {
  return (
    <RowStack sx={{ mt: 2 }}>
      {/* <Box sx={boxStyle}>{rackData.warehouseCode}</Box>
        <Box sx={boxStyle}>{rackData.flexCode}</Box>
        <Box sx={boxStyle}>{rackData.floorCode}</Box>
        &nbsp;
        <Box sx={boxStyle}>{rackData.rackCode[0]}</Box>
        <Box sx={boxStyle}>{rackData.rackCode[1]}</Box>
        <Box sx={boxStyle}>{rackData.rackCode[2]}</Box>
        <Box sx={boxStyle}>{rackData.rackDanCode}</Box> */}

      <Box sx={boxStyle}>{rackData[0]}</Box>
      <Box sx={boxStyle}>{rackData[1]}</Box>
      <Box sx={boxStyle}>{rackData[2]}</Box>
      {/* &nbsp; */}
      <Box sx={boxStyle}>{rackData[3]}</Box>
      <Box sx={boxStyle}>{rackData[4]}</Box>
      <Box sx={boxStyle}>{rackData[5]}</Box>
      <Box sx={boxStyle}>{rackData[6]}</Box>
    </RowStack>
  );
};
