import { Pagination, Typography } from "@mui/material";
import RowStack from "../../../../shared/ui/RowStack";

interface CustomPaginationProps {
    count: number;
    page: number;
    pageSize: number;
    onChange: (e: any) => void;
  }
  
  const CustomPagination: React.FC<CustomPaginationProps> = ({ count, page, pageSize, onChange }) => {
    return (
      <RowStack sx={{ px: 5, width: "100%" }}>
        <div></div>
        <Pagination
          siblingCount={4}
          count={Math.ceil(count / pageSize) || 0}
          size="large"
          page={page}
          onChange={(event, value) => {
            onChange({ target: { name: "page", value: value } });
          }}
        />
        <Typography variant="body2">
          {(page - 1) * pageSize + 1} ~ {Math.min(page * pageSize, count)} / {count.toLocaleString()} 개
        </Typography>
      </RowStack>
    );
  };
  

  export default CustomPagination;