import CustomerFindOrderTable from "../entities/customer-find-order/ui/CustomerFindOrderTable";

const CustomerFindOrder = () => {
  return (
    <>
      <CustomerFindOrderTable />
    </>
  );
};

export default CustomerFindOrder;
