// UTC 시간을 KST(한국 표준시)로 변환하는 함수
export function convertToKST(utcDateString:string) {
    // UTC 시간을 Date 객체로 생성
    const utcDate = new Date(utcDateString);

    // KST로 변환 (UTC + 9시간)
    const kstDate = new Date(utcDate.getTime() + 9 * 60 * 60 * 1000);

    // KST 시간 문자열 반환 (한국어 형식)
    return kstDate.toLocaleString('ko-KR', {
        timeZone: 'Asia/Seoul',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
}
