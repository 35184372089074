import UserTable from "../entities/user/ui/UserTable";

const Users = () => {
  return (
    <>
      <UserTable />
    </>
  );
};

export default Users;
