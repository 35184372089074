import RefreshIcon from "@mui/icons-material/Refresh";
import { Tooltip, Typography, IconButton } from "@mui/material";

interface Props {
  refetch: () => void;
}

const RefetchButton: React.FC<Props> = ({ refetch }) => {
  return (
    <Tooltip title={<Typography variant="subtitle2">새로고침</Typography>} placement="top" arrow>
      <IconButton onClick={() => refetch()}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RefetchButton;