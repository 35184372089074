import { customAxios } from "../../config/axios-config";
import { getRackRequestDto, getRackListResponseDto, createRackRequestDto, createRackResponseDto, updateRackDto, deleteRackRequestDto, getRackResponseDto } from "./types";
import { stringToDate, dateToString } from "../../shared/lib/convertStringToDate";
export const rackApi = {
  getRack: async (data: getRackRequestDto): Promise<getRackResponseDto> => {
    return await customAxios.get(`/racks/${data.RackID}/`).then((res) => stringToDate(res.data));
  },
  getRackList: async (): Promise<getRackListResponseDto[]> => {
    return await customAxios.get("/racks/").then((res) => res.data.map((data: getRackListResponseDto) => stringToDate(data)));
  },
  createRack: async (body: createRackRequestDto): Promise<createRackResponseDto> => {
    return await customAxios.post("/racks/", dateToString(body)).then((res) => stringToDate(res.data));
  },
  updateRack: async (data: updateRackDto): Promise<updateRackDto> => {
    const body = {
      Brand: data.Brand,
      IsCoupang: data.IsCoupang,
      CoupangRackID: data.CoupangRackID,
    };
    return await customAxios.patch(`/racks/${data.RackID}/`, body).then(() => data);
  },
  deleteRack: async (data: deleteRackRequestDto) => {
    return await customAxios.delete(`/racks/${data.RackID}/`).then(() => data);
  },

};
