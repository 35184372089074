import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";

interface RefreshButtonProps {
  onClick: () => void;
  isFetching?: boolean;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ onClick, isFetching = false }) => {
  return (
    <Button sx={{ backgroundColor: "white" }} onClick={onClick} variant={"outlined"} startIcon={<RefreshIcon />}>
      {isFetching ? "...로딩중" : "새로고침"}
    </Button>
  );
};

export default RefreshButton;
