import { Dialog, DialogTitle, DialogContent, Stack, Typography, DialogActions, Button, Divider, IconButton} from "@mui/material";
import styled from "@emotion/styled";
import { useGetBoxNameListByOrderItemId, useUpdateFulfillments } from "../../../service/total-order/totalOrderListHooks";
import { useEffect, useMemo, useState } from "react";
import { Basket, ItemResponse } from "../../../service/order/types";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { customToast } from "../../../shared/lib/customToast";
import _ from "lodash";
import { ShowHistory } from "./ShowHistoryModal";
import RowStack from "../../../shared/ui/RowStack";



interface ShowBoxNameListModalProps {
  open: boolean;
  handleClose: () => void;
  BarCode: string;
  OrderItemID: number;
  row: ItemResponse;
  Center: string;
  OrderID: number;
}

type BasketInfo = {
  [key:string]:{
    Quantity:number,
    RemoveQuantity:number,
  }
}

export const ShowBoxNameListModal: React.FC<ShowBoxNameListModalProps> = ({ OrderID, row, Center, BarCode, open, handleClose, OrderItemID }) => {
  const { data, refetch } = useGetBoxNameListByOrderItemId({ orderItemID: OrderItemID }); //재고
  const [basketInfo, setBasketInfo ] = useState<BasketInfo>({});
  const { mutateAsync: updateFulfillments } = useUpdateFulfillments();

  useEffect(()=>{
    refetch()
  },[open])
   
const basketList = useMemo(()=>{
  return Object.keys(basketInfo)
},[basketInfo])

  //data 가공해서 넣어둠 
  useEffect(()=>{
    let result:BasketInfo= {};
    if(data){
      data.forEach((v:Basket)=>{
        if(result[v.ToObjectCode]){
          result[v.ToObjectCode].Quantity += v.Quantity;
        }else{
          result[v.ToObjectCode] = {
            Quantity:v.Quantity,
            RemoveQuantity:0,
          }
        }
      })
    }

    setBasketInfo(result) 
  },[data])


  const handleChange = (ToObjectCode:string, number:number) =>{
    setBasketInfo((prev:BasketInfo) =>{
      const newData = _.cloneDeep(prev)
      const {RemoveQuantity, Quantity} =  prev[ToObjectCode];

      if(RemoveQuantity+number > 0) customToast("warn","기존수량보다 많이 입력할 수 없습니다.")
        if(RemoveQuantity+number < -1*Quantity) customToast("warn","수량은 0보다 작을 수 없습니다. ")

      newData[ToObjectCode].RemoveQuantity  = Math.min(0, Math.max(-1 *Quantity, (RemoveQuantity+number)))
      return newData;
    })
  }




  const handleUpdateFulfillments = (ToObjectCode:string, RemoveQuantity:number)=>{
    if(confirm("수량을 변경하시겠습니까?")){
      const dto = {
        OrderID,
        OrderItemID,
        ToObjectCode: ToObjectCode,
        Quantity: RemoveQuantity,
        BarCode,
        ToType: "cancel",
        Status: "R",
      }
      updateFulfillments(dto);
      refetch();
    }
  }
  



  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">찾은수량<br/>
       </DialogTitle>

      <DialogContent>
        <Stack justifyContent={"space-between"} direction={"row"} sx={{mb:2}}>

      <Typography fontWeight="bold" fontSize="18px" color="black">
              {BarCode}
            </Typography>
            <ShowHistory rows={data || []}/>
        </Stack>
        <Stack gap={1}>
          <CardContainer>
       
            {basketList.length !== 0 ? (
              basketList.filter( v => basketInfo[v].Quantity !== 0).map((basketName, i) => {
                const {Quantity, RemoveQuantity} = basketInfo[basketName]
                return (
                <Stack key={i} sx={{ mb: 1 }}>
                  <RowStack>
                    <Typography sx={{ color: "#999" }}>{Center === "VF150" ? "토트번호" : "상자이름"} :</Typography>
                    <Typography>{basketName}</Typography>
                  </RowStack>
                  <RowStack>
                    <Typography sx={{ color: "#999", marginRight:"auto" }}>개수 :</Typography>
                      <Typography>{(Quantity+RemoveQuantity).toLocaleString()} 개</Typography>
                     
                          <IconButton onClick={() => handleChange(basketName, +1)} disabled = {RemoveQuantity === 0}>
                            <AddCircleOutlineIcon color={RemoveQuantity === 0 ? "disabled":"primary"} />
                          </IconButton>
                          <IconButton onClick={() => handleChange(basketName, -1)} disabled = {RemoveQuantity+Quantity === 0}>
                            <RemoveCircleOutlineIcon color={RemoveQuantity+Quantity === 0 ? "disabled":"primary"} />
                          </IconButton>
                          <Button variant="contained" disabled={RemoveQuantity === 0} onClick={()=>{
                            handleUpdateFulfillments(basketName, RemoveQuantity)
                          }}>
                              변경 ({RemoveQuantity})
                          </Button>
                        
                  </RowStack>
                  <Divider sx={{ my: 2 }} />
                </Stack>
              )})
            ) : (
              <Typography textAlign={"center"} sx={{ color: "#999" }}>
                없음
              </Typography>
            )}
            <RowStack>
              <Typography sx={{ color: "#666" }}>총개수 :</Typography>
              <Typography>{!!basketList ? basketList?.reduce((r, a) => r + basketInfo[a].Quantity+basketInfo[a].RemoveQuantity, 0) : 0} 개</Typography>
            </RowStack>
          </CardContainer>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};



const CardContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 300px;
`;

