import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type ShowColumnToggle = {
  showColumnToggle: boolean;
};

type ShowColumnToggleActions = {
  setShowColumnToggle: (data: boolean) => void;
};

export const useShowColumnToggleStore = create(
  persist<ShowColumnToggle & ShowColumnToggleActions>(
    (set) => ({
      showColumnToggle: false,
      setShowColumnToggle: (data: boolean) => {
        set({
          showColumnToggle: data,
        });
      },
    }),
    {
      name: "PRODUCT_TABLE_HIDDEN_COLUMNS",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
