import * as React from "react";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useModals } from "../../../../service/modal/useModals";
import { CreateRackModal } from "./CreateRackModal";
import { useCreateRack } from "../../../../service/rack/rackHooks";
import { createRackRequestDto } from "../../../../service/rack/types";
import { CompareOutlined } from "@mui/icons-material";

const AddRackButton = () => {
  const { openModal, closeModal } = useModals();
  const { mutate: createRack } = useCreateRack();
  //닫기 함수
  const handleClose = () => {
    closeModal(CreateRackModal);
  };

  //비즈니스 로직 : 랙추가 함수
  const handleSubmit = (data: createRackRequestDto) => {
    createRack(data);
    handleClose();
  };

  //열기 함수
  const handleOpenModal = () => {
    openModal(CreateRackModal, {
      handleSubmit: handleSubmit,
      handleClose: handleClose,
    });
  };

  return (
    <React.Fragment>
      <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={handleOpenModal}>
        랙 추가
      </Button>
    </React.Fragment>
  );
};

export default AddRackButton;
