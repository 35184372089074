import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { userApi } from "./UserAxios";
import { LoginRequestDto, LoginResponseDto, createUserDto, deleteUserDto, getUserResponseDto, updateUserDto } from "./types";
import { useNavigate } from "react-router-dom";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { QUERY_KEY } from "../../config/querykey";
import { addIdColumn } from "../../shared/lib/addIdColumn";
import { Id } from "../../shared/type/Id";
import { clearUserInfo, setUserInfo } from "../../store/UserInfoStore";

export const useLogin = () => {
  const navigate = useNavigate();
  return useMutation<LoginResponseDto, any, LoginRequestDto>({
    mutationFn: (data: LoginRequestDto) => userApi.login(data),
    onSuccess: (data) => {
      /**
       * 1. 유저정보 localStorage 저장
       * 2. 로그인시 페이지 이동 /users
       */
      setUserInfo({ UserName: data.UserName, IsStaff: data.is_staff, RoleID: data.roleID });
      navigate("/users");
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "로그인에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => userApi.logout(),
    onSuccess: () => {
      clearUserInfo();
      navigate("/");
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "로그아웃에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<createUserDto, Error, createUserDto>({
    mutationFn: (data: createUserDto) => userApi.createUser(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.userList] });
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "유저생성에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useGetUserList = () => {
  return useQuery<getUserResponseDto[], Error, (getUserResponseDto & Id)[]>({
    queryKey: [QUERY_KEY.userList],
    queryFn: () => userApi.getUserList(),
    select: (data) => addIdColumn(data),
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation<deleteUserDto, Error, deleteUserDto>({
    mutationFn: (data: deleteUserDto) => userApi.deleteUser(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.userList], (oldData: (getUserResponseDto & Id)[] | undefined) =>
        oldData ? oldData.filter((item) => item.UserName !== data.UserName) : []
      );
    },
    onError: (err) => {
      console.log(err);
      displayErrorMessage({ error: err, defaultErrorMessage: "유저삭제에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<updateUserDto, Error, updateUserDto>({
    mutationFn: (data: updateUserDto) => userApi.updateUser(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.userList], (oldData: (getUserResponseDto & Id)[] | undefined) =>
        oldData
          ? oldData.map((item) => {
              if (item.UserID === data.UserID) {
                return { ...item, ExpireTime: data.ExpireTime };
              }
              return item;
            })
          : []
      );
    },
    onError: (err) => {
      displayErrorMessage({ error: err, defaultErrorMessage: "유저정보수정에 실패했습니다. 잠시후 다시 시도해주세요." });
    },
  });
};
