import { customAxios } from "../../config/axios-config";
import { createTargetFindItemDto, deleteTargetFindDto, getTargetFindItemDto, updateTargetFindDto, updateTargetFindMemodto } from "./types";

export const targetFindApi = {
  getTargetFind: async (): Promise<getTargetFindItemDto[]> => {
    return await customAxios.get(`/find_items/getList/`).then((res) => res.data);
  },
  getTargetFindExcelFormat: async (data: any): Promise<any> => {
    return await customAxios.get(`/find_items/getXlsxFormat/`).then((res) => res.data);
  },
  createTargetFind: async (body: createTargetFindItemDto[]): Promise<createTargetFindItemDto[]> => {
    return await customAxios.post(`/find_items/setFindItems/`, body).then(() => body);
  },
  updateTargetFind: async (body: updateTargetFindDto): Promise<updateTargetFindDto> => {
    return await customAxios.post("/find_items/changeQuantity/", body).then((res) => res.data);
  },

  updateTargetFindMemo: async (body: updateTargetFindMemodto): Promise<updateTargetFindMemodto> => {
    return await customAxios.patch(`/find_items/${body.FindItemID}/`, body).then((res) => body);
  },
  deleteTargetFind: async (body: deleteTargetFindDto): Promise<deleteTargetFindDto> => {
    return await customAxios.delete(`/find_items/${body.FindItemID}/`).then(() => body);
  },
};
