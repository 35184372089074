import { Button, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { ItemResponse } from "../../../../service/order/types";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import IssueListTable from "./IssuListTable";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
interface Props {
  mismatchedValues: ItemResponse[];
  lowRocketMargin: ItemResponse[];
  emptyRocketMargin: ItemResponse[];
  totalCount: number;
}

const IssueListButton: React.FC<Props> = ({ mismatchedValues, lowRocketMargin, emptyRocketMargin, totalCount }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState("1");

  //tab 변경 버튼
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChangeOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Button onClick={handleChangeOpen} sx={{ backgroundColor: "white" }} variant="outlined" color="error" startIcon={<ErrorOutlineIcon />}>
        이슈 목록 {totalCount}건
      </Button>
      <Dialog
        open={open}
        onClose={handleChangeOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "950px", // Set your width here
              height: "800px",
            },
          },
        }}
      >
        <DialogTitle>
          <Stack direction={"row"} alignItems={"center"}>
            <img src="https://cdn-icons-png.flaticon.com/128/559/559343.png" alt="경고" width={30} />
            <Typography sx={{ marginRight: "auto", marginLeft: 2, color: "red" }}>이슈 목록 </Typography>

            <IconButton onClick={handleChangeOpen}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="견적가 불일치" value="1" />
                <Tab label="로켓마진 누락" value="2" />
                <Tab label="로켓마진 1000원이하" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <IssueListTable data={mismatchedValues} />
            </TabPanel>
            <TabPanel value="2">
              <IssueListTable data={emptyRocketMargin} />
            </TabPanel>
            <TabPanel value="3">
              <IssueListTable data={lowRocketMargin} isLowPriceTable={true} />
            </TabPanel>
          </TabContext>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default IssueListButton;
