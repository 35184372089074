import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { MAX_CAPACITY } from "../../../../shared/const/constant";

interface ProgressBarProps {
  value: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  const percentage = React.useMemo(() => {
    let result;
    if (value <= 4 && value >= 1) {
      result = 1;
    } else {
      result = Math.round((value / MAX_CAPACITY) * 100);
    }

    return result;
  }, [value]);

  const color = React.useMemo(() => {
    if (percentage < 50) {
      return "#0cca25";
    } else if (percentage < 80) {
      return "#FFC266";
    } else {
      return "#FF6666";
    }
  }, [percentage]);

  return (
    <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }}>
      <Box
        sx={{
          position: "relative",
          width: "100px",
          height: "30px",
          background: `linear-gradient(to right, ${color} ${percentage}%, transparent ${percentage >= 100 ? 100 : percentage}%)`,
          border: "1px solid grey",
          display: "flex",

          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle2" sx={{ position: "absolute" }}>
          {percentage}%
        </Typography>
      </Box>
    </Stack>
  );
};
