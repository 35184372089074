import { Stack, Button, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import RowStack from "../../shared/ui/RowStack";


interface TitleAndMenuProps {
  refetch: () => void;
  AddButton?: React.ReactNode;
  objectCodeList?: any[];
  pdfDownLoadButton?: boolean;
  maxDownloadCount?: number;
  title: string;
  type?: "R" | "B" | null;
  menu?: React.ReactNode[];
}

const TitleAndMenu: React.FC<TitleAndMenuProps> = ({
  refetch,
  AddButton,
  title,
  objectCodeList = [],
  pdfDownLoadButton,
  type = null,
  maxDownloadCount = 50,
  menu = [],
}) => {

  return (
    <>
      <RowStack sx={{ px: 1 }}>
        <Typography fontSize={"22px"} fontWeight={"bold"} color="#333">
          {title}
        </Typography>

        <Stack direction="row" justifyContent={"center"} gap={2} sx={{ py: 3 }}>
          {menu?.map((Item: any, i: number) => Item)}
          <Button variant="outlined" sx={{ backgroundColor: "background.paper" }} size="small" startIcon={<RefreshIcon />} onClick={() => refetch()}>
            새로고침
          </Button>
          {AddButton}
        </Stack>
      </RowStack>
    </>
  );
};

export default TitleAndMenu;
