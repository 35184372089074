import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import DataGrid from "react-data-grid";
import { useRef, useState, useMemo, useEffect, useCallback } from "react";
import { QUERY_KEY } from "../../../config/querykey";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AutoMultiCell from "../../../shared/ui/grid-cell/AutoMultiCell";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocalStorage } from "../../../service/localStorage/useLocalStorage";
import _ from "lodash";
import { handleCopy, handlePaste } from "../../../shared/lib/dataGridTableCopyPaste";
import { autoRowHeight } from "../../../shared/lib/autoRowHeight";
import { useHiddenColumnsStore } from "../../../store/HiddenColumns";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import RefetchButton from "./RefetchButton";
import { AllInfoType, getBrandSalesSummaryResponseDto } from "../../../service/sales-summary/types";
import { invoiceExcelDownloader } from "../../customer-find-order/lib/invoiceExcelDownLoader";
import { useDateRangeStore } from "../../../store/DateRangeStore";
import { autoToLocaleString } from "../lib/AutoToLocaleString";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";

interface SortType {
  columnKey: string;
  direction: "ASC" | "DESC";
}

interface Props {
  brandName: string;
  brandCode: string;
  data: AllInfoType[];
}

//크기조절

const SALES_DATA_TABLE_COLUMNS = [80, 80, 80, 80, 120, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80];

const SalesDataTable: React.FC<Props> = ({ brandName, data, brandCode }) => {
  const [widthList, setWidthList] = useLocalStorage("SALES_DATA_TABLE_COLUMNS", SALES_DATA_TABLE_COLUMNS);

  const queryClient = useQueryClient();
  const selectedRowId = useRef<number>();
  const [filters, setFilters] = useState<string>("");
  const [sortType, setSortType] = useState<SortType>({ columnKey: "ReceiveDate", direction: "ASC" });
  const { hiddenColumns } = useHiddenColumnsStore((v) => v);
  const { getStartDateToString, getEndDateToString } = useDateRangeStore((v) => v);

  //   const [selected, setSelected] = useState<number[]>([]);

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
  };

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateCustomerInfo = async (newData: any) => {
    /**
     * newData : 새롭게 변경된 row
     * oldData : 변경되기 전 row
     */
    // const { Detail_chungdo, OrderCount: Oc, CancelCount: Cc, Detail01, Detail02, CustomerOrderID } = newData[selectedRowId.current || 0];
    return newData;
  };

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType((prev) => ({ ...prev, direction: prev.direction === "ASC" ? "DESC" : "ASC" }));
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //필터를 적용함
  const filteredRows = useMemo(() => {
    if (!data) return [];
    if (filters === "") return data;
    const lowerCaseFilter = filters.toLowerCase();
    return data.filter((row) => {
      return Object.values(row).some((value) => {
        const str = value ? value.toString().toLowerCase() : "";
        return str.includes(lowerCaseFilter);
      });
    });
  }, [data, filters]);

  useEffect(() => {
    handleSortData();
  }, [sortType]);

  const handleSortData = () => {
    const { columnKey, direction } = sortType;
    const sortFunc = (a: any, b: any) => {
      const a1 = a[columnKey] === null || a[columnKey] === "" ? null : a[columnKey];
      const b1 = b[columnKey] === null || b[columnKey] === "" ? null : b[columnKey];

      // 빈 값이 항상 마지막으로 정렬되도록 설정
      if (a1 === null && b1 === null) {
        return 0;
      }
      if (a1 === null) {
        return 1; // 빈 값은 항상 뒤로
      }
      if (b1 === null) {
        return -1; // 빈 값은 항상 뒤로
      }

      // 일반 값의 정렬
      if (a1 > b1) {
        return direction === "ASC" ? 1 : -1;
      } else if (a1 < b1) {
        return direction === "ASC" ? -1 : 1;
      }
      return 0;
    };

    queryClient.setQueryData(
      [QUERY_KEY.brandSalesSummary, getStartDateToString(), getEndDateToString(), brandCode],
      (oldData: getBrandSalesSummaryResponseDto | undefined) => {
        const newData = _.cloneDeep(oldData);
        if (newData && newData.AllInfo) {
          newData.AllInfo.sort(sortFunc);
          return newData;
        } else {
          return [];
        }
      }
    );
  };

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [
      {
        totalCount: data?.length || 0, // 총개수
        totalQuantity: data?.reduce((acc, v) => v.Quantity + acc, 0) || 0, // 발주수량
        totalQuantityOK: data?.reduce((acc, v) => v.Quantity_OK + acc, 0) || 0, // 확정수량
        totalQuotedPrice: data?.reduce((acc, v) => v.QuotedPrice + acc, 0) || 0, // 견적가
        totalSalePrice: data?.reduce((acc, v) => v.SalePrice + acc, 0) || 0, // 판매가
        totalChinaCost: data?.reduce((acc, v) => v.ChinaCost + acc, 0) || 0, // 중국원가
        totalLocalDeliveryFee: data?.reduce((acc, v) => v.LocalDeliveryFee + acc, 0) || 0, // 배송비
        totalKoreaCost: data?.reduce((acc, v) => v.KoreaCost + acc, 0) || 0, // 한국원가
        totalTotalCost: data?.reduce((acc, v) => v.TotalCost + acc, 0) || 0, // 총원가
        totalDeliveryPrice: data?.reduce((acc, v) => v.DeliveryPrice + acc, 0) || 0, // 납품가
        totalVAT: data?.reduce((acc, v) => v.VAT + acc, 0) || 0, // 부가세
        totalRocketMargin: data?.reduce((acc, v) => v.RocketMargin + acc, 0) || 0, // 로켓마진
        totalAccountingCost: data?.reduce((acc, v) => v.AccountingCost + acc, 0) || 0, // 부가세
        totalOurMargin: data?.reduce((acc, v) => v.OurMargin + acc, 0) || 0, // 자사마진
      },
    ];
  }, [data]);

  //컬럼 테이블 생성
  const columns: any[] = [
    {
      key: "ReceiveDate",
      name: "입고일",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return "총 : " + autoToLocaleString(row.totalCount);
      },
    },
    {
      key: "orderAlias",
      name: "발주서",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
    },
    {
      key: "orderID",
      name: "발주번호",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{row[column.key]}</AutoMultiCell>;
      },
    },
    {
      key: "BarCode",
      name: "바코드",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
    },
    {
      key: "ProductName",
      name: "상품명",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
    },
    {
      key: "Quantity",
      name: "발주수량",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalQuantity);
      },
    },
    {
      key: "Quantity_OK",
      name: "확정수량",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalQuantityOK);
      },
    },

    {
      key: "QuotedPrice",
      name: "견적가",
      renderCell: ({ row, column }: any) => {
        return (
          <AutoMultiCell>
            {typeof autoToLocaleString(row[column.key]) === "number"
              ? autoToLocaleString(row[column.key]).toLocaleString()
              : autoToLocaleString(row[column.key])}
          </AutoMultiCell>
        );
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalQuotedPrice);
      },
    },
    {
      key: "SalePrice",
      name: "판매가",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalSalePrice);
      },
    },
    {
      key: "ChinaCost",
      name: "중국원가",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalChinaCost);
      },
    },
    {
      key: "LocalDeliveryFee",
      name: "배송비",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalLocalDeliveryFee);
      },
    },
    {
      key: "KoreaCost",
      name: "한국원가",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalKoreaCost);
      },
    },
    {
      key: "TotalCost",
      name: "총원가",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalTotalCost);
      },
    },
    {
      key: "DeliveryPrice",
      name: "납품가",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalDeliveryPrice);
      },
    },
    {
      key: "VAT",
      name: "부가세",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalVAT);
      },
    },
    {
      key: "RocketMargin",
      name: "로켓마진",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalRocketMargin);
      },
    },
    {
      key: "AccountingCost",
      name: "회계원가",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalAccountingCost);
      },
    },
    {
      key: "OurMargin",
      name: "자사마진",
      renderCell: ({ row, column }: any) => {
        return <AutoMultiCell>{autoToLocaleString(row[column.key])}</AutoMultiCell>;
      },
      renderSummaryCell: ({ row }: any) => {
        return autoToLocaleString(row.totalOurMargin);
      },
    },
  ];

  const handleColumnResize = useCallback(
    _.throttle((index, width) => {
      setWidthList((prev: number[]) => {
        const newWidthList = [...prev];
        newWidthList[index] = width;
        return newWidthList;
      });
    }, 400),
    []
  );

  const handleExcelDownLoad = () => {
    const dataset = [];
    const keyset = columns.map((v) => v.key);
    dataset.push(columns.map((v) => v.name));
    data.forEach((v: any, i) => {
      dataset.push(
        keyset.map((key, i) => {
          return v[keyset[i]];
        })
      );
    });

    invoiceExcelDownloader(dataset, `[${getStartDateToString()}~${getEndDateToString()}] ${brandName} 매출집계.xlsx`);
  };

  return (
    <>
      <Card sx={{ mb: 5, mt: 10 }}>
        <Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }} gap={2}>
          <Button onClick={handleExcelDownLoad} variant="outlined" startIcon={<DownloadIcon />} size="large" sx={{ marginRight: "auto" }}>
            엑셀 다운로드
          </Button>
          <SearchInputField onChange={(e) => setFilters(e.target.value)} value={filters} />
          <HiddenColumnMenuButton columns={columns.map((v) => v.name)} />
          <RefetchButton refetch={() => {}} />
        </Stack>
      </Card>
      {data && (
        <DataGrid
          className="rdg-light"
          onSortColumnsChange={handleChangeSortType}
          style={{ height: "600px", paddingBottom: "20px", width: "1200px" }}
          rowHeight={(row) => autoRowHeight(row, 50)}
          onSelectedCellChange={handleSelectedRow}
          onRowsChange={handleUpdateCustomerInfo}
          columns={columns
            .map((column, i) => ({
              ...column,
              width: widthList[i],
            }))
            .filter((v) => !hiddenColumns.includes(v.name))}
          sortColumns={sortType ? [sortType] : []}
          rows={filteredRows}
          topSummaryRows={summaryRows} // 하단 요약 행 추가
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onColumnResize={handleColumnResize}
          onCopy={handleCopy}
          onPaste={handlePaste}
        />
      )}
    </>
  );
};
export default SalesDataTable;

interface HiddenColumnMenuButtonProps {
  columns: string[];
}

const HiddenColumnMenuButton: React.FC<HiddenColumnMenuButtonProps> = ({ columns }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hiddenColumns, setHiddenColumns } = useHiddenColumnsStore((v) => v);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHidden = (column: string) => {
    if (hiddenColumns.includes(column)) {
      setHiddenColumns(hiddenColumns.filter((v) => v !== column));
    } else {
      setHiddenColumns([...hiddenColumns, column]);
    }
  };

  return (
    <>
      <Tooltip title={<Typography variant="subtitle2">항목설정</Typography>} placement="top" arrow>
        <IconButton onClick={handleClick}>
          <MoreVertOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {columns.map((column) => (
          <MenuItem key={column}>
            <FormControlLabel
              sx={hiddenColumns.includes(column) ? { color: "grey", textDecoration: "line-through" } : {}}
              control={<Checkbox checked={!hiddenColumns.includes(column)} onChange={() => handleHidden(column)} />}
              label={column}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
