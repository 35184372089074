import { customAxios } from "../../config/axios-config";
import { stringToDate } from "../../shared/lib/convertStringToDate";
import {
  createStoreRequestDto,
  createStoreResponseDto,
  getStoreListResponseDto,
  getStoreRequestDto,
  getStoreResponseDto,
  updateStoreDto,
  updateStoreItemDto,
} from "./types";

export const storeApi = {
  getStore: async (data: getStoreRequestDto): Promise<getStoreResponseDto> => {
    return await customAxios.get(`/incoming_orders/${data}/`).then((res) => res.data);
  },
  getStoreList: async (): Promise<getStoreListResponseDto> => {
    return await customAxios.get(`/incoming_orders/`).then((res) => res.data.map((data: getStoreListResponseDto) => stringToDate(data)));
  },

  createStoreList: async (body: createStoreRequestDto[]): Promise<createStoreResponseDto[]> => {
    return await customAxios.post(`/incoming_orders/register_incoming_order/`, body).then((res) => res.data);
  },
  updateStore: async (data: updateStoreDto): Promise<updateStoreDto> => {
    const body = {
      //바꿀 변수 값 지정
      ExpectedDate: data.ExpectedDate,
      Status: data.Status,
    };
    return await customAxios.patch(`/incoming_orders/${data.IncomingOrderID}/`, data).then(() => data);
  },
  updateStoreItem: async (data: updateStoreItemDto): Promise<updateStoreItemDto> => {
    return await customAxios.patch(`/incoming_order_items/${data.IncomingOrderItemID}/`, data).then(() => data);
  },
};
