import { Dialog, DialogTitle, DialogContent, DialogContentText, Stack, DialogActions, Button, Typography, Grid, IconButton } from "@mui/material";
import React, { useMemo } from "react";
import { BrandIconType, brandIconOptions } from "../../const/brandIconsOption";
import { useBrandCodeStore } from "../../../../store/brandStore";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import RowStack from "../../../../shared/ui/RowStack";
interface ChangeBrandModalProps {
  open: boolean;
  handleSubmit: (data: string) => void;
  handleClose: () => void;
}

export const ChangeBrandModal: React.FC<ChangeBrandModalProps> = ({ open, handleClose }) => {
  const { BrandCode, setBrandCode } = useBrandCodeStore((state) => state);

  const location = useLocation();
  const navigate = useNavigate();
  const handleCloseAndSave = () => {
    if (location.pathname.startsWith("/individualorder/")) {
      navigate("/orderlist");
    }
    handleClose();
  };

  const handleChangeBrand = (code: string) => {
    setBrandCode(code);
    handleCloseAndSave();
  };

  const brandMap: Map<string, BrandIconType> = useMemo(() => {
    return new Map(brandIconOptions.map((item: BrandIconType) => [item.brandCode, item]));
  }, []);

  return (
    <>
      {" "}
      <Dialog open={open} onClose={handleCloseAndSave} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <RowStack>
            <Typography variant="subtitle1" color="#333">
              브랜드 선택
            </Typography>
            <IconButton onClick={handleCloseAndSave}>
              <CloseIcon />
            </IconButton>
          </RowStack>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle2">브랜드를 선택해 주세요.</Typography>
            <Typography variant="subtitle2">
              선택한 브랜드 :{" "}
              <Typography variant="subtitle2" color="primary" fontWeight="bold" sx={{ display: "inline-block" }}>
                {brandMap.get(BrandCode)?.brandName || "선택되지 않음"}
              </Typography>
            </Typography>
          </DialogContentText> */}
          <Grid container spacing={2} sx={{ my: 2, userSelect: "none" }}>
            {brandIconOptions.map((item: BrandIconType, i: number) => {
              return (
                <Grid item xs={4} sx={{ cursor: "pointer" }} onClick={() => handleChangeBrand(item.brandCode)}>
                  <Stack
                    direction="column"
                    gap={1}
                    key={i}
                    alignItems={"center"}
                    sx={{
                      p: 2,
                      boxShadow: BrandCode !== item.brandCode ? "0px 0px 10px #ccc" : "0px 0px 10px #0665cb",
                      borderRadius: "12px",
                      cursur: "pointer",
                    }}
                  >
                    <img src={item.imagePath} alt={item.brandName} width={80} draggable="false" />
                    <Typography sx={{ display: "inline-block" }}>{item.brandName}</Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
