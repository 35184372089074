import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type brandCodeType = {
  boxInfo: { [key: string]: string };
};

type BrandCodeActions = {
  setBoxInfo: (data: { type: string; boxName: string }) => void;
  clearBoxInfo: () => void;
  deleteBoxName: (key: string) => void;
};

export const useBoxNameStore = create(
  persist<brandCodeType & BrandCodeActions>(
    (set, get) => ({
      boxInfo: {},
      setBoxInfo: (data) => {
        const boxInfo = get().boxInfo;
        boxInfo[data.type] = data.boxName;
        set({
          boxInfo: { ...boxInfo },
        });
      },
      deleteBoxName: (key: string) => {
        const boxInfo = get().boxInfo;
        delete boxInfo[key];
        set({
          boxInfo: { ...boxInfo },
        });
      },
      clearBoxInfo: () => {
        set({
          boxInfo: {},
        });
      },
    }),
    {
      name: "ATTERLIC_BOX_INFO",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
