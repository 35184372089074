import * as React from "react";
import { FormControl, MenuItem, Pagination, PaginationItem, Select, Stack, Typography } from "@mui/material";
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { useState } from "react";

export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const totalRowCount = useGridSelector(apiRef, (state: any) => state.rows.totalRowCount);
  const rowCount = useGridSelector(apiRef, (state: any) => state.pagination.rowCount);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100); // 기본값은 25로 설정합니다.

  // 페이지 변경 이벤트
  const handleRowsPerPageChange = (event: any) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    apiRef.current.setPageSize(newRowsPerPage); // 페이지 사이즈를 변경합니다.
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{ p: 1, pr: 4, boxSizing: "border-box" }} gap={3}>
      <Pagination
        // color="primary"
        variant="text"
        // shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1)}
      />
      <Typography variant="body2">
        총 개수 : {parseInt(rowCount, 10).toLocaleString()} / {parseInt(totalRowCount, 10).toLocaleString()} 개
      </Typography>
      <Stack direction="row" alignItems="center">
        행 개수 : &nbsp;
        <FormControl size="small">
          <Select
            sx={{ height: "45px", fontSize: "14px" }}
            size="small"
            value={rowsPerPage}
            variant="outlined"
            onChange={handleRowsPerPageChange}
            displayEmpty
          >
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
}
