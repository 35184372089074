/**
 *  Cumulative sum of an array
 * 배열을 증가하는 합으로 만들어줌
 * @example cumulativeSum([1, 2, 3, 4, 5]) => [1, 3, 6, 10, 15]
 *
 * @param arr
 * @returns
 */
export function cumulativeSum(arr: number[]) {
  return arr.reduce((acc, value, index) => {
    if (index === 0) {
      acc.push(value);
    } else {
      acc.push(acc[index - 1] + value);
    }
    return acc;
  }, [] as number[]);
}
