import { Divider, Stack, Typography } from "@mui/material";
import { CardContainer } from "../style/style";
import { searchProductResponseDto } from "../../../service/total-order/types";
import { ItemResponse } from "../../../service/order/types";
import { DescriptionItem } from "../../../shared/ui/description-item/DescriptionItem";

interface ProductInfoProps {
  data: searchProductResponseDto | ItemResponse;
  BarCode?: string;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ data, BarCode = "" }) => {
  const { ProductName, Color, Size, BarCode: barCode } = data;
  return (
    <CardContainer>
      {data ? (
        <Stack>
          <Typography fontSize={"16px"} fontWeight="bold" sx={{ mb: 2, whiteSpace: "normal", wordBreak: "keep-all", overflowWrap: "break-word" }}>
            {ProductName}
          </Typography>
          <Divider sx={{ my: 2 }} />
          {/* 바코드 */}
          <DescriptionItem label={"바코드 :"} value={barCode ? barCode : BarCode} />
          {/* 사이즈 */}
          <DescriptionItem label={"사이즈 :"} value={Size} />
          {/* 색상 */}
          <DescriptionItem label={"색상 :"} value={Color} />
        </Stack>
      ) : (
        <Typography sx={{ color: "#666", lineHeight: "120px", textAlign: "center" }}>등록된 제품이 아닙니다.</Typography>
      )}
    </CardContainer>
  );
};

export default ProductInfo;
