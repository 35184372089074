import { Stack, Typography } from "@mui/material";

export const SUBTITLE_COLOT = "#999";
export const MAIN_CONTENT_COLOR = "#333";

interface LabelWithContent {
  label: string;
  content: string | number | null;
  labelColor?: string;
  contentColor?: string;
  sx?: any;
  contentSx?: any;
}
const LabelWithContent: React.FC<LabelWithContent> = ({ label, content, labelColor, contentColor, sx, contentSx }) => {
  return (
    <>
      <Stack sx={sx || {}} direction="row" justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="caption" color={labelColor || SUBTITLE_COLOT}>
          {label}
        </Typography>
        <Typography variant="h6" color={contentColor || MAIN_CONTENT_COLOR} sx={contentSx || {}}>
          {content || "-"}
        </Typography>
      </Stack>
    </>
  );
};

export default LabelWithContent;
