import React from "react";
import { Button, Card, Stack, TextField, Tooltip, Typography } from "@mui/material";
import "react-data-grid/lib/styles.css";
import { customAxios } from "../config/axios-config";
import { customToast } from "../shared/lib/customToast";
import RowStack from "../shared/ui/RowStack";
import { useCreateBasket } from "../service/basket/BasketHooks";

// export interface createBasketDto {
//   BasketID: string;
//   IsCoupang: boolean;
//   CoupangBaskID: string;
// }

const Test: React.FC = () => {
  // const { mutateAsync: updateProductBulk } = useUpdateProductBulk();
  // const { mutate: moveToBasket } = useMoveTableToBasket();
  const { mutateAsync } = useCreateBasket();

  const [old, setOld] = React.useState<string>("");
  const [newBarCode, setNewBarCode] = React.useState<string>("");

  const handleAddBasekt = async () => {
    const success = [];
    const error = [];
    for (let i = 621901; i <= 622000; i++) {
      const body = {
        BasketID: i.toString(),
        IsCoupang: false,
        CoupangBaskID: "",
      };

      try {
        await mutateAsync(body);
        success.push(i);
      } catch {
        error.push(i);
      }
    }

    console.log({ success, error });
  };

  const handleSubmit = async () => {
    if (old === "" || newBarCode === "") {
      customToast("info", "값을 넣으세요");
    } else {
      const body = {
        old_barcode: old,
        new_barcode: newBarCode,
      };

      customAxios
        .post("/products/update_barcode/", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlebulkSubmit = async () => {
    let error: any[] = [];

    // for (let item of list) {
    //   const body = {
    //     old_barcode: item,
    //     new_barcode: item.replace("T", ""),
    //   };

    //   console.log(body);
    //   await customAxios
    //     .post("/products/update_barcode/", body)
    //     .then((res) => {
    //       customToast("success", "성공");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       error.push(item);
    //       customToast("error", "실패");
    //     });
    // }

    console.log(error);
  };

  const handleCustomerOrder = async () => {
    customAxios.get("/customer_order/").then((res) =>
      //console.log(res.data.length, "/",res.data.filter((item: any) => !item.StatusMeno ).length)
      console.log(res.data)
    );
  };

  customAxios.get("find_items/getList/").then((res) => {
    console.log(res.data);
  });

  return (
    <Card sx={{ mt: 10, width: "400xp", margin: "100px auto" }}>
      <Stack gap={5} alignItems={"center"}>
        {/* <UploadChangeBrandButton sx={{ width: "200px", backgroundColor: "white", height: "46px", borderRadius: "0px" }} /> */}
        {/* <DateRangeButton /> */}

        <Stack gap={3}>
          <TextField value={old} onChange={(e) => setOld(e.target.value)} label="old" size="small" /> to
          <TextField value={newBarCode} onChange={(e) => setNewBarCode(e.target.value)} label="new" size="small" />
          <Button variant="contained" onClick={handleSubmit} size="large" disabled>
            클릭
          </Button>
          <Button variant="contained" onClick={handlebulkSubmit} size="large" disabled>
            줄줄이 업데이트
          </Button>
          <Tooltip
            placement="top"
            title={
              <RowStack gap={2}>
                <Button variant="outlined">복사</Button>
                <Button variant="outlined">프린트</Button>
              </RowStack>
            }
            arrow
          >
            <Typography sx={{}}>PA001</Typography>
          </Tooltip>
        </Stack>

        <Button onClick={handleCustomerOrder}>불ㄹ러불뤄</Button>
        <Button onClick={handleAddBasekt}>바구니 추가 </Button>
      </Stack>
    </Card>
  );
};

export default Test;
