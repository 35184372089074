import html2canvas from "html2canvas";
import { useRef } from "react";
import { customToast } from "../../shared/lib/customToast";

export const useCopyToClipboard = () => {
  const copyRef = useRef<HTMLDivElement>(null);

  const onCopy = () => {
    if (copyRef.current === null) return;
    html2canvas(copyRef.current).then((canvas) => {
      canvas.toBlob((blob: any) => {
        var item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]).then(() => {
          customToast("info","복사되었습니다.");
        });
      });
    });
  };

  return { copyRef, onCopy };
};
