import React from "react";
import { useModals } from "../../../../service/modal/useModals";
import { getCustomerOrderListResponseDto } from "../../../../service/customer-find-order/types";
import { ShowOrderPeopleModal } from "./ShowOrderPeopleModal";
import AutoMultiCell from "../../../../shared/ui/grid-cell/AutoMultiCell";
import { Box } from "@mui/material";

interface Props {
  data: getCustomerOrderListResponseDto;
  isError?: boolean;
}

const ShowOderPeopleButton: React.FC<Props> = ({ data, isError = false }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowOrderPeopleModal);
  };

  const handleClickOpen = () => {
    openModal(ShowOrderPeopleModal, { data, handleClose });
  };

  return (
    <React.Fragment>
      <AutoMultiCell>
        <Box
          sx={{
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
            backgroundColor: "transparent",
            color: isError ? "red" : "#0d80d7",
            border: "0px solid black",
          }}
          onClick={handleClickOpen}
          // onMouseEnter={handleClickOpen}
        >
          {data.OrderNumber_idx}
        </Box>
      </AutoMultiCell>
    </React.Fragment>
  );
};

export default ShowOderPeopleButton;
