import styled from "@emotion/styled";

const AutoMultiCell = styled.div`
  white-space: normal;
  word-break: keep-all;
  overflow-wrap: break-word;
  width: 100%;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export default AutoMultiCell;
