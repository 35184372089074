import { Dialog, DialogTitle, DialogContent, Stack, Typography, TextField, DialogActions, Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { QUERY_KEY } from "../../../config/querykey";
import { useUpdateProductWithRack } from "../../../service/product/productHooks";
import { rackApi } from "../../../service/rack/rackAxios";
import { customToast } from "../../../shared/lib/customToast";

interface AddBasketInProductProps {
  open: boolean;
  BarCode: string;
  handleClose: () => void;
}

export const AddBasketInProduct: React.FC<AddBasketInProductProps> = ({ open, BarCode, handleClose }) => {
  const queryClient = useQueryClient();
  const [value, setValue] = useState<string>("");
  const [count, setCount] = useState<string>("");
  const { mutate: updateProductWithRack } = useUpdateProductWithRack(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.productList] });
  });
  useEffect(() => {
    setValue("");
    setCount("");
  }, []);

  const handleSubmit = () => {
    if (value === "" || value.length < 4) {
      alert("랙번호를 4자리 이상 입력해주세요.");
    } else {
      rackApi
        .getRack({ RackID: value })
        .then((res) => {
          updateProductWithRack({ BarCode, to: value, from: "table", Type: "TB", count: parseInt(count) });
          handleClose();
        })
        .catch((err) => {
          customToast("warn", "바구니를 찾을 수 없습니다.");
        });
    }
  };

  /**
   * 1. 랙 조회하기
   * 2. 오류나면 알람창 띄우기
   * 3. 오류 안나면 진행하기
   */
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>바구니 추가</DialogTitle>
      <DialogContent sx={{ width: "300px" }}>
        <Stack gap={4}>
          <Typography variant="caption">바구니 번호를 입력해주세요.</Typography>
          <TextField fullWidth value={value} onChange={(e) => setValue(e.target.value)} label="바구니 번호" />
          <TextField
            fullWidth
            value={count}
            label="개수"
            onChange={(e) =>
              setCount((pre: any) => {
                const v = e.target.value;

                if (v === "") {
                  return 0;
                }
                if (isNaN(parseInt(v))) {
                  return pre;
                }
                if (parseInt(v) < 0) {
                  customToast("warn", "0개 이하는 추가할 수 없습니다.");
                  return 0;
                }
                if (parseInt(v) > 2000) {
                  customToast("warn", "2000개 이상은 추가할 수 없습니다.");
                  return 2000;
                }
                return v;
              })
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" disabled={value === "" || value.length < 4 || !count}>
          등록
        </Button>
        <Button onClick={handleClose} variant="outlined">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};
