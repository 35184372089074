import { Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions, IconButton, Tooltip, Stack } from "@mui/material";
import ProductListInRackTable from "./ProductListInRackTable";
import { useGetRack } from "../../service/rack/rackHooks";
import RefreshIcon from "@mui/icons-material/Refresh";
import RowStack from "../../shared/ui/RowStack";
interface ProductListInRackModalProps {
  open: boolean;
  code: string;
  brand: string;
  handleClose: () => void;
}
const ProductListInRackModal: React.FC<ProductListInRackModalProps> = ({ open, code, brand, handleClose }) => {
  const { data, refetch, isFetching } = useGetRack({ RackID: code });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px", // Set your width here
              height: "570px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <RowStack>
            <Stack sx={{ p: 2 }}>
              랙 번호 [{code}]
              <Typography variant="body2" color="grey">
                현재 적재된 물품 목록입니다.
              </Typography>
            </Stack>
            {isFetching && <Typography sx={{ml:"auto"}}variant="caption">로딩중.. </Typography>}
            <Tooltip title="새로고침">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </RowStack>
        </DialogTitle>

        <DialogContent>
          {data?.inventory.length === 0 ? (
            <Typography textAlign={"center"} variant="subtitle1">
              상품이 없습니다.{" "}
            </Typography>
          ) : (
            <ProductListInRackTable data={data?.inventory || []} />
          )}
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductListInRackModal;
