import React, { useState } from "react";
import { Dialog, List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText, FormControlLabel, Radio } from "@mui/material";
import { useUpdateProduct } from "../../../service/product/productHooks";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../config/querykey";
import { useBrandCodeStore } from "../../../store/brandStore";
import { brandOptions } from "../../../entities/side-navigation/const/brandIconsOption";

interface BrandEditCellProps {
  row: any;
  api: any;
  field: string;
  id: number;
  status: string;
}

const BrandEditCell: React.FC<BrandEditCellProps> = ({ row, api, field, id, status }) => {
  const { BarCode, Brand } = row;
  const queryClinet = useQueryClient();
  const BrandCode = useBrandCodeStore((v) => v.BrandCode);
  const [open, setOpen] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(Brand);
  const { mutate: updateProduct } = useUpdateProduct((data: any) => {
    queryClinet.setQueryData([QUERY_KEY.product, status, BrandCode], (oldData: any) => {
      return oldData ? oldData.map((item: any) => (item.BarCode === data.BarCode ? { ...item, ...data } : item)) : [];
    });
  });

  const handleToggle = (brand: string) => () => {
    setSelectedBrand(brand);
  };

  const handleClose = () => {
    api.setEditCellValue({ id, field, value: selectedBrand });
    api.stopCellEditMode({ id, field });
    updateProduct({ BarCode, Brand: selectedBrand });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <List disablePadding>
        {brandOptions.map((brand) => {
          return (
            <ListItem key={brand.value} disablePadding sx={{ py: 2, px:4 }}>
              <FormControlLabel
                value={brand.value}
                control={<Radio checked={brand.value === selectedBrand} onClick={handleToggle(brand.value)} />}
                label={brand.label}
              />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

export default BrandEditCell;
