/**
 * @param columns
 * @param widths
 * @returns
 */
export const setColumnWidths = (columns: any[], widths: number[]) => {
  let currentIndex = 0;

  const assignWidths = (cols: any[]) => {
    return cols.map((column: any) => {
      let newColumn = { ...column };

      if (!newColumn.children) {
        if (widths[currentIndex] !== undefined) {
          newColumn.width = widths[currentIndex];
        }
        currentIndex += 1; // 다음 말단 컬럼의 인덱스를 증가시킴
      } else {
        newColumn.children = assignWidths(newColumn.children);
      }

      return newColumn;
    });
  };

  return assignWidths(columns);
};
