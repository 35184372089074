import * as React from "react";
import { storeItemResponse, updateStoreDto, updateStoreItemDto } from "../../../service/store/types";
import "react-data-grid/lib/styles.css";
import { Button, FormControl, InputLabel, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import DataGrid, { RenderEditCellProps, Column, Row, textEditor } from "react-data-grid";
import { useLocation } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { FilterStateType } from "../../total-order/type/types";
import { useGetStore, useUpdateStore, useUpdateStoreItem } from "../../../service/store/storeHooks";
import RefreshButton from "../../../shared/ui/buttom/RefreshButton";
import { useMemo } from "react";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem";
import SubjectIcon from "@mui/icons-material/Subject";
import { CenterCellRander } from "../../total-order/style/style";
import { customToast } from "../../../shared/lib/customToast";
import { QUERY_KEY } from "../../../config/querykey";
import { useQueryClient } from "@tanstack/react-query";
import { getOrderResponseDto } from "../../../service/order/types";
import { SortType } from "../../../shared/type/SortType";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import RowStack from "../../../shared/ui/RowStack";

interface Row extends storeItemResponse {
  [Index: string]: any;
}

interface CustomColumn extends Column<Row> {
  children?: CustomColumn[];
  editable?: boolean;
  editor?: React.ComponentType<RenderEditCellProps<Row, unknown>>;
  renderCell?: (params: { row: Row }) => JSX.Element;
  renderEditCell?: (props: RenderEditCellProps<Row, unknown>) => JSX.Element;
  headerCellClass?: string;
  width?: number;
}

export default function IndividualStoreTable() {
  const location = useLocation();
  const ID = location.state.id;
  const { mutate: updateStore } = useUpdateStore();
  const { mutate: updateStoreItem } = useUpdateStoreItem();
  const { data: response, refetch, isFetching } = useGetStore(ID);
  const [filterState, setFilterState] = useState<FilterStateType>("all");
  const [dfilter, setDfilter] = useState<string>("");
  const [sortType, setSortType] = useState<SortType>({ columnKey: "ProductName", direction: "ASC" });
  const [status, setStatus] = useState<string>();
  const selectedRowId = useRef<number>();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (response?.Status) {
      setStatus(response.Status);
    }
  }, [response]);

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType((prev) => ({ ...prev, direction: prev.direction === "ASC" ? "DESC" : "ASC" }));
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //필터를 적용함
  const filteredRows = useMemo(() => {
    if (!response?.items) return [];
    return response?.items.filter((data) => {
      return Object.values(data).some((value) => {
        return String(value).toLowerCase().includes(dfilter.toLowerCase());
      });
    });
  }, [response?.items, dfilter, filterState]);

  const handleSortData = () => {
    const { columnKey, direction } = sortType;
    const sortFunc = (a: any, b: any) => {
      const a1 = a[columnKey] === null ? "" : a[columnKey];
      const b1 = b[columnKey] === null ? "" : b[columnKey];
      if (a1 > b1) {
        return direction === "ASC" ? 1 : -1;
      } else if (a1 < b1) {
        return direction === "ASC" ? -1 : 1;
      }
      return 0;
    };

    queryClient.setQueryData([QUERY_KEY.store, ID], (oldData: getOrderResponseDto[] | undefined) => {
      return oldData ? [...oldData].sort(sortFunc) : [];
    });
  };

  useEffect(() => {
    handleSortData();
  }, [sortType]);

  const renderString = (Alias: string | undefined) => <div>{"입고서 : " + Alias}</div>;

  const columns: CustomColumn[] = [
    {
      key: "입고서 이름",
      name: renderString(response?.Alias),
      //name: response?.Alias ? response?.Alias : " ",
      headerCellClass: "blue-header1",

      children: [
        {
          key: "BarCode",
          name: "바코드",
          headerCellClass: "blue-header2",
          width: 150,
        },
        {
          key: "ProductName",
          name: "상품명",
          headerCellClass: "blue-header2",
          width: 370,
        },
        {
          key: "Color",
          name: "컬러",
          headerCellClass: "blue-header2",
          renderCell: (p) => {
            return <CenterCellRander>{p.row.Color}</CenterCellRander>;
          },
        },
        {
          key: "Size",
          name: "Size",
          headerCellClass: "blue-header2",
          renderCell: (p) => {
            return <CenterCellRander>{p.row.Size}</CenterCellRander>;
          },
        },

        {
          key: "BoxNumber",
          headerCellClass: "blue-header2",
          name: "박스번호",
          renderCell: (p) => {
            return <CenterCellRander>{p.row.BoxNumber}</CenterCellRander>;
          },
        },
        {
          key: "Quantity",
          headerCellClass: "blue-header2",
          name: "입고수량",
          renderCell: (p) => {
            return <CenterCellRander>{p.row.Quantity}</CenterCellRander>;
          },
        },

        {
          key: "CoupangDate",
          name: "쿠팡납품일자",
          headerCellClass: "blue-header2",
          renderEditCell: textEditor,
          renderCell: (p) => {
            return <CenterCellRander>{p.row.CoupangDate}</CenterCellRander>;
          },
        },
      ],
    },
  ];

  //뒤로가기 버튼 함수
  const handleBack = () => {
    history.back();
  };

  //상태 변경 함수
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
    const newStatus = event.target.value as string;
    if (response) {
      const data: updateStoreDto = {
        //IncomingOrderItemID: response.items,
        IncomingOrderID: response.IncomingOrderID,
        Status: newStatus,
        ExpectedDate: response.ExpectedDate,
      };

      updateStore(data, {
        onSuccess: () => {
          customToast("success", "입고서 상태를 변경하였습니다.");
          refetch();
        },
      });
    }
  };

  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
  };

  const handleUpdateCustomerInfo = async (newData: any, oldData: any) => {
    if (selectedRowId.current == undefined) {
      return oldData;
    }
    const itemInfo: updateStoreItemDto = newData[selectedRowId.current];
    const { CoupangDate, IncomingOrderItemID } = itemInfo;
    if (itemInfo) {
      updateStoreItem({ CoupangDate, IncomingOrderItemID, IncomingOrderID: ID });
      return newData;
    } else {
      return oldData;
    }
  };

  return (
    <>
      <BackDropLoading open={isFetching} />
      <CustomTableContainer>
        <Stack gap={2} sx={{ mt: 3 }}>
          <RowStack>
            <Stack direction="row">
              <SubjectIcon />
              <Typography fontWeight="bold" fontSize="22px" sx={{ mb: 5 }}>
                개별입고현황
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent={"end"} gap={2} sx={{ mb: 2 }}>
              <Button variant="outlined" startIcon={<ReplyIcon />} onClick={handleBack} style={{ backgroundColor: "white" }}>
                뒤로가기
              </Button>
              <RefreshButton
                onClick={() => {
                  refetch();
                }}
              />

              <FormControl sx={{ minWidth: 110, height: 40, backgroundColor: "white" }} size="small">
                <InputLabel id="demo-simple-select-label" sx={{ color: "#1976d2", right: "5px" }}>
                  입고상태
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{ color: "#1976d2" }}
                  value={status ? status : ""}
                  label="status"
                  onChange={handleStatusChange}
                >
                  <MenuItem value={"E"}>완료</MenuItem>
                  <MenuItem value={"R"}>준비</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </RowStack>
        </Stack>

        <Stack gap={5}>
          <DataGrid
            onSortColumnsChange={handleChangeSortType}
            className={" rdg-light"}
            style={{ height: "85vh" }}
            rowHeight={35}
            columns={columns}
            sortColumns={sortType ? [sortType] : []}
            rows={filteredRows}
            onRowsChange={handleUpdateCustomerInfo}
            onSelectedCellChange={handleSelectedRow}
            defaultColumnOptions={{
              resizable: true,
              sortable: true,
            }}
          />
        </Stack>
      </CustomTableContainer>
    </>
  );
}
