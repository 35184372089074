import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

//column의 사이즈를 저장하고 가져오는 store

type columnType = {
  columns: { [key: string]: { [key: string]: number } };
};

type columnActions = {
  getColumns: () => { [key: string]: number };
  setColumns: (field: string, width: number) => void;
};

export const useColumnStore = create(
  persist<columnType & columnActions>(
    (set, get) => ({
      columns: {},
      setColumns: (field: string, width: number) => {
        const page = window.location.pathname.split("/")[1] || "defaultPage";
        set((state) => ({
          columns: {
            ...state.columns,
            [page]: {
              ...(state.columns[page] || {}),
              [field]: width,
            },
          },
        }));
      },
      getColumns: () => {
        const page = window.location.pathname.split("/")[1] || "defaultPage";
        return get().columns[page] || {};
      },
    }),
    {
      name: "PAPERLIFE_COLUMNS",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
