import styled from "@emotion/styled";

export const CustomButton = styled.div`
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: 0px solid black;
  color: #0d80d7;
`;
