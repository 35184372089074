import { Button, Menu } from "@mui/material";
import { ko } from "date-fns/locale";
import { useState } from "react";
import { DateRange } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useDateRangeStore } from "../../../store/DateRangeStore";

interface DataRageProps {
  size?: "small" | "medium" | "large";
  sx?: any;
}

const DateRangeButton: React.FC<DataRageProps> = ({ size, sx = {} }) => {
  const { term, setTerm } = useDateRangeStore((v) => v);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  const open = Boolean(anchorEl);
  const handleCloseTermForm = () => {
    setAnchorEl(null);
  };
  const handleOpenTermForm = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDateSelect = (e: any) => {
    setTerm(e.selection);
  };

  return (
    <>
      <Button onClick={handleOpenTermForm} variant="outlined" endIcon={<CalendarMonthIcon />} size={size || "medium"} sx={sx}>
        기간 : {formatDate(term.startDate, "yyyy-MM-dd")} ~ {formatDate(term.endDate, "yyyy-MM-dd")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseTermForm}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <DateRange dateDisplayFormat={"yyyy / MM / dd"} editableDateInputs={false} locale={ko} ranges={[term]} onChange={handleDateSelect} />
      </Menu>
    </>
  );
};

export default DateRangeButton;

function formatDate(date: Date, format: string) {
  const map: { [key: string]: any } = {
    yyyy: date.getFullYear(),
    MM: ("0" + (date.getMonth() + 1)).slice(-2),
    dd: ("0" + date.getDate()).slice(-2),
    HH: ("0" + date.getHours()).slice(-2),
    mm: ("0" + date.getMinutes()).slice(-2),
    ss: ("0" + date.getSeconds()).slice(-2),
  };

  return format.replace(/yyyy|MM|dd|HH|mm|ss/gi, (matched) => map[matched]);
}
