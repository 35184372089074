import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Tooltip } from "@mui/material";

interface RefreshButtonProps {
  onClick: () => void;
  isFetching?: boolean;
}

const RefreshIconButton: React.FC<RefreshButtonProps> = ({ onClick, isFetching = false }) => {
  return (
    <Tooltip title={<p>새로고침</p>} placement="top" arrow>
      <IconButton onClick={onClick}>
        <RefreshIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};

export default RefreshIconButton;
