import { customAxios } from "../../config/axios-config";
import { dateToString, stringToDate } from "../../shared/lib/convertStringToDate";
import {LoginRequestDto, LoginResponseDto, createUserDto, deleteUserDto, getUserResponseDto, updateUserDto } from "./types";

export const userApi = {
  async login(body: LoginRequestDto): Promise<LoginResponseDto> {
    return await customAxios.post(`/users/login/`, body).then((res) => ({ ...body, ...res.data }));
  },
  async logout() {
    return await customAxios.post(`/users/logout/`).then((res) => res);
  },
  async createUser(body: createUserDto): Promise<createUserDto> {
    return await customAxios.post("/users/", dateToString(body)).then(() => stringToDate<createUserDto>(body));
  },
  async getUserList(): Promise<getUserResponseDto[]> {
    return await customAxios.get("/users/").then((res) => res.data.map((data: getUserResponseDto) => stringToDate<getUserResponseDto>(data)));
  },
  async deleteUser(data: deleteUserDto): Promise<deleteUserDto> {
    return await customAxios.delete(`/users/${data.UserID}/`).then(() => data);
  },
  async updateUser(data: updateUserDto): Promise<updateUserDto> {
    const body = { ExpireTime: data.ExpireTime };
    // return await customAxios.patch(`/users/${data.UserID}/`, body).then(() => data);
    return await customAxios.patch(`/users/${data.UserID}/`, dateToString(body)).then(() => stringToDate<updateUserDto>(data));
  },
};
