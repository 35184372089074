import NewTargetFindTable from "../entities/target-find/ui/NewTargetFindTable";

const TargetFind = () => {
  return (
    <>
      <NewTargetFindTable />
    </>
  );
};

export default TargetFind;
