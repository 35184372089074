import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getInventoryByProductIdRequestDto, getInventoryByProductIdResponseDto } from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { inventoryApi } from "./inventoryAxios";

export const useGetInventoryByProductId = (data: getInventoryByProductIdRequestDto) => {
  const queryClient = useQueryClient()

  return useQuery<getInventoryByProductIdResponseDto[], Error>({
    queryKey: [QUERY_KEY.inventory, data.BarCode],
    queryFn: () => inventoryApi.getInventoryByProductId(data),
  });
};
