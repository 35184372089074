import { useQuery } from "@tanstack/react-query";
import { getRocketFindListRequestDto, getRocketFindListResponseDto } from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { rocketApi } from "./rocketAxios";

export const useGetRocketFind = (data: getRocketFindListRequestDto) => {
  return useQuery<getRocketFindListResponseDto[], Error>({
    queryKey: [QUERY_KEY.rocketFind, data.brand],
    queryFn: () => rocketApi.getRocketFindList(data),
    select: (data) => data.filter((v) => v.RackID !== null),
  });
};
