
export const dataGridStyle = {
  backgroundColor: "background.paper",
  py: 1,
  boxSizing: "border-box",

};

export const otherToolbar = {
  csvOptions: { disableToolbarButton: true },
  printOptions: { disableToolbarButton: true },
  showQuickFilter: true,
  quickFilterProps: { debounceMs: 500 },
};

//초기에 보여질 컬럼 설정
export const initialState = {
  columns: {
    columnVisibilityModel: {
      // recentTime: false,
      registerTime: false,
    },
  },
};
