import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack, Button, Typography } from "@mui/material";
import Barcode from "react-barcode";
import { useHtmlToImage } from "../../../service/html-to-image/userHtmlToImage";
import { useCopyToClipboard } from "../../../service/copy-to-clipboard/useCopyToClipboard";
import DownloadIcon from "@mui/icons-material/Download";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

interface BarCodeModalProps {
  value: string;
  open: boolean;
  handleClose: () => void;
}

export const BarCodeModal: React.FC<BarCodeModalProps> = ({ open, value, handleClose }) => {
  const { htmlRef, onDownLoad } = useHtmlToImage();
  const { copyRef, onCopy } = useCopyToClipboard();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" ref={htmlRef}>
          <Stack alignItems="center" sx={{ p: 3 }} ref={copyRef}>
            <Barcode value={value} displayValue={false} />
            <Typography variant="subtitle1" sx={{ fontSize: "18px", p: 1 }}>
              {value}
            </Typography>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Stack sx={{ width: "100%" }} gap={1}>
          <Stack direction={"row"} gap={1}>
            <Button onClick={() => onDownLoad(value)} autoFocus variant="contained" startIcon={<DownloadIcon />} sx={{ minWidth: "120px" }} fullWidth>
              다운로드
            </Button>
            <Button onClick={onCopy} variant="contained" fullWidth startIcon={<FileCopyOutlinedIcon />} sx={{ minWidth: "120px" }}>
              복사
            </Button>
          </Stack>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            닫기
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
