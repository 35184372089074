export const SUBTITLE_COLOT = "#999";
export const MAIN_CONTENT_COLOR = "#333";
export const NO_IMAGE_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXt7e2+vr67u7vs7OzKysrw8PDFxcXZ2dnn5+fS0tLGxsbBwcHh4eHi4uLX19e/v7/Ozs5I6RZjAAAESUlEQVR4nO2biXKjOhBFUWvfDP//tU9qhG0IeJJ5qQIx91QF21K7SicSWvEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwzyLdHmeX6hfJkXYQXp9dsF/DiF3oNrXo6MDwLpWoHweGIt7FMB4ICpHPLtvvcNRIb9NMjxupEObswv0f9IIbDwUF2WfY2eX9Mck3pmO/qqiWOCu7knTmNa7/gVeg7UjRfdY5ynx0o6j35zBEwduU0kONB5LdzHF2hwcSDzdwJWktk9kN6WbwsDvFJz+8F1+n92nAEh86MdQ7hmPaFF5L9YwyKav5Tb+G0e2UfVrCsh5k74a5Fb2O7K/BPbTc2sHErg2pjXTaeTOOcVparGxxk24zu24Nlw7Et6GQjFsFkojUdSuleY301rU8l02bdVWvhnO5tVqlci1ulx2dGrbJWFrLKE7cTO96NeQWuZ3JtV2odWqvhrImbmdyc83q9dqqU8PIiWk7RnIz1X6V3KnhvFPxZRYQ7mN4/zoUnPjlPvT3uQ/nXlNvqpASx4ZVYq+GlhO3gzuHbvbhOjVsg7tcDX1tJpduMadZBvf3+mrzHK3ELQzL6ojT3bI5Q8tyKtNNDFuTLBmGl0q+bf5+ObLp1lBEuWS5nN2yJbUZDLs2JCW/E9ax4Z7i3pFUx4aloeZV6bVUO0FdG5ZqfN2AWu6fKfZtWDegfHZOOmeV2I/oxXB3V392JBpHcXzi1o3hdiD/LjT1Yvjh4YvPhv08mvF3ldjP4Rqfnf3YkXoSrMOdndTP8LknwYr+KWcXGAAAAAAA3AYpX9fyIuUqQ74yVq+rlPdvXQ8Z+Twi8smZr8/Kxvb4kykZJbcu4nXJqDvEeiKajxZt/c1QtBzGX7quYj1ektUz8W6UMXF5KDZUQ8PnwXUDwMh2FlUX9Y5ELJH1/CaUdyaqcy0+UQpdlrGz4UTTIN3zIHQ2HEXWTkQ2dBQDn9BYMk6WL5X3JWy4diut1SK5lWpVl+xybUi+vDzKtRpaUg9SbBhKxBTSHHauwh8ohpH8B8NEQYdyLYalkh+Ob7nZkJfAgaac0qXrkGy588yx4SSyUJnrsPwfNNXsYqitCabWIT/Mf+Edt+rwoOmDYSJFlg1r/ZWg0iqroSd+CDUIo5S68BP71WEY+TmLA0NZTFxtpaWvjd4HUrXXDaVPMq2nufaGTXWoh2Yvw3FjWO5Co9lQcYMUUbKh1mExPNnhM1yHgxHzaGFysss29tNQOjcbEnlra375OKacuLbnnubi92FtdbWwaf4BUPv5nWmG/CGVWzCTcLrWHI+fy0wmXH1OM0wqL1ed6pawb4X1Ki25w5DVNNjyV7DKl7Hf10jHYbw3fFLpv4PWr+vwvs3Lb1afnkHvkdgbBgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/Cv8BZ+gm25QuNoUAAAAASUVORK5CYII=";

export const ALERT_ICON_LIST = [
  "https://cdn-icons-png.flaticon.com/128/6897/6897039.png", // warning
  "https://cdn-icons-png.flaticon.com/128/7572/7572539.png", //매가폰
  "https://cdn-icons-png.flaticon.com/128/1867/1867682.png", //의류
  "https://cdn-icons-png.flaticon.com/128/3460/3460473.png", //강아지 고양이
  "https://cdn-icons-png.flaticon.com/128/3199/3199914.png", //신발
  "https://cdn-icons-png.flaticon.com/128/9338/9338581.png", //악세서리
  "https://cdn-icons-png.flaticon.com/128/685/685388.png", //포장 상자
  "https://cdn-icons-png.flaticon.com/128/15271/15271679.png", //주방용품
];
