

/**
 * addazle/react-data-grid에서 사용하는 sort 함수
 * @param a 
 * @param b 
 * @param sortType 
 * @returns 
 */
export const tableSortFunc = (a: any, b: any, sortType: any) => {
  const { columnKey, direction } = sortType;
  const a1 = a[columnKey] === null || a[columnKey] === "" ? null : a[columnKey];
  const b1 = b[columnKey] === null || b[columnKey] === "" ? null : b[columnKey];

  // 빈 값이 항상 마지막으로 정렬되도록 설정
  if (a1 === null && b1 === null) {
    return 0;
  }
  if (a1 === null) {
    return 1; // 빈 값은 항상 뒤로
  }
  if (b1 === null) {
    return -1; // 빈 값은 항상 뒤로
  }

  // 일반 값의 정렬
  if (a1 > b1) {
    return direction === "ASC" ? 1 : -1;
  } else if (a1 < b1) {
    return direction === "ASC" ? -1 : 1;
  }
  return 0;
};
