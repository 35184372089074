
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Card, Stack, Typography } from "@mui/material";

interface AlertboxProps {
  list: string[];
}

export const Alertbox: React.FC<AlertboxProps> = ({ list }) => {
  return (
    <Card sx={{ position: "fixed", right: 50, bottom: 50, boxShadow: 2, height: "500px", overflow: "auto" }}>
      <Stack gap={2} alignItems={"center"}>
        <ErrorOutlineOutlinedIcon sx={{ fontSize: "50px" }} color="primary" />
        <Typography>폼목별 주문번호가 중복되었습니다!</Typography>
        <Stack gap={1}>
          {list.map((v) => {
            const [orderIndex, barCode] = v.split(" ");
            return (
              <Card>
                <Typography>주문번호 : {orderIndex}</Typography>
                <Typography>바코드 : {barCode}</Typography>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    </Card>
  );
};
