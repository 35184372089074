import { NavItem } from "../../../config/nav-config";

/***
 * 사이드바 메뉴별 권한을 확인하는 함수
 * Check if the user has access to the item
 * @param item
 * @param userRole
 * @param userName
 * @returns boolean
 */
export const hasAccess = (item: NavItem, userRole: number, userName: string): boolean => {
    const { permisseionRoleIDList = [], authorizedUsers = [] } = item;
    if (permisseionRoleIDList.length > 0 && !permisseionRoleIDList.includes(userRole)) {
      return false;
    }
    if (authorizedUsers.length > 0 && !authorizedUsers.includes(userName)) {
      return false;
    }
    return true;
  };
  