import { Stack, Typography } from "@mui/material";

interface Props {
  label: string;
  value: string | number;
}

export const DescriptionItem: React.FC<Props> = ({ label, value }) => {
  return (
    <>
      <Stack direction="row" justifyContent={"space-between"} alignItems="end">
        <Typography color={"#666"} variant="caption">
          {label}
        </Typography>
        <Typography color={"#333"}>{value}</Typography>
      </Stack>
    </>
  );
};


